/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LoggerUpdatedShippingCompanyRangeDTO {
  loggerUpdatedShippingCompanyRangeId?: number;
  lowerShippingPrice?: string;
  averageShippingPrice?: string;
  greaterShippingPrice?: string;
  lowerWeight?: string;
  averageWeight?: string;
  greaterWeight?: string;
  totalShippingCompanyRange?: number;
  responsible?: string;
  environment?: string;
  dateCreated?: Date;
  dateUpdated?: Date;
}
