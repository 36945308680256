import { Clipboard } from '@angular/cdk/clipboard';
import { CurrencyPipe, TitleCasePipe } from '@angular/common';
import {
  Component,
  inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  CouponTypes,
  LineItem,
  Order,
  OrderStatus,
  Shops
} from '@infrab4a/connect';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  PersonControllerService,
  PersonDetail,
  Reward,
  ShopOrderControllerService,
  ShopOrderRefundDetail
} from 'src/app/admin-api';
import { ShippingTrackingModalComponent } from 'src/app/components/shipping-tracking-modal/shipping-tracking-modal.component';
import { TableColumn } from 'src/app/components/table';
import { ShopOrderService } from 'src/app/connect-api/api/shop/shop-order.service';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { getShop } from 'src/app/connect-api/enums/ShopMap';
import { PaymentMethod } from 'src/app/connect-api/models/PaymentMethod';
import { PaymentStatus } from 'src/app/connect-api/models/PaymentStatus';
import { Estado, TrackingModel } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { OpenService } from 'src/app/services/open.service';
import { FormUtil } from 'src/app/utils/form.util';
import { ShopOrderRefundModalComponent } from '../shop-order-refund-modal/shop-order-refund-modal.component';

@Component({
  selector: 'app-shop-order-detail',
  templateUrl: './shop-order-detail.component.html',
  styleUrl: './shop-order-detail.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService, TitleCasePipe, CurrencyPipe, ConfirmationService]
})
export class ShopOrderDetailComponent implements OnInit {
  private title = inject(Title);
  private shopOrdersService = inject(ShopOrderService);
  private router = inject(Router);
  private messageService = inject(MessageService);
  private personService = inject(PersonControllerService);
  private clipboard: Clipboard = inject(Clipboard);
  private titleCasePipe: TitleCasePipe = inject(TitleCasePipe);
  private openService: OpenService = inject(OpenService);
  private currencyPipe = inject(CurrencyPipe);
  private dialog = inject(DialogService);
  private shopOrderService = inject(ShopOrderControllerService);
  private messageSerice = inject(MessageService);
  private confirmationService = inject(ConfirmationService);

  @ViewChild(Dropdown)
  dropdown: Dropdown | undefined;

  @Input() orderId: string;
  @Input() set tab(tab: number) {
    this.selectedTab = tab;
  }
  protected person: PersonDetail | undefined;
  protected selectedTab = 0;
  protected statusList: string[] = [
    'Aguardando pagamento',
    'Preparando pedido',
    'Enviado',
    'Entregue',
    'Cancelado'
  ];
  protected changeStatus = false;

  protected cols = [
    new TableColumn.Builder()
      .setHeader('Imagem')
      .setField('image')
      .setType('image')
      .setFilter(false)
      .setStyleClass('product-image')
      .setDisplayFunction((item: LineItem) => item.image || item.images[0])
      .build(),
    new TableColumn.Builder()
      .setHeader('SKU Admin')
      .setField('id')
      .setRouterLink(this.orderRouterLink)
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn.Builder()
      .setHeader('EAN')
      .setField('EAN')
      .setRouterLink(this.orderRouterLink)
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn.Builder()
      .setHeader('Nome')
      .setField('name')
      .setRouterLink(this.orderRouterLink)
      .setRouterLinkFieldName('id')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Marca')
      .setField('brand')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Label')
      .setField('label')
      .setType('text')
      .setSortable(false)
      .setDisplayFunction((product: LineItem) =>
        ShopProductService.getProductLabel(product.label)
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Preço item')
      .setField('pricePaid')
      .setDisplayFunction((item: LineItem) => item.pricePaid)
      .setType('currency')
      .setCondition('gte')
      .build()
  ];
  refundedCols: TableColumn[];

  order: Order;
  status = new FormControl<string>(null);
  states: Array<Estado>;
  couponDiscount: { type: CouponTypes; value: number };
  tracking: Array<TrackingModel>;
  refunds: ShopOrderRefundDetail[];
  reward: Reward;
  tableReady = false;
  products: LineItem[];
  refundedProducts: Array<LineItem & { refundedAmount: number }>;

  tableFilters: {
    [key: string]: {
      label: string;
      value: 'outlet' | 'on-sale' | 'last-units' | 'glamstar' | '';
    }[];
  } = {
    label: [
      { label: 'Todos', value: '' },
      { label: 'Sem label', value: null },
      { label: 'Outlet', value: 'outlet' },
      { label: 'Promoção', value: 'on-sale' },
      { label: 'Últimas unidades', value: 'last-units' },
      { label: 'Glamstar', value: 'glamstar' }
    ]
  };

  async ngOnInit() {
    if (!this.orderId) {
      AppDialogService.showErrorDialog(
        { message: 'Pedido não encontrado.' },
        true
      );
      return;
    }
    LoaderService.showLoader();
    try {
      this.order = await this.shopOrdersService.getOrderById(this.orderId);
      this.setCouponDiscount();
      if (
        this.couponDiscount &&
        this.couponDiscount?.type === CouponTypes.PERCENTAGE
      ) {
        this.order?.lineItems?.forEach((l: any) => {
          l.discount = l.pricePaid * (this.couponDiscount.value / 100);
          l.pricePaidWithDiscounts = l.pricePaid - l.discount;
        });
        this.cols = this.cols.concat([
          new TableColumn.Builder()
            .setHeader('Desconto cupom')
            .setField('discount')
            .setType('currency')
            .setCondition('gte')
            .build(),
          new TableColumn.Builder()
            .setHeader('Preço pago')
            .setField('pricePaidWithDiscounts')
            .setType('currency')
            .setCondition('gte')
            .build()
        ]);
      }
      this.cols.push(
        new TableColumn.Builder()
          .setHeader('Quantidade')
          .setField('quantity')
          .setType('number')
          .setCondition('gte')
          .build()
      );
      this.cols
        .filter((c) => c.routerLink)
        .forEach((c) => (c.routerLink = this.orderRouterLink));
      if (this.order.shop === Shops.GLAMPOINTS) {
        this.cols = this.cols.filter((c) => c.type !== 'currency');
      }
      this.refundedCols = [...this.cols].concat(
        new TableColumn.Builder()
          .setHeader('Usuário')
          .setField('username')
          .setCondition('contains')
          .setType('text')
          .setDisplayFunction((i: LineItem) => this.itemRefund(i)?.username)
          .build(),
        new TableColumn.Builder()
          .setHeader('Valor estorno')
          .setField('quantity')
          .setDisplayFunction((i: LineItem) => this.itemRefund(i)?.amount)
          .setType('currency')
          .setFilter(false)
          .build()
      );
      this.status.setValue(this.order?.status);
      this.title.setTitle(`Loja - Pedido ${this.orderId}`);
      await Promise.all([
        this.loadPersonalData(),
        this.findStates(),
        this.findTracking(),
        this.findOrderRefunds()
      ]);
      await this.findReward();
      this.tableReady = true;
      this.lineItems = this.order.lineItems;
      LoaderService.showLoader(false);
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  private async loadPersonalData() {
    if (
      ['Glamshop', 'Glampoints'].includes(this.order?.shop) &&
      this.order.user?.id
    ) {
      this.person = await lastValueFrom(
        this.personService
          .findPersonInfoById(Number(this.order?.user.id))
          .pipe(map((data) => data.result))
      );
    } else {
      this.person = {
        personId: 0,
        username: this.order?.user?.email,
        name: this.order?.user?.firstName || this.order?.user?.displayName,
        lastName: this.order?.user?.lastName,
        phone: FormUtil.phoneFormatted(Number(this.order?.user.phone)),
        birthday: this.order?.user?.birthday?.toISOString() as any,
        cpf: Number(this.order?.user?.cpf),
        urlImageProfile: this.order?.user?.urlImageProfile,
        cpfFormatted: FormUtil.cpfFormatted(Number(this.order?.user?.cpf)),
        cpfString: this.order?.user?.cpf
      };
    }
  }

  tabChanged(index: number) {
    this.selectedTab = index;
    this.router.navigate([`/shop-orders/order/${this.orderId}`], {
      queryParams: { tab: index },
      queryParamsHandling: 'merge'
    });
  }

  showDropdown(): void {
    this.changeStatus = true;
    setTimeout(() => {
      this.dropdown?.show();
    });
  }

  async changeOrderStatus() {
    try {
      LoaderService.showLoader();
      this.order = await this.shopOrdersService.updateOrderStatus(
        this.status.value,
        this.order?.id
      );
      this.changeStatus = false;
      this.messageService.add({
        severity: 'success',
        detail: 'Status atualizado com sucesso',
        summary: 'Sucesso'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Copiado para área de trasnferência'
    });
  }

  async findStates(): Promise<void> {
    try {
      this.states = await this.openService.listStates();
    } catch (error) {
      this.states = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  getTooltip(field?: 'boletoBarcode' | 'boletoUrl' | 'pixQrCode'): string {
    let message = '';
    if (this.order?.payment?.paymentMethod) {
      switch (this.order.payment.paymentMethod) {
        case 'credit_card':
          break;
        default:
          message += this.titleCasePipe.transform(
            this.order.payment.paymentMethod
          );
          if (this.order.payment.status === 'paid') message += ' pago';
          else if (
            (this.order.payment.boletoExpirationDate ||
              this.order.payment.pixExpirationDate) &&
            FormUtil.utcDate(
              this.order.payment.boletoExpirationDate ||
                this.order.payment.pixExpirationDate
            ).getTime() < new Date().getTime()
          )
            message += ' vencido';
          else if (field) {
            switch (field) {
              case 'boletoBarcode':
                return 'Copiar código de barras';
              case 'boletoUrl':
                return 'Ver boleto';
              default:
                return 'Copiar código';
            }
          } else return '';
      }
    }
    return message;
  }

  setCouponDiscount(): void {
    if (this.order?.coupon) {
      const discountPercentage =
        100 * (this.order.discount / this.order.subTotalPrice);
      Object.keys(this.order.coupon.discount).forEach((key) => {
        if (
          !this.couponDiscount &&
          this.order.coupon.discount[key].type === CouponTypes.ABSOLUTE &&
          this.order.coupon.discount[key].value === this.order.discount
        ) {
          this.couponDiscount = this.order.coupon.discount[key];
        } else if (
          !this.couponDiscount &&
          this.order.coupon.discount[key].type === CouponTypes.PERCENTAGE &&
          this.order.coupon.discount[key].value === discountPercentage
        )
          this.couponDiscount = this.order.coupon.discount[key];
      });
    }
  }

  async findTracking() {
    if (this.order.shipping.invoiceNumber)
      try {
        this.tracking = await this.openService.getShippingTracking(
          Number(this.order.shipping.invoiceNumber)
        );
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      }
  }

  async findOrderRefunds() {
    try {
      if (this.order.orderNumber) {
        this.refunds = await lastValueFrom(
          this.shopOrderService
            .findShopOrderRefundsByOrderNumber(this.order.orderNumber)
            .pipe(map((data) => data.result))
        );
      } else {
        this.refunds = await lastValueFrom(
          this.shopOrderService
            .findShopOrderRefunds(this.order.id)
            .pipe(map((data) => data.result))
        );
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error);
      this.refunds = [];
    }
  }

  async findReward() {
    if (this.person?.personId)
      try {
        this.reward = await lastValueFrom(
          this.shopOrderService
            .findShopOrderReward(this.person.personId, this.order.id)
            .pipe(map((data) => data.result))
        );
      } catch (error) {
        console.log(error);
      }
  }

  openTracking() {
    if (this.tracking?.length)
      this.dialog.open(ShippingTrackingModalComponent, {
        showHeader: true,
        header: 'Rastreio da nota ' + this.order.shipping.invoiceNumber,
        position: 'center',
        data: {
          order: this.order,
          tracking: this.tracking
        },
        dismissableMask: true,
        width: '50vw'
      });
    else
      AppDialogService.showErrorDialog({
        error: {
          message: 'Sem informações de rastreio para exibir.'
        }
      });
  }

  refundOrder(allProducts = false) {
    this.dialog
      .open(ShopOrderRefundModalComponent, {
        header: 'Estornar pedido ' + (this.order.orderNumber || this.order.id),
        data: {
          order: this.order,
          refunds: this.refunds,
          person: this.person,
          reward: this.reward,
          allProducts
        },
        width: '600px'
      })
      .onClose.subscribe((refunds: ShopOrderRefundDetail[]) => {
        if (refunds?.length) {
          this.tableReady = false;
          this.refunds = [...refunds];
          this.messageSerice.add({
            severity: 'success',
            detail: `${refunds.length} produto(s) estornado(s)`,
            summary: 'Sucesso'
          });
          this.lineItems = this.order.lineItems;
          this.tableReady = true;
          if (!allProducts && this.order?.status !== OrderStatus.ENTREGUE)
            this.confirmationService.confirm({
              acceptLabel: 'Sim, alterar!',
              acceptButtonStyleClass: 'p-button-danger',
              rejectButtonStyleClass: 'p-button-primary',
              rejectLabel: 'Não! Manter status atual.',
              message: 'Deseja alterar o status do pedido para "Entregue"?',
              header: 'Status do pedido',
              accept: async () => {
                this.status.setValue(OrderStatus.ENTREGUE);
                await this.changeOrderStatus();
              }
            });
          else if (allProducts && this.order?.status !== OrderStatus.CANCELADO)
            this.confirmationService.confirm({
              acceptLabel: 'Sim, alterar!',
              acceptButtonStyleClass: 'p-button-danger',
              rejectButtonStyleClass: 'p-button-primary',
              rejectLabel: 'Não! Manter status atual.',
              message: 'Deseja alterar o status do pedido para "Cancelado"?',
              header: 'Status do pedido',
              accept: async () => {
                this.status.setValue(OrderStatus.CANCELADO);
                await this.changeOrderStatus();
              }
            });
          LoaderService.showLoader(false);
        }
      });
  }

  itemRefund = (item: LineItem) => {
    return this.refunds?.find((r) => r.ean === item.EAN);
  };

  set lineItems(itens: LineItem[]) {
    this.products = itens.filter(
      (i) => !this.refunds || this.refunds?.every((r) => r.ean !== i.EAN)
    );
    this.refundedProducts = itens
      .filter((i) => this.refunds?.some((r) => r.ean === i.EAN))
      .map(
        (i) =>
          ({
            ...i,
            refundedAmount:
              this.refunds.find((r) => r.ean === i.EAN)?.amount || 0
          } as LineItem & { refundedAmount: number })
      );
  }

  get isWarningStatus() {
    return (
      this.order?.status === 'Aguardando pagamento' ||
      this.order?.status === 'Preparando pedido' ||
      this.order?.status === 'Enviado'
    );
  }

  get productsTotal(): number {
    return this.order?.lineItems?.reduce(
      (sum, i) => sum + (i.price.price || i.price.fullPrice || 0) * i.quantity,
      0
    );
  }

  get subscriberDiscount(): number {
    return this.order?.lineItems?.reduce(
      (sum, i) => sum + i.quantity * (i.price.price - i.pricePaid),
      0
    );
  }

  get methodName(): string {
    return PaymentMethod[this.order?.payment.paymentMethod];
  }

  get shopName(): string {
    return getShop(this.order?.shop);
  }

  get paymentStatus(): string {
    return PaymentStatus[this.order.payment.status];
  }

  get expired(): boolean {
    return (
      (this.order?.payment?.paymentMethod === 'boleto' &&
        !this.order.payment.paidAt &&
        this.order.payment.boletoExpirationDate &&
        FormUtil.utcDate(this.order.payment.boletoExpirationDate).getTime() <
          new Date().getTime()) ||
      (this.order?.payment?.paymentMethod === 'pix' &&
        !this.order.payment.paidAt &&
        this.order.payment.pixExpirationDate &&
        FormUtil.utcDate(this.order.payment.pixExpirationDate).getTime() <
          new Date().getTime())
    );
  }

  get orderRouterLink(): string {
    return this.order?.shop === Shops.GLAMPOINTS
      ? '/products/catalog/product-variant/'
      : '/shop-products/catalog/product/';
  }

  get couponDiscountLabel(): string {
    if (!this.couponDiscount) return undefined;
    return this.couponDiscount?.type === CouponTypes.ABSOLUTE
      ? this.currencyPipe.transform(this.order.discount, 'BRL')
      : `${this.couponDiscount.value.toFixed(2)} %`;
  }

  get refundedAmount() {
    return this.refunded
      ? this.order.totalPrice
      : this.refunds?.reduce((sum, r) => (sum += r.amount), 0) || 0;
  }

  get lineItems() {
    return this.products?.filter((p) => p.pricePaid);
  }

  get glampoints() {
    return (this.reward?.points || 0) / (this.order?.lineItems?.length || 1);
  }

  get refunded() {
    return !this.lineItems?.length;
  }
}
