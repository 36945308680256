import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StockMovementsComponent } from 'src/app/pages/operations/stock-movements/stock-movements.component';
import { AllocationComponent } from './allocation/allocation.component';
import { CompositionProductionsComponent } from './composition-productions/composition-productions.component';
import { CompositionDetailComponent } from './compositions/composition-detail/composition-detail.component';
import { CompositionsComponent } from './compositions/compositions.component';
import { EditionsDashboardComponent } from './editions-dashboard/editions-dashboard.component';

const routes: Routes = [
  {
    path: 'editions',
    component: EditionsDashboardComponent
  },
  {
    path: 'editions/:editionId',
    component: EditionsDashboardComponent
  },
  {
    path: 'compositions/id/:compositionId',
    component: CompositionDetailComponent
  },
  { path: 'compositions', component: CompositionsComponent },
  { path: 'compositions/:editionId', component: CompositionsComponent },
  { path: 'allocation', component: AllocationComponent },
  {
    path: 'composition-production',
    component: CompositionProductionsComponent
  },
  {
    path: 'composition-production/:editionId',
    component: CompositionProductionsComponent
  },
  { path: 'stock-movements', component: StockMovementsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperationsRoutingModule {}
