import {
  Component,
  inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import {
  FinancialAdminControllerService,
  FinancialControllerService,
  OpenFinancePayerInstitutionDetail
} from 'src/app/admin-api';
import { TableColumn, TableComponent } from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-payer-institutions',
  templateUrl: './payer-institutions.component.html',
  styleUrl: './payer-institutions.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PayerInstitutionsComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;

  financialService = inject(FinancialControllerService);
  private permissionService = inject(NgxPermissionsService);
  private financialAdminService = inject(FinancialAdminControllerService);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
  private title = inject(Title);

  cols: TableColumn[];
  permission = false;

  async ngOnInit() {
    try {
      this.permission = await this.permissionService.hasPermission([
        roleAsObject(Role.Full_Administrator).enumValue,
        roleAsObject(Role.Financial).enumValue
      ]);
      this.title.setTitle('Instituições financeiras do Open Finance');
    } catch (error) {
      AppDialogService.showErrorDialog(error);
      this.permission = false;
    }
    this.cols = [...this.columns];
  }

  payerInstitutionImg(institution: OpenFinancePayerInstitutionDetail) {
    return institution?.logoUrl || institution?.iconUrl
      ? `<img src="${institution?.logoUrl || institution?.iconUrl}" alt="${
          institution.legalEntityName
        }">`
      : '';
  }

  updateInstitutionStatus(institution: OpenFinancePayerInstitutionDetail) {
    this.confirmationService.confirm({
      acceptButtonStyleClass: 'p-button-primary',
      rejectButtonStyleClass: 'p-button-secondary',
      acceptLabel: 'Atualizar',
      rejectLabel: 'Voltar',
      accept: async () => await this.updateStatus(institution),
      header: 'Atualizar informações',
      message: `Tem certeza que deseja ${
        institution.active ? 'Ativar' : 'Desativar'
      } a institução?.`.concat(
        institution.totalPending
          ? ' Os pagamentos pendentes não serão afetados.'
          : ''
      )
    });
  }

  async updateStatus(institution: OpenFinancePayerInstitutionDetail) {
    try {
      LoaderService.showLoader();
      await lastValueFrom(
        this.financialAdminService.updateInstitution(
          institution,
          institution.openFinancePayerInstitutionId
        )
      );
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Instituição atualizada'
      });
    } catch (error) {
      institution.active = !institution.active;
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  refreshInstitutions() {
    this.confirmationService.confirm({
      acceptButtonStyleClass: 'p-button-warning',
      acceptLabel: 'Atualizar',
      rejectLabel: 'Voltar',
      accept: async () => await this.updateInstitutionsFromGateway(),
      header: 'Atualizar informações',
      message:
        'Deseja atualizar as instituções com as informações na Belvo?\nObs: Novas instituções podem ser incluídas'
    });
  }

  async updateInstitutionsFromGateway() {
    try {
      LoaderService.showLoader();
      await lastValueFrom(
        this.financialAdminService.refreshOpenFinanceInstitutions()
      );
      await this.table?.refresh();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Instituições atualizadas'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  get columns() {
    return [
      new TableColumn.Builder()
        .setHeader('Id')
        .setField('openFinancePayerInstitutionId')
        .setType('number')
        .setRouterLink('')
        .setRouterLinkFieldName('openFinancePayerInstitutionId')
        .build(),
      new TableColumn.Builder()
        .setHeader('Logo')
        .setField('logoUrl')
        .setStyleClass('institution')
        .setInnerHTML((institution: OpenFinancePayerInstitutionDetail) =>
          this.payerInstitutionImg(institution)
        )
        .setFilter(false)
        .setSortable(false)
        .setRouterLink(
          (institution: OpenFinancePayerInstitutionDetail) =>
            institution.website
        )
        .build(),
      new TableColumn.Builder()
        .setHeader('Instituição')
        .setField('institutionName')
        .setRouterLink('')
        .setRouterLinkFieldName('openFinancePayerInstitutionId')
        .setCondition('contains')
        .build(),
      new TableColumn.Builder()
        .setHeader('Id Belvo')
        .setField('payerInstitutionId')
        .setRouterLink('')
        .setRouterLinkFieldName('openFinancePayerInstitutionId')
        .build(),
      new TableColumn.Builder()
        .setHeader('Ativo')
        .setField('active')
        .setType(this.permission ? 'inputSwitch' : 'boolean')
        .build(),
      new TableColumn.Builder()
        .setHeader('Falhas')
        .setField('totalFailures')
        .setType('formattedInteger')
        .setCondition('gte')
        .build(),
      new TableColumn.Builder()
        .setHeader('Pendências')
        .setField('totalPending')
        .setType('formattedInteger')
        .setCondition('gte')
        .build(),
      new TableColumn.Builder()
        .setHeader('Sucessos')
        .setField('totalSuccess')
        .setType('formattedInteger')
        .setCondition('gte')
        .build()
    ];
  }
}
