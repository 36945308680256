/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Category, CategoryCondition, Filter, Shops } from '@infrab4a/connect';
import { CategoryBase } from '@infrab4a/connect/src/domain/catalog/models/category-base';
import { MessageService } from 'primeng/api';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { ChipsAddEvent } from 'primeng/chips';
import { Editor } from 'primeng/editor';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
import Quill from 'quill';
import { BaseConnectService } from 'src/app/connect-api/api/shop/base-connect.service';
import { ShopCategoryFilterService } from 'src/app/connect-api/api/shop/shop-category-filter.service';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { getShopsArray } from 'src/app/connect-api/enums/ShopMap';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { slugify } from 'src/app/utils/slug.util';

@Component({
  selector: 'app-shop-category-form',
  templateUrl: './shop-category-form.component.html',
  styleUrl: './shop-category-form.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ShopCategoryFormComponent implements OnInit {
  @Input({ required: true }) category: Partial<Category>;
  @Output() categoryUpdated = new EventEmitter<Partial<Category>>();

  @ViewChild('description') editorDescription: Editor;

  private shoppingCategoryService = inject(ShopCategoryService);
  private filterService = inject(ShopCategoryFilterService);
  private messageService = inject(MessageService);
  private router = inject(Router);
  private cdRef = inject(ChangeDetectorRef);

  public categoryForm = new FormGroup({
    slug: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required]),
    brandLogo: new FormControl(null),
    description: new FormControl<string>(null),
    metadatas: new FormArray(
      [this.metadataGroup],
      [Validators.required, Validators.minLength(1)]
    ),
    levelOneCategory: new FormControl(false),
    isCollection: new FormControl(false),
    brandCategory: new FormControl(false),
    isWishlist: new FormControl(false),
    published: new FormControl(false),
    shops: new FormControl<string[]>(
      [],
      [Validators.required, Validators.min(1)]
    ),
    parent: new FormControl<CategoryBase>(null, Validators.required),
    category_name: new FormControl(''),
    reference: new FormControl(null, Validators.required),
    id: new FormControl({ value: null, disabled: true }, Validators.required),
    parentTree: new FormControl<string>({ value: null, disabled: true }),
    filters: new FormControl<Filter[]>([]),
    conditions: new FormGroup({
      tags: new FormControl<string[]>([]),
      brand: new FormControl<string>(null)
    }),
    type: new FormControl<
      'collection' | 'levelOneCategory' | 'category' | 'brandCategory'
    >('collection', Validators.required),
    copyMetadata: new FormControl(true)
  });
  categoryTree: Array<Partial<Category>>;
  filterOptions: Filter[] = [];
  filterOptionsAvailable: Filter[] = [];
  categories: Partial<Category>[];
  chipRegex: any = /\r|\n|;|,/;
  shops = getShopsArray();
  ready = false;

  async ngOnInit(): Promise<void> {
    await this.findCategoriesAndFilters();
    this.patchFormValue();
    if (this.category) this.categoryForm.controls['id'].enable();
    if (this.category?.parent)
      this.changeParentCategory(Number(this.category.parent?.id));
    this.ready = true;
  }

  async findCategoriesAndFilters(): Promise<void> {
    const [filterOptions, categories] = await Promise.all([
      this.filterService.getFilterList(),
      this.shoppingCategoryService.getCategoriesForProduct()
    ]);
    categories.sort((c1, c2) => {
      if (
        FormUtil.semAcento(c1.name).toLowerCase() <
        FormUtil.semAcento(c2.name).toLowerCase()
      )
        return -1;
      if (
        FormUtil.semAcento(c1.name).toLowerCase() >
        FormUtil.semAcento(c2.name).toLowerCase()
      )
        return 1;
      return 0;
    });
    filterOptions.sort((f1, f2) => {
      if (
        FormUtil.semAcento(f1.description).toUpperCase() <
        FormUtil.semAcento(f2.description).toUpperCase()
      )
        return -1;
      if (
        FormUtil.semAcento(f1.description).toUpperCase() >
        FormUtil.semAcento(f2.description).toUpperCase()
      )
        return 1;
      return 0;
    });
    this.filterOptions = filterOptions;
    this.categories = categories;
  }

  private patchFormValue() {
    const levelOneCategory = this.category?.reference && !this.category.parent;
    this.categoryForm.patchValue({
      ...(this.category || {}),
      levelOneCategory,
      metadatas: [],
      parent: null,
      type: this.getType(this.category),
      copyMetadata: this.category
        ? this.category.metadatas?.reduce((title: string[], m) => {
            if (!title.includes(m.title)) title.push(m.title);
            return title;
          }, []).length === 1 &&
          this.category.metadatas?.reduce((description: string[], m) => {
            if (!description.includes(m.description))
              description.push(m.description);
            return description;
          }, []).length === 1
        : true
    });

    if (this.category?.parentId) {
      const cat = this.categories.find(
        (cat) => cat.id === this.category.parentId.toString()
      );
      this.categoryForm.controls['parent'].setValue(cat as any);
      this.changeParentCategory(this.category.parentId);
    }

    this.collectionChange({ value: this.categoryForm.value.type });
    this.changeShopAvailability();
    this.categoryForm.controls.metadatas.patchValue(
      this.category?.metadatas || []
    );
  }

  collectionChange(event: SelectButtonChangeEvent | { value: string }): void {
    if (event.value === 'category') {
      this.categoryForm.controls['brandCategory'].setValue(false);
      this.categoryForm.controls['reference'].enable();
      this.categoryForm.controls['parent'].enable();
      this.categoryForm.controls['levelOneCategory'].setValue(false);
      this.categoryForm.controls['isCollection'].setValue(false);
    } else if (event.value === 'levelOneCategory') {
      this.categoryForm.controls['isCollection'].setValue(false);
      this.categoryForm.controls['brandCategory'].setValue(false);
      this.categoryForm.controls['reference'].enable();
      this.categoryForm.controls['parent'].setValue(null);
      this.categoryForm.controls['parent'].disable();
      this.categoryForm.controls['levelOneCategory'].setValue(true);
    } else if (event.value === 'collection') {
      this.categoryForm.controls['brandCategory'].setValue(false);
      this.categoryForm.controls['reference'].setValue(null);
      this.categoryForm.controls['reference'].disable();
      this.categoryForm.controls['parent'].setValue(null);
      this.categoryForm.controls['parent'].disable();
      this.categoryForm.controls['levelOneCategory'].setValue(false);
      this.categoryForm.controls['isCollection'].setValue(true);
    } else if (event.value === 'brandCategory') {
      this.categoryForm.controls['brandCategory'].setValue(true);
      this.categoryForm.controls['reference'].setValue(null);
      this.categoryForm.controls['reference'].disable();
      this.categoryForm.controls['parent'].setValue(null);
      this.categoryForm.controls['parent'].disable();
      this.categoryForm.controls['levelOneCategory'].setValue(false);
      this.categoryForm.controls['isCollection'].setValue(true);
    }
    this.changeParentCategory();
    this.categoryForm.updateValueAndValidity();
  }

  async changeParentCategory(value?: number) {
    if (value) {
      if (this.category?.id && Number(this.category.id) === value) {
        this.messageService.add({
          severity: 'warn',
          detail: `A categoria não pode ser relacionada com ela mesma!`,
          summary: 'Erro'
        });
        this.categoryForm.parent.setErrors({ same: true });
        return;
      }

      LoaderService.showLoader();
      try {
        this.categoryTree =
          await this.shoppingCategoryService.getCategoriesTree(
            value?.toString()
          );
        this.setCategoryTreeLabel();
        if (this.categoryForm.controls['reference'].value)
          this.validateReference();
        else {
          this.categoryForm.controls['reference'].setValue(
            this.categoryTree[this.categoryTree.length - 1].reference + '.'
          );
          setTimeout(() => {
            document.getElementById('reference')?.focus();
          });
        }
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    } else {
      this.categoryForm.controls['parentTree'].setValue('Sem categoria');
      this.validateReference();
    }
  }

  setCategoryTreeLabel(): void {
    const reverse = [...(this.categoryTree || [])];
    reverse.reverse();
    this.categoryForm.controls['parentTree'].setValue(
      reverse
        ?.concat([
          {
            ...this.categoryForm.value,
            reference: this.categoryForm.value.reference || ''
          } as Partial<Category>
        ])
        .map(
          (c) => `${c.reference} ${c.name} ${c.shop ? '[' + c.shop + ']' : ''}`
        )
        .toString()
        .replaceAll(',', ' > ') || 'Sem categoria'
    );
  }

  async submit(): Promise<void> {
    this.categoryForm.updateValueAndValidity();
    if (!this.categoryForm.valid) {
      this.categoryForm.markAllAsTouched();
      this.categoryForm.markAsDirty();
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Verifique os campos'
      });
      return;
    }
    if (this.category) await this.updateCategory();
    else await this.createCategory();
  }

  public async updateCategory() {
    if (this.categoryForm.valid) {
      LoaderService.showLoader(true);
      try {
        this.category = await this.shoppingCategoryService.updateCategory(
          this.getCategoryData
        );
        this.patchFormValue();
        if (this.category?.parent)
          this.changeParentCategory(Number(this.category.parent?.id));
        this.messageService.add({
          severity: 'success',
          detail: `Categoria atualizada com sucesso!`,
          summary: 'Sucesso'
        });
        this.categoryUpdated.emit(this.category);
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    } else {
      this.messageService.add({
        severity: 'warn',
        detail: `Verifique os campos obrigatórios`,
        summary: 'Formulário inválido'
      });
      this.categoryForm.markAsDirty();
      this.categoryForm.markAllAsTouched();
    }
  }

  public async createCategory() {
    if (this.categoryForm.valid) {
      LoaderService.showLoader(true);
      try {
        let category = this.getCategoryData;
        category = await this.shoppingCategoryService.saveCategory(category);
        this.messageService.add({
          severity: 'success',
          detail: `Categoria criada com sucesso!`,
          summary: 'Sucesso'
        });
        this.router.navigate([
          '/shop-products/categories/category/' + category.id
        ]);
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    } else {
      this.categoryForm.markAsDirty();
      this.categoryForm.markAllAsTouched();
      this.messageService.add({
        severity: 'warn',
        detail: `Verifique os campos obrigatórios`,
        summary: 'Formulário inválido'
      });
    }
  }

  invalidControl(control: string): boolean {
    return (
      this.categoryForm.controls[control].invalid &&
      (this.categoryForm.controls[control].dirty ||
        this.categoryForm.controls[control].touched)
    );
  }

  async slugify(): Promise<void> {
    this.categoryForm.controls['slug'].setValue(
      slugify(this.categoryForm.value.name)
    );

    const categoryBySlug = await this.shoppingCategoryService.getCategoryBySlug(
      this.categoryForm.value.slug,
      this.categoryForm.value.shops[0] as Shops
    );

    if (
      categoryBySlug &&
      (!this.category || this.category.id !== categoryBySlug.id)
    ) {
      this.categoryForm.controls['slug'].setErrors({ exists: true });

      return;
    }
    this.categoryForm.controls.conditions.controls.brand.setValue(
      this.categoryForm.value.slug
    );
  }

  get getCategoryData(): Partial<Category> {
    const formValue = this.categoryForm.getRawValue();
    const { metadata, ...category }: any = this.category
      ? { ...ShopCategoryService.categoryUpdateDTO(this.category) }
      : ({} as Partial<Category>);
    category.name = formValue.name.trim();
    category.slug = formValue.slug.trim();
    category.description =
      formValue.description?.length &&
      (this.editorDescription.getQuill() as Quill).getText().trim()
        ? formValue.description
        : BaseConnectService.NULL;
    category.brandCategory = formValue.brandCategory;
    category.isCollection = formValue.isCollection;
    category.published = formValue.published;
    if (this.category)
      category.parent = formValue.parent || BaseConnectService.NULL;
    else if (formValue.parent) category.parent = formValue.parent;
    category.parentId = formValue.parent?.id
      ? formValue.parent.id
      : this.category
      ? BaseConnectService.NULL
      : null;
    category.reference = formValue.reference
      ? formValue.reference
      : this.category
      ? BaseConnectService.NULL
      : null;
    category.shop = formValue.shops[0];
    category.shops = formValue.shops;
    category.metadatas = formValue.metadatas;

    if (
      formValue.brandCategory &&
      !formValue.conditions.brand &&
      !formValue.conditions.brand.trim()
    ) {
      formValue.conditions.brand = formValue.name.trim();
    }

    category.conditions = this.category
      ? formValue.conditions
      : ({ brand: null, tags: [] } as CategoryCondition);

    if (this.category)
      category.filters = formValue.filters.length
        ? { action: 'merge', value: formValue.filters || [] }
        : { action: 'remove', value: this.category.filters };
    else if (formValue.filters) category.filters = formValue.filters || [];
    if (this.categoryForm.value.id) category.id = this.categoryForm.value.id;

    return category as any;
  }

  isRequired(control: FormControl): boolean {
    return control.enabled && control.hasValidator(Validators.required);
  }

  validateReference(): boolean {
    if (
      !this.categoryForm.value.isCollection &&
      !this.categoryForm.value.levelOneCategory &&
      this.categoryForm.controls['parent'].value &&
      this.categoryForm.controls['reference'].value
    ) {
      const references = this.categoryForm.value.reference
        ?.split('.')
        .filter((r) => r.trim()?.length && !Number.isNaN(r.trim()))
        .map((r) => Number(r));
      if (
        this.categoryTree?.some(
          (c) => !this.categoryForm.value.reference.includes(c.reference)
        ) ||
        (this.categoryTree &&
          references.length !== this.categoryTree.length + 1)
      ) {
        this.categoryForm.controls['reference'].setErrors({
          format: `A referência deve possuir o formato ${this.categoryTree[0].reference}.X`
        });
        return false;
      } else {
        const exists = this.categories.find(
          (c) =>
            c.reference === this.categoryForm.value.reference &&
            (!this.category?.id || c.id !== this.category.id)
        );
        if (
          exists &&
          (!this.category ||
            (exists.id !== this.category?.id &&
              exists.shops?.some((s) =>
                this.categoryForm.value.shops?.includes(s)
              )))
        ) {
          this.categoryForm.controls['reference'].setErrors({
            format: `A referência ${exists.reference} pertence à categoria ${exists.name}.`
          });
          return false;
        }
      }
    } else if (
      !this.categoryForm.value.isCollection &&
      this.categoryForm.value.levelOneCategory &&
      this.categoryForm.value.reference
    ) {
      this.categoryForm.controls.reference.setValue(
        (this.categoryForm.value.reference as string).replace(/\D/g, '')
      );
      const exists = this.categories.find(
        (c) =>
          c.reference === this.categoryForm.value.reference &&
          (!this.category?.id || c.id !== this.category.id)
      );
      if (exists) {
        this.categoryForm.controls['reference'].setErrors({
          format: `A referência ${exists.reference} pertence à categoria ${exists.name}.`
        });
        return false;
      }
    } else if (this.categoryForm.touched && this.categoryForm.dirty) {
      this.categoryForm.controls['parent'].markAsTouched();
      this.categoryForm.controls['parent'].markAsDirty();
      this.categoryForm.controls['reference'].markAllAsTouched();
      this.categoryForm.controls['reference'].markAsDirty();
      this.categoryForm.controls['reference'].setErrors({});
      this.categoryForm.updateValueAndValidity();
    }
    return true;
  }

  addTag(event?: ChipsAddEvent) {
    if (event?.value) {
      const index = this.categoryForm.value.conditions.tags.findIndex(
        (t) => t === slugify((event.value as string).toLowerCase())
      );
      if (
        index >= 0 &&
        index < this.categoryForm.value.conditions.tags.length - 1
      ) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atenção',
          detail: `Tag já vinculada '${this.categoryForm.value.conditions.tags[index]}'`
        });
        this.categoryForm.controls['conditions'].controls['tags'].setValue(
          this.categoryForm.value.conditions.tags.slice(
            0,
            this.categoryForm.value.conditions.tags.length - 1
          )
        );
      }
    }
    this.categoryForm.controls['conditions'].controls['tags'].setValue(
      this.categoryForm.value.conditions.tags.map((t) =>
        slugify(t.toLowerCase())
      )
    );
  }

  searchFilters(event: AutoCompleteCompleteEvent): void {
    this.filterOptionsAvailable =
      this.filterOptions?.filter(
        (f) =>
          !this.categoryForm.value.filters?.some((cf) => cf?.id === f.id) &&
          f.slug.includes(
            slugify(FormUtil.semAcento(event.query.toLowerCase()))
          )
      ) || [];
  }

  shopsIncludes(shop: string): boolean {
    return this.categoryForm.value.shops.includes(shop);
  }

  changeShopAvailability(): void {
    const metadatas: FormArray<typeof this.metadataGroup> = new FormArray([]);
    this.categoryForm.value.shops?.forEach((s, i) => {
      metadatas.push(this.metadataGroup);
      metadatas.at(i).controls.shop.setValue(s as Shops);
      const current = this.categoryForm.controls.metadatas.controls.find(
        (c) => c.value.shop === s
      );
      if (current) metadatas.at(i).patchValue(current.value);
    });
    metadatas.controls.sort((c1, c2) => {
      return c1.value.shop.localeCompare(c2.value.shop);
    });
    this.categoryForm.controls.metadatas = metadatas;
    this.copyMetadata();
  }

  copyMetadata() {
    if (this.categoryForm.controls.copyMetadata)
      this.categoryForm.controls.metadatas.controls.forEach((c) =>
        c.patchValue({
          ...this.categoryForm.value.metadatas[0],
          shop: c.value.shop
        })
      );
    this.categoryForm.updateValueAndValidity();
  }

  copyValue($event: InputEvent, controlName: 'title' | 'description') {
    this.categoryForm.controls.metadatas.controls.forEach((c) => {
      c.get(controlName).setValue(($event.target as any).value);
      c.get(controlName).updateValueAndValidity();
      c.updateValueAndValidity();
    });
    this.categoryForm.updateValueAndValidity();
  }

  getType(category: Partial<Category>) {
    if (category?.isCollection && category?.brandCategory)
      return 'brandCategory';
    if (category?.isCollection) return 'collection';
    if (
      this.category?.reference &&
      !this.category.isCollection &&
      !this.category.parent
    )
      return 'levelOneCategory';

    return 'category';
  }

  shop(shop: Shops) {
    return this.shops.find((s) => s.value === shop);
  }

  get metadataGroup() {
    return new FormGroup({
      title: new FormControl<string>(null, Validators.required),
      description: new FormControl<string>(null, Validators.required),
      shop: new FormControl<Shops>(null, Validators.required)
    });
  }
}
