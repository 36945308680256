import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  Product,
  ProductRepository,
  ProductReviews,
  ProductReviewsRepository,
  ReviewWithProductData
} from '@infrab4a/connect';
import { ConnectRequest } from 'src/app/models';
import { BaseConnectService } from './base-connect.service';
import { ShopProductService } from './shop-product.service';

@Injectable({
  providedIn: 'root'
})
export class ShopProductReviewService extends BaseConnectService<ProductReviews> {
  constructor(
    @Inject('ProductReviewsRepository')
    private reviewRepository: ProductReviewsRepository,
    @Inject('ProductRepository')
    private productRepository: ProductRepository,
    private httpClient: HttpClient,
    private shoppingProductService: ShopProductService
  ) {
    super(reviewRepository);
  }

  async getReviewById(id: string): Promise<ProductReviews> {
    return this.reviewRepository.get({ id: Number(id) });
  }

  async getReviewsList(request: Partial<ConnectRequest>) {
    const productsRequest: ConnectRequest = {
      filters: [],
      page: 0,
      pageSize: request.pageSize * 10,
      sortBy: 'id',
      sortDirection: 'ASC'
    };
    const nameFilter = request.filters.find((f) => f.field === 'productName');
    if (nameFilter?.value)
      productsRequest.filters.push({
        condition: 'contains',
        field: 'name',
        fieldType: 'text',
        value: nameFilter.value
      });
    const eanFilter = request.filters.find((f) => f.field === 'productEan');
    if (eanFilter?.value)
      productsRequest.filters.push({
        condition: 'equals',
        field: 'EAN',
        fieldType: 'text',
        value: eanFilter.value
      });

    if (nameFilter?.value || eanFilter?.value) {
      const products = await this.shoppingProductService.paginatedSearch(
        productsRequest
      );
      if (products?.data?.length) {
        request.filters.push({
          condition: 'in',
          field: 'productId',
          fieldType: 'number',
          value: products.data.map((p) => p.id)
        });
      }
      request.filters = request.filters.filter(
        (f) => f.field !== 'productName' && f.field !== 'productEan'
      );
    }
    if (request.sortBy === 'productName' || request.sortBy === 'productEan')
      request.sortBy = 'createdAt';
    const reviewsResult = await this.paginatedSearch(request);
    const result = {
      ...reviewsResult,
      data: await this.mappingReviewProduct(reviewsResult.data)
    };
    const content = result.data.map((category) => ({ ...category }));
    const totalPages = request.pageSize
      ? Math.floor(result.count / request.pageSize)
      : undefined;
    const totalElements = result.count;

    return {
      totalPages,
      totalElements,
      content,
      empty: result.count === 0
    };
  }

  async aproveReview(review: ReviewWithProductData) {
    await this.reviewRepository.aproveReview(Number(review.id));
  }

  async disaproveReview(review: ReviewWithProductData) {
    await this.reviewRepository.disaproveReview(Number(review.id));
  }

  async mappingReviewProduct(reviews: ProductReviews[]) {
    const products = await Promise.all(
      reviews
        .reduce((ids: Array<number>, p) => {
          if (!ids.includes(p.productId)) ids.push(p.productId);
          return ids;
        }, [])
        .map((id) => this.getProductInfo(id))
    );
    return reviews.map((review) => {
      const product = products.find(
        (p) => p.id === review.productId.toString()
      );
      return {
        ...review,
        productName: product.name,
        productSku: product.sku,
        productEan: product.EAN
      };
    });
  }

  private async getProductInfo(id): Promise<Product> {
    return (
      await this.productRepository.find({
        filters: {
          id
        }
      })
    ).data[0];
  }

  public static mapReviewStatus(status: any): string {
    if (status === null) return 'Pendente';

    return status ? 'Aprovada' : 'Reprovada';
  }

  async update(review: Partial<ProductReviews>) {
    return await this.reviewRepository.update(review);
  }

  async updateStatus(reviews: number[], status = false) {
    return await this.reviewRepository.updateManyStatus(
      reviews.map((id) => ({ id, status }))
    );
  }

  override get scheme(): 'firebase' | 'hasura' {
    return 'hasura';
  }
}

export class ProductReviewDTO {
  productName?: string;
  productSku?: string;
  productEan?: string;
  status?: string;
}
