import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleDetailsComponent } from './articles/article-details/article-details.component';
import { ArticlesComponent } from './articles/articles.component';
import { CourseDetailComponent } from './courses/course-detail/course-detail.component';
import { CoursesComponent } from './courses/courses.component';
import { LiveDetailComponent } from './lives/live-detail/live-detail.component';
import { LivesComponent } from './lives/lives.component';

const routes: Routes = [
  { path: 'article', component: ArticlesComponent },
  { path: 'article/:articleId', component: ArticleDetailsComponent },
  { path: 'lives', component: LivesComponent },
  { path: 'lives/:liveId', component: LiveDetailComponent },
  { path: 'course', component: CoursesComponent },
  { path: 'course/:courseId', component: CourseDetailComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingModule {}
