/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriberInfo {
  subscriberId?: number;
  endEdition?: number;
  personId?: number;
  subscriptionId?: number;
  boxId?: string;
  startEdition?: number;
  remainingEditions?: number;
  lastEdition?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  subscriberStatus?: number;
  shippingStreetNumber?: string;
  shippingStreetExtensions?: string;
  shippingStreet?: string;
  shippingStateCode?: number;
  shippingState?: string;
  shippingPrice?: number;
  shippingPostalCode?: string;
  shippingPhone?: string;
  shippingNeighborhood?: string;
  shippingName?: string;
  shippingCountryCode?: number;
  shippingCountry?: string;
  shippingCityCode?: number;
  shippingCity?: string;
  subscriptionTypeId?: number;
  billingIsShipping?: boolean;
  nextEdition?: number;
  subscriptionPrice?: number;
  subscriptionTypeName?: string;
  subscriptionTypeInstallments?: number;
  subscriptionName?: string;
  status?: string;
  addressIdActiveOnSubscriber?: number;
  addressIdActiveOnPerson?: number;
  nextCycleDate?: Date;
}
