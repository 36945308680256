/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CourseModuleContentAccessDetail } from './courseModuleContentAccessDetail';

export interface CourseModuleContent {
  contentId?: number;
  moduleId?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  title?: string;
  subtitle?: string;
  description?: string;
  image?: string;
  code?: string;
  order?: number;
  active?: boolean;
  minutesTotal?: number;
  views?: Array<CourseModuleContentAccessDetail>;
}
