import { Inject, Injectable } from '@angular/core';
import {
  Filter,
  FilterOptionRepository,
  FilterRepository,
  Where
} from '@infrab4a/connect';
import { ConnectRequest } from 'src/app/models';
import { BaseConnectService } from './base-connect.service';

@Injectable({
  providedIn: 'root'
})
export class ShopCategoryFilterService extends BaseConnectService<Filter> {
  constructor(
    @Inject('FilterRepository') private filterRepository: FilterRepository,
    @Inject('FilterOptionRepository')
    private filterOptionsRepository: FilterOptionRepository
  ) {
    super(filterRepository);
  }

  async getFilterById(id: string): Promise<Filter> {
    return this.filterRepository.get({ id: Number(id) });
  }

  async getFilterBySlug(slug: string): Promise<Filter> {
    return this.filterRepository
      .find({
        filters: {
          slug: {
            operator: Where.EQUALS,
            value: slug
          }
        }
      })
      .then((res) => res.data.shift());
  }

  async getPaginetedFilterList(request: Partial<ConnectRequest>) {
    const result = await this.paginatedSearch(request);
    const content = result.data.map((category) => ({ ...category }));
    const totalPages = request.pageSize
      ? Math.floor(result.count / request.pageSize)
      : undefined;
    const totalElements = result.count;

    return {
      totalPages,
      totalElements,
      content,
      empty: result.count === 0
    };
  }

  async getFilterList(filters = null) {
    return this.filterRepository.find({ filters }).then((res) => res.data);
  }

  async saveFilter(filter: Partial<Filter>) {
    return this.filterRepository.create(Filter.toInstance(filter));
  }

  async updateFilter(filter: Partial<Filter>) {
    return this.filterRepository.update(Filter.toInstance(filter));
  }

  async deleteFilter(filterId: number): Promise<void> {
    await this.filterRepository.delete({ id: filterId });
  }

  async deleteFilterOption(optionId: string): Promise<void> {
    await this.filterOptionsRepository.delete({ id: optionId });
  }

  override get scheme(): 'firebase' | 'hasura' {
    return 'hasura';
  }
}
