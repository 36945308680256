import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import BlotFormatter from '@enzedonline/quill-blot-formatter2';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService, TreeDragDropService, TreeNode } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TreeNodeDropEvent } from 'primeng/tree';
import Quill from 'quill';
import { lastValueFrom, map } from 'rxjs';
import {
  Course,
  CourseControllerService,
  CourseLikeDetail,
  CourseModule,
  CourseModuleContent,
  CourseModuleContentAccessDetail
} from 'src/app/admin-api';
import { ContentFormComponent } from 'src/app/components/course-form/content-form/content-form.component';
import { ModuleFormComponent } from 'src/app/components/course-form/module-form/module-form.component';
import { TableColumn } from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrl: './course-detail.component.scss',
  providers: [
    DialogService,
    MessageService,
    NgxPermissionsService,
    TreeDragDropService
  ],
  encapsulation: ViewEncapsulation.None
})
export class CourseDetailComponent implements OnInit {
  courseId: number;
  private courseService = inject(CourseControllerService);
  permissionsService = inject(NgxPermissionsService);
  private messageService = inject(MessageService);
  private dialog = inject(DialogService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private title = inject(Title);

  course: Course;
  access: CourseModuleContentAccessDetail[];

  permission = false;
  modules: CourseModuleNode[];
  permissionsAccepted = [
    roleAsObject(Role.Full_Administrator)?.enumValue,
    roleAsObject(Role.Content)?.enumValue
  ];
  likes: CourseLikeDetail[];

  likeCols = [
    new TableColumn.Builder()
      .setHeader('Últ. atualização')
      .setField('dateUpdated')
      .setType('date')
      .setCondition('between')
      .build(),
    new TableColumn.Builder()
      .setHeader('Assinante')
      .setField('personName')
      .setCondition('contains')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Deu like?')
      .setField('like')
      .setType('boolean')
      .build()
  ];

  accessCols = [
    new TableColumn.Builder()
      .setHeader('Módulo')
      .setField('moduleName')
      .setCondition('contains')
      .setDisplayFunction(
        (v: CourseModuleContentAccessDetail) =>
          `${v.moduleOrder || 0}. ${v.moduleName}`
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Aula')
      .setField('title')
      .setCondition('contains')
      .setDisplayFunction(
        (v: CourseModuleContentAccessDetail) => `${v.order || 0}. ${v.title}`
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Últ. visualização')
      .setField('dateUpdated')
      .setType('date')
      .setCondition('between')
      .build(),
    new TableColumn.Builder()
      .setHeader('Primeira visualização')
      .setField('dateCreated')
      .setType('date')
      .setCondition('between')
      .build(),
    new TableColumn.Builder()
      .setHeader('Assinante')
      .setField('personName')
      .setCondition('contains')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Visualizações')
      .setField('views')
      .setType('formattedInteger')
      .setCondition('gte')
      .build()
  ];
  tabIndex = 0;

  async ngOnInit(): Promise<void> {
    Quill.register('modules/blotFormatter2', BlotFormatter);

    this.permission = await this.permissionsService.hasPermission(
      this.permissionsAccepted
    );
    this.activatedRoute.params.subscribe(async (params) => {
      if (params['courseId'] && params['courseId'] !== 'new') {
        this.courseId = Number(params['courseId']);
        LoaderService.showLoader();
        await Promise.all([this.findCourse(), this.findCourseLikes()]);
        this.title.setTitle('Detalhes do curso - ' + this.course.name);
        LoaderService.showLoader(false);
      } else if (!this.permission)
        AppDialogService.showErrorDialog({ message: 'Sem permissão' }, true);
      else this.title.setTitle('Novo curso');
      this.activatedRoute.queryParams.subscribe((qParam) => {
        if (qParam['tab'] && Number(qParam)) {
          this.navigate(Number(qParam));
        }
      });
    });
  }

  async findCourse() {
    try {
      this.model = await lastValueFrom(
        this.courseService
          .findCourseById(Number(this.courseId))
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async findCourseLikes() {
    try {
      this.likes = await lastValueFrom(
        this.courseService
          .getCourseLikes(Number(this.courseId))
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.likes = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async changeOrder($event: TreeNodeDropEvent): Promise<void> {
    const allowedDiffTypes = ['module', 'content'];
    if (
      $event.dragNode.parent?.key !== $event.dropNode.parent?.key ||
      ($event.dragNode.type !== $event.dropNode.type &&
        (!allowedDiffTypes.includes($event.dropNode.type) ||
          !allowedDiffTypes.includes($event.dragNode.type)))
    ) {
      AppDialogService.showErrorDialog(
        {},
        false,
        'Não é possível realizar esta ação'
      );
      return;
    }
    LoaderService.showLoader();
    try {
      if ($event.dragNode.type === 'module') {
        await lastValueFrom(
          this.courseService
            .updateCourseModulesOrder(
              this.modules
                .filter((m) => m.type === 'module')
                .map((m) => Number(m.key)),
              this.modelId
            )
            .pipe(map((data) => data.result))
        );
        await this.findCourse();
      } else {
        await lastValueFrom(
          this.courseService
            .updateCourseModuleContentsOrder(
              $event.dragNode.parent.children.map((q) => Number(q.key)),
              Number($event.dragNode.parent.key)
            )
            .pipe(map((data) => data.result))
        );
        await this.findCourse();
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async updateModule(module: CourseModule): Promise<void> {
    await lastValueFrom(
      this.courseService
        .updateCourseModule(module, module.moduleId)
        .pipe(map((data) => data.result))
    );
  }

  async updateModuleContent(content: CourseModuleContent): Promise<void> {
    await lastValueFrom(
      this.courseService
        .updateCourseModuleContent(content, content.contentId)
        .pipe(map((data) => data.result))
    );
  }

  async changeModuleStatus(module: CourseModule): Promise<void> {
    LoaderService.showLoader();
    try {
      await this.updateModule(module);
      await this.findCourse();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Módulo atualizado com sucesso'
      });
    } catch (error) {
      module.active = !module.active;
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async changeContentStatus(content: CourseModuleContent): Promise<void> {
    LoaderService.showLoader();
    try {
      await this.updateModuleContent(content);
      await this.findCourse();
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Video atualizada com sucesso'
      });
    } catch (error) {
      content.active = !content.active;
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  changeModule(module?: CourseModule): void {
    this.dialog
      .open(ModuleFormComponent, {
        data: {
          module,
          courseId: this.courseId
        },
        width: '600px',
        header:
          module && module?.moduleId
            ? 'Editar módulo ' + module.order
            : 'Novo módulo',
        maximizable: true,
        modal: true
      })
      .onClose.subscribe(async (data: CourseModule) => {
        if (data) {
          await this.findCourse();
          this.messageService.add({
            severity: 'success',
            detail:
              'Módulo ' + (module ? 'alterado' : 'criado') + ' com sucesso',
            summary: 'Sucesso'
          });
          LoaderService.showLoader(false);
        }
      });
  }

  changeContent(moduleId: number, content?: CourseModuleContent): void {
    this.dialog
      .open(ContentFormComponent, {
        data: {
          content,
          moduleId: moduleId
        },
        width: '600px',
        header:
          content && content?.contentId
            ? 'Editar video ' + content.order
            : 'Novo video',
        modal: true,
        maximizable: true
      })
      .onClose.subscribe(async (data: CourseModule) => {
        if (data) {
          await this.findCourse();
          this.messageService.add({
            severity: 'success',
            detail:
              'Video ' + (module ? 'alterado' : 'adicionado') + ' com sucesso',
            summary: 'Sucesso'
          });
          LoaderService.showLoader(false);
        }
      });
  }

  navigate(index: number) {
    this.tabIndex = index;
    this.router.navigate(['/content/course/' + this.course?.courseId], {
      queryParams: {
        tab: this.tabIndex
      },
      queryParamsHandling: 'merge'
    });
  }

  moduleViews(module: CourseModule) {
    return module.content?.reduce((sum, c) => (sum += this.contentViews(c)), 0);
  }

  contentViews(content: CourseModuleContent) {
    return content?.views?.reduce((sum, v) => (sum += v.views || 0), 0);
  }

  get modelId() {
    return this.course?.courseId;
  }

  get filePath(): string {
    return '/courses/';
  }

  get model(): Course {
    return this.course;
  }

  set model(course: Course) {
    course.modules.forEach((m) =>
      m.content.sort((c1, c2) => c1.order - c2.order)
    );
    course.modules.sort((m1, m2) => m1.order - m2.order);
    const expanded = this.modules?.filter((m) => m.expanded);
    this.course = course;
    this.modules = [
      {
        type: 'header',
        key: 'header',
        leaf: true,
        draggable: false,
        droppable: false,
        selectable: false
      } as CourseModuleNode
    ].concat(
      course.modules.map(
        (m) =>
          ({
            data: { ...m },
            key: m.moduleId.toString(),
            type: 'module',
            label: m.name,
            draggable: true,
            leaf: !m.content?.length,
            expanded: expanded?.some((e) => e.key === m.moduleId.toString()),
            children:
              m.content?.map((c) => ({
                key: c.contentId.toString(),
                data: { ...c },
                parent: { ...m },
                type: 'content',
                label: c.title,
                leaf: true,
                droppable: false
              })) || []
          } as CourseModuleNode)
      )
    );
    this.access =
      this.content?.reduce((list, c) => (list = list.concat(c.views)), []) ||
      [];
  }

  get content() {
    return this.course.modules?.reduce(
      (content: CourseModuleContent[], m) => content.concat(m.content || []),
      []
    );
  }

  get activeContent() {
    return this.course?.modules
      ?.filter((m) => m.active)
      .reduce(
        (content: CourseModuleContent[], m) =>
          content.concat(m.content?.filter((c) => c.active) || []),
        []
      );
  }

  get totalActiveDuration() {
    return (
      this.activeContent?.reduce((sum, c) => (sum += c.minutesTotal || 0), 0) ||
      0
    );
  }

  get activeModules() {
    return this.course?.modules?.filter(
      (m) => m.active && m.content?.some((c) => c.active)
    );
  }

  get totalLikes() {
    return this.likes?.filter((l) => l.like)?.length;
  }
}

export interface CourseModuleNode
  extends TreeNode<CourseModule | CourseModuleContent> {
  type: 'module' | 'content' | 'header';
}
