import { Inject, Injectable } from '@angular/core';
import { Category, CategoryRepository, Shops, Where } from '@infrab4a/connect';
import { CatalogService } from '@infrab4a/connect-angular';
import { ConnectRequest } from 'src/app/models';
import { BaseConnectService } from './base-connect.service';

@Injectable({
  providedIn: 'root'
})
export class ShopCategoryService extends BaseConnectService<Category> {
  constructor(
    @Inject('CategoryRepository')
    private categoryRepository: CategoryRepository,
    private catalogService: CatalogService
  ) {
    super(categoryRepository);
  }

  async getCategoryById(id: string): Promise<Category> {
    return this.categoryRepository.get({ id });
  }

  async getCategoryBySlug(slug: string, shop: Shops) {
    return this.categoryRepository
      .find({
        fields: ['id', 'slug'],
        filters: {
          slug: { operator: Where.EQUALS, value: slug },
          shop: { operator: Where.EQUALS, value: shop }
        }
      })
      .then((res) => res.data[0]);
  }

  async findCategoryBySlug(slug: string) {
    return this.categoryRepository
      .find({
        filters: {
          slug: { operator: Where.EQUALS, value: slug }
        }
      })
      .then((res) => res.data[0]);
  }

  async getCategoryByReference(reference: string, shop: Shops) {
    return this.categoryRepository
      .find({
        fields: ['id', 'slug'],
        filters: {
          reference: { operator: Where.EQUALS, value: reference },
          shop: { operator: Where.EQUALS, value: shop }
        }
      })
      .then((res) => res.data[0]);
  }

  async getCategoriesList(request: Partial<ConnectRequest>) {
    const result = await this.paginatedSearch(request);
    const totalPages = request.pageSize
      ? Math.floor(result.count / request.pageSize)
      : undefined;
    const totalElements = result.count;

    return {
      totalPages,
      totalElements,
      content: result.data,
      empty: result.count === 0
    };
  }

  async getCategoriesForProduct(): Promise<Category[]> {
    return this.categoryRepository
      .find({
        fields: ['id', 'name', 'reference', 'shop'],
        filters: {
          isCollection: { operator: Where.EQUALS, value: false },
          brandCategory: { operator: Where.EQUALS, value: false },
          reference: { operator: Where.ISNOTNULL },
          published: { operator: Where.EQUALS, value: true }
        },
        orderBy: {
          reference: 'asc'
        }
      })
      .then((res) => res.data);
  }

  async getCollections(): Promise<Category[]> {
    return this.categoryRepository
      .find({
        fields: ['id', 'name', 'reference', 'shop'],
        filters: {
          isCollection: { operator: Where.EQUALS, value: true },
          brandCategory: { operator: Where.EQUALS, value: false },
          published: { operator: Where.EQUALS, value: true },
          isWishlist: { operator: Where.EQUALS, value: false },
          shop: { operator: Where.EQUALS, value: Shops.GLAMSHOP }
        },
        orderBy: {
          reference: 'asc'
        }
      })
      .then((res) => res.data);
  }

  async getCategoriesListAll(): Promise<Category[]> {
    return this.categoryRepository
      .find({
        fields: ['id', 'name', 'reference', 'shop'],
        orderBy: {
          reference: 'asc'
        }
      })
      .then((res) => res.data);
  }

  async getCategoryProducts(category: Partial<Category>) {
    return this.getCategoryProductsPage(category);
  }

  async getCategoryProductsPage(category: Partial<Category>, perPage = 9999) {
    return this.catalogService.fetchProducts({
      category: category as Category,
      sort: 'most-relevant',
      perPage,
      page: 0
    });
  }

  async getCategoryHomeProducts(categoryId: string, shop: Shops) {
    return (
      (
        await this.categoryRepository.getCategoriesForHome([categoryId], shop)
      )?.at(0)?.products || []
    );
  }

  async saveCategory(category: Partial<Category>) {
    return this.categoryRepository.create(Category.toInstance(category));
  }

  async updateCategory(category: Partial<Category>) {
    return this.categoryRepository.update(Category.toInstance(category));
  }

  static categoryUpdateDTO(category: Partial<Category>) {
    return {
      ...category,
      parentId: category.parentId || BaseConnectService.NULL,
      reference: category.reference || BaseConnectService.NULL,
      metadatas: category.metadatas.length
        ? category.metadatas
        : BaseConnectService.REMOVE,
      mostRelevants: category.mostRelevants,
      filters: category.filters?.length
        ? category.filters
        : BaseConnectService.NULL,
      conditions: {
        ...category.conditions,
        tags: category.conditions?.tags?.length
          ? category.conditions.tags
          : BaseConnectService.NULL
      },
      parent: category.parent || BaseConnectService.NULL
    };
  }

  async getCategoriesTree(id: string, categoryTree = []): Promise<Category[]> {
    if (!id) return;

    const category = await this.getCategoryById(id);

    if (categoryTree.some((t) => t.id == category.id)) return categoryTree;

    categoryTree.push({
      id: category.id,
      name: category.name,
      slug: category.slug,
      reference: category.reference
    });

    if (category.parentId) {
      categoryTree = await this.getCategoriesTree(
        category.parentId.toString(),
        categoryTree
      );
    }

    return categoryTree;
  }

  override get scheme(): 'firebase' | 'hasura' {
    return 'hasura';
  }
}
