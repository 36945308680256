import { CurrencyPipe, DatePipe, TitleCasePipe } from '@angular/common';
import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation,
  WritableSignal,
  signal
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  Edition,
  EditionControllerService,
  KpiControllerService,
  SubscriberControlGroupBySubscriptionType,
  SubscriberControlGroupCounterOldest,
  SubscriberControlGroupDetail,
  SubscriptionControllerService,
  SubscriptionType
} from 'src/app/admin-api';
import { SubscriberGroupCounterChartDialogComponent } from 'src/app/components/subscriber-group-counter-chart/subscriber-group-counter-chart-dialog/subscriber-group-counter-chart-dialog.component';
import { TableColumn } from 'src/app/components/table';
import {
  EditionId,
  SubscriberGroupEnum,
  SubscriberSummary,
  getAllSubscriptions,
  getAllSubscriptionsIncludeAll
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FileUtil } from 'src/app/utils/file.util';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-subscriptions-dashboard',
  templateUrl: './subscriptions-dashboard.component.html',
  styleUrls: ['./subscriptions-dashboard.component.scss'],
  providers: [DatePipe, DialogService, TitleCasePipe],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionsDashboardComponent implements OnInit {
  form = signal(
    new FormGroup({
      subscriptionId: new FormControl<number>(1, [
        Validators.required,
        Validators.min(0)
      ]),
      editionId: new FormControl<number>(
        Number(
          `${new Date().getFullYear()}${(new Date().getMonth() + 1)
            .toString()
            .padStart(2, '0')}`
        ),
        [Validators.required, Validators.min(1)]
      )
    })
  );
  subscriptions: WritableSignal<Array<{ label: string; value: number }>> =
    signal(getAllSubscriptionsIncludeAll());
  editions: WritableSignal<Array<Edition>> = signal([]);
  subscriberControlGroups: WritableSignal<
    Array<SubscriberControlGroupBySubscriptionType>
  > = signal([]);
  parentGroups: WritableSignal<
    Array<
      SubscriberControlGroupDetail & {
        groups?: Array<
          SubscriberControlGroupDetail & {
            groups?: Array<
              | SubscriberControlGroupDetail & {
                  subscriptions?: Array<SubscriberControlGroupBySubscriptionType>;
                  groups?: Array<
                    SubscriberControlGroupDetail & {
                      subscriptions?: Array<SubscriberControlGroupBySubscriptionType>;
                    }
                  >;
                }
            >;
          }
        >;
      }
    >
  > = signal([]);
  cols: Array<TableColumn> = [
    new TableColumn('Assinatura', 'subscriptionName', false, 'text'),
    new TableColumn('Plano', 'subscriptionTypeName', false, 'text'),
    new TableColumn('Assinantes', 'subscriberCount', false, 'number'),
    new TableColumn('Total', 'installmentTotal', false, 'currency'),
    new TableColumn('Ticket Médio', 'installmentAvg', false, 'currency')
  ];
  subscriptionTypes:
    | Array<{
        subscriptionTypeName: string;
        subscriptionName: string;
        subscriptionTypeId: number;
      }>
    | undefined;
  groupLabels: Array<{ subscriberGroupId: number; label: string }> = [
    {
      subscriberGroupId: SubscriberGroupEnum.Conquistadas_com_pagamento,
      label: 'Aquisiçao'
    },
    {
      subscriberGroupId: SubscriberGroupEnum.Reativacoes_com_pagamento,
      label: 'Reativação'
    },
    {
      subscriberGroupId: SubscriberGroupEnum.Pendentes_Convertidas,
      label: 'Mesma'
    },
    {
      subscriberGroupId: SubscriberGroupEnum.Upgrade_Assinatura,
      label: 'Upgrade'
    },
    {
      subscriberGroupId: SubscriberGroupEnum.Downgrade_Assinatura,
      label: 'Downgrade'
    },
    {
      subscriberGroupId: SubscriberGroupEnum.Mantidas_com_voucher,
      label: 'Mantidas voucher'
    },
    {
      subscriberGroupId: SubscriberGroupEnum.Pendente_falha_de_pagamento,
      label: 'Falha pagamento'
    },
    {
      subscriberGroupId: SubscriberGroupEnum.Pendentes,
      label: 'Aguardando pagamento'
    },
    {
      subscriberGroupId: SubscriberGroupEnum.Recorrencia_Desabilitada,
      label: 'Atual'
    },
    {
      subscriberGroupId: SubscriberGroupEnum.Expiradas,
      label: 'Recorrencia desabilitada'
    },
    {
      subscriberGroupId: SubscriberGroupEnum.Vouchers_Finalizados,
      label: 'Voucher expirado'
    },
    {
      subscriberGroupId:
        SubscriberGroupEnum.Pendente_falha_de_pagamento_retentativa,
      label: 'Falha pagamento (Retentativa)'
    },
    {
      subscriberGroupId: SubscriberGroupEnum.Falhas_de_Pagamento_retentativa,
      label: 'Falha pagamento (Retentativa)'
    },
    {
      subscriberGroupId:
        SubscriberGroupEnum.Reativacoes_com_pagamento_retentativa,
      label: 'Reativação (Retentativa)'
    }
  ];
  oldestCounters: Array<SubscriberControlGroupCounterOldest> | undefined;
  subscriptionTypeList: Array<SubscriptionType> | undefined;
  isTablet = false;
  isMobile = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }

  constructor(
    private kpiService: KpiControllerService,
    private editionService: EditionControllerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private title: Title,
    private titlecasePipe: TitleCasePipe,
    private dialog: DialogService,
    private datePipe: DatePipe,
    private subscriptionService: SubscriptionControllerService,
    private currencyPipe: CurrencyPipe
  ) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isTablet = window.innerWidth < 1300;
    this.isMobile = window.innerWidth < 667;
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params.subscribe(async (params) => {
      if (params['editionId']) {
        this.form.update((f) => {
          f.patchValue({
            editionId: Number(params['editionId']),
            subscriptionId: Number((params['editionId'] / 1000000).toFixed(0))
          });
          return f;
        });
        LoaderService.showLoader();
        const fail = document.getElementById('img-fail');
        if (fail?.style?.display === 'none') {
          fail.style.display = 'block';
        }
        await this.findEditions();
        await this.findPage();
        this.title.setTitle(
          'Dashboard de Assinaturas - ' + this.edition?.theme
        );
        LoaderService.showLoader(false);
      } else {
        this.navigate();
      }
    });
  }

  async findPage(): Promise<void> {
    await Promise.all([
      this.findSubscriptionsDashboardByEditionId(),
      this.findOldestCounters(),
      this.findSubscriptionTypes()
    ]);
    if (this.subscriberControlGroups()?.length) {
      this.parentGroups.set([
        {
          name: 'Ativas',
          groups: [
            {
              editionId: this.form().value.editionId as number,
              installmentAvg: 0,
              installmentTotal: 0,
              name: 'Novas',
              subscriberCount: 0,
              subscriptionId: this.form().value.subscriptionId as number,
              subscriberGroupId: SubscriberGroupEnum.Conquistadas,
              groups: []
            },
            {
              editionId: this.form().value.editionId as number,
              installmentAvg: 0,
              installmentTotal: 0,
              name: 'Mantidas',
              subscriberCount: 0,
              subscriptionId: this.form().value.subscriptionId as number,
              subscriberGroupId: SubscriberGroupEnum.Mantidas,
              groups: []
            }
          ],
          subscriberCount: 0,
          subscriberGroupId: 0
        }
      ]);
      // Edição desse mes ou posterior
      if (
        EditionId.isAfterOrEqual(
          this.form().value.editionId as number,
          EditionId.currentEdition(this.form().value.subscriptionId || 0)
        )
      ) {
        this.parentGroups.update((p) => {
          p.push({
            name: 'Congeladas',
            groups: [
              {
                editionId: this.form().value.editionId as number,
                installmentAvg: 0,
                installmentTotal: 0,
                name: 'Não garantido',
                subscriberCount: 0,
                subscriptionId: this.form().value.subscriptionId as number,
                subscriberGroupId: SubscriberGroupEnum.Pendentes,
                groups: []
              },
              {
                editionId: this.form().value.editionId as number,
                installmentAvg: 0,
                installmentTotal: 0,
                name: 'Recorrência desabilitada',
                subscriberCount: 0,
                subscriptionId: this.form().value.subscriptionId as number,
                subscriberGroupId: SubscriberGroupEnum.Recorrencia_Desabilitada,
                groups: []
              }
            ],
            subscriberCount: 0,
            subscriberGroupId: SubscriberGroupEnum.Pendentes
          });
          return p;
        });
      }
      this.parentGroups.update((p) => {
        p.push({
          name: 'Inativas',
          groups: [
            {
              editionId: this.form().value.editionId as number,
              installmentAvg: 0,
              installmentTotal: 0,
              name: 'Inativas',
              subscriberCount: 0,
              subscriptionId: this.form().value.subscriptionId as number,
              subscriberGroupId: SubscriberGroupEnum.CHURN,
              groups: []
            }
          ],
          subscriberCount: 0,
          subscriberGroupId: SubscriberGroupEnum.CHURN
        });
        return p;
      });
      this.subscriptionTypes = [];
      this.subscriberControlGroups().forEach((s) => {
        if (
          s.subscriptionTypeId &&
          this.subscriptionTypes?.every(
            (st) => st.subscriptionTypeId !== s.subscriptionTypeId
          )
        ) {
          this.subscriptionTypes.push({
            subscriptionTypeId: s.subscriptionTypeId,
            subscriptionTypeName: s.subscriptionTypeName as string,
            subscriptionName: s.subscriptionName as string
          });
        }
      });
      this.subscriptionTypes.sort((s1, s2) => {
        if (
          s1.subscriptionName.toUpperCase() === 'GLAMBOX' &&
          s2.subscriptionName.toUpperCase() !== 'GLAMBOX'
        ) {
          return -1;
        } else if (
          s2.subscriptionName.toUpperCase() === 'GLAMBOX' &&
          s1.subscriptionName.toUpperCase() !== 'GLAMBOX'
        ) {
          return 1;
        }
        if (
          this.titlecasePipe.transform(
            `${s1.subscriptionName} ${s1.subscriptionTypeName}`
          ) <
          this.titlecasePipe.transform(
            `${s2.subscriptionName} ${s2.subscriptionTypeName}`
          )
        )
          return -1;
        else if (
          this.titlecasePipe.transform(
            `${s2.subscriptionName} ${s2.subscriptionTypeName}`
          ) <
          this.titlecasePipe.transform(
            `${s1.subscriptionName} ${s1.subscriptionTypeName}`
          )
        )
          return 1;
        return s1.subscriptionTypeId - s2.subscriptionTypeId;
      });
      this.parentGroups().forEach((pg) => {
        pg.groups?.forEach((g) => {
          g.groups = this.subscriberControlGroups()
            ?.filter((s) => {
              return (
                s.parentId === g.subscriberGroupId ||
                g.subscriberGroupId === s.subscriberGroupId
              );
            })
            .reduce((groups: Array<SubscriberControlGroupDetail>, s) => {
              const exists = groups.find(
                (gg) => gg.subscriberGroupId === s.subscriberGroupId
              );
              if (!exists) {
                groups.push({
                  description: s.description,
                  editionId: s.editionId,
                  installmentAvg: s.installmentAvg || 0,
                  installmentTotal: s.installmentTotal || 0,
                  name: s.name,
                  parentId: s.parentId,
                  subscriberCount: s.subscriberCount || 0,
                  subscriberGroupId: s.subscriberGroupId,
                  subscriptionId: Number((s.editionId / 1000000).toFixed(0))
                });
              } else {
                exists.installmentTotal =
                  (exists.installmentTotal || 0) + (s.installmentTotal || 0);
                exists.subscriberCount =
                  (exists.subscriberCount || 0) + (s.subscriberCount || 0);
                exists.installmentAvg =
                  (exists.installmentTotal || 0) /
                  (exists.subscriberCount || 1);
              }
              return groups;
            }, []);
          g.groups?.sort(
            (g1, g2) =>
              (g1.subscriberGroupId as number) -
              (g2.subscriberGroupId as number)
          );
          g.groups?.forEach(
            (gg) =>
              (gg.subscriptions = this.subscriptionTypes?.map((st) => {
                return (
                  this.subscriberControlGroups()
                    ?.filter(
                      (sc) =>
                        sc.subscriberGroupId === gg.subscriberGroupId &&
                        sc.subscriptionTypeId === st.subscriptionTypeId
                    )
                    .reduce(
                      (sum, s) => {
                        sum.subscriberCount += s.subscriberCount;
                        return sum;
                      },
                      {
                        installmentAvg: 0,
                        installmentTotal: 0,
                        subscriberCount: 0,
                        editionId: gg.editionId,
                        name: gg.name,
                        parentId: gg.parentId,
                        subscriberGroupId: gg.subscriberGroupId,
                        subscriptionTypeId: st.subscriptionTypeId,
                        subscriptionTypeName: st.subscriptionTypeName,
                        subscriptionName: st.subscriptionName
                      }
                    ) || {
                    installmentAvg: 0,
                    installmentTotal: 0,
                    subscriberCount: 0,
                    editionId: gg.editionId,
                    name: gg.name,
                    parentId: gg.parentId,
                    subscriberGroupId: gg.subscriberGroupId,
                    subscriptionTypeId: st.subscriptionTypeId,
                    subscriptionTypeName: st.subscriptionTypeName,
                    subscriptionName: st.subscriptionName
                  }
                );
              }))
          );
          if (
            g.groups?.length &&
            g.subscriberGroupId === SubscriberGroupEnum.Mantidas
          ) {
            let idx = g.groups?.findIndex(
              (gg) =>
                gg.subscriberGroupId ===
                SubscriberGroupEnum.Pendentes_Convertidas
            );
            g.groups?.push({
              name: 'Renovações',
              groups: [],
              subscriberGroupId:
                SubscriberGroupEnum.Renovacao_com_troca_de_plano_cupom,
              editionId: this.form().value.editionId
            });
            if (idx !== undefined && idx >= 0) {
              (g.groups[(g.groups?.length || 1) - 1] as any).groups.push(
                g.groups[idx]
              );
              g.groups?.splice(idx, 1);
            }
            idx = g.groups?.findIndex(
              (gg) =>
                gg.subscriberGroupId === SubscriberGroupEnum.Upgrade_Assinatura
            );
            if (idx !== undefined && idx >= 0) {
              (g.groups[(g.groups?.length || 1) - 1] as any).groups.push(
                g.groups[idx]
              );
              g.groups?.splice(idx, 1);
            }
            idx = (g.groups as Array<any>)?.findIndex(
              (gg) =>
                gg.subscriberGroupId ===
                SubscriberGroupEnum.Downgrade_Assinatura
            );
            if (idx !== undefined && idx >= 0) {
              (g.groups[(g.groups?.length || 1) - 1] as any).groups.push(
                g.groups[idx]
              );
              g.groups?.splice(idx, 1);
            }
            if (g.groups[(g.groups?.length || 1) - 1].groups?.length) {
              g.groups[(g.groups?.length || 1) - 1].subscriberCount = g.groups[
                (g.groups?.length || 1) - 1
              ].groups.reduce((sum, g) => (sum += g.subscriberCount || 0), 0);
            } else {
              g.groups.splice((g.groups?.length || 1) - 1);
            }
          }
          g.groups?.sort(
            (g1, g2) =>
              (g1.subscriberGroupId as number) -
              (g2.subscriberGroupId as number)
          );
          g.installmentTotal = g.groups?.reduce(
            (sum, s) => (sum += s.installmentTotal || 0),
            0
          );
          g.subscriberCount = g.groups?.reduce((sum, s) => {
            if (s.subscriptions) {
              sum += s.subscriberCount || 0;
            } else {
              sum +=
                s.groups?.reduce(
                  (gSum, ggg) => (gSum += ggg.subscriberCount || 0),
                  0
                ) || 0;
            }

            return sum;
          }, 0);
          g.installmentAvg =
            (g.installmentTotal || 0) / (g.subscriberCount || 1);
        });
        pg.subscriberCount = pg.groups?.reduce(
          (sum, s) => (sum += s.subscriberCount || 0),
          0
        );
      });
      // this.parentGroups = this.parentGroups.filter((p) => p.subscriberCount);
      this.parentGroups().forEach((p) => {
        p.groups?.forEach((g) => {
          if (!g.groups?.length) {
            g.groups = [
              {
                name: g.name,
                subscriberCount: 0,
                subscriptions:
                  this.subscriptionTypes?.map((st) => ({
                    subscriberCount: 0,
                    subscriptionTypeName: st.subscriptionTypeName,
                    subscriptionTypeId: st.subscriptionTypeId,
                    subscriptionName: st.subscriptionName
                  })) || []
              }
            ];
          }
        });
      });
      this.oldestCounters?.sort(
        (c1, c2) =>
          (c1.subscriptionTypeId as number) - (c2.subscriptionTypeId as number)
      );
      if (!this.futureEdition) {
        this.parentGroups()
          .find((g) => g.subscriberGroupId === SubscriberGroupEnum.Pendentes)
          ?.groups?.find(
            (gg) => gg.subscriberGroupId === SubscriberGroupEnum.Pendentes
          )
          ?.groups?.unshift({
            name: 'Início do mês',
            subscriberCount: this.oldestCounters
              ?.filter(
                (c) =>
                  [
                    SubscriberGroupEnum.Pendentes,
                    SubscriberGroupEnum.Pendente_falha_de_pagamento,
                    SubscriberGroupEnum.Renovacao_com_troca_de_plano_cupom,
                    SubscriberGroupEnum.Pendentes_Convertidas,
                    SubscriberGroupEnum.Upgrade_Assinatura,
                    SubscriberGroupEnum.Downgrade_Assinatura,
                    SubscriberGroupEnum.Outros_mantidas,
                    SubscriberGroupEnum.Mantidas_com_voucher
                  ].includes(c.subscriberGroupId)
                // c.subscriberGroupId === SubscriberGroupEnum.Pendentes ||
                // c.subscriberGroupId ===
                //   SubscriberGroupEnum.Pendente_falha_de_pagamento
              )
              .reduce((sum, c) => (sum += c.itemCount || 0), 0),
            subscriptions: this.subscriptionTypes?.map((st) => {
              const oldest = this.oldestCounters?.filter(
                (c) =>
                  [
                    SubscriberGroupEnum.Pendentes,
                    SubscriberGroupEnum.Pendente_falha_de_pagamento,
                    SubscriberGroupEnum.Renovacao_com_troca_de_plano_cupom,
                    SubscriberGroupEnum.Pendentes_Convertidas,
                    SubscriberGroupEnum.Upgrade_Assinatura,
                    SubscriberGroupEnum.Downgrade_Assinatura,
                    SubscriberGroupEnum.Outros_mantidas,
                    SubscriberGroupEnum.Mantidas_com_voucher
                  ].includes(c.subscriberGroupId) &&
                  c.subscriptionTypeId === st.subscriptionTypeId
              );
              return {
                name: st.subscriptionTypeName,
                subscriptionTypeId: st.subscriptionTypeId,
                subscriberCount:
                  oldest?.reduce((sum, o) => (sum += o.itemCount || 0), 0) || 0,
                subscriptionTypeName: st.subscriptionTypeName,
                subscriptionName: st.subscriptionName
              } as SubscriberControlGroupBySubscriptionType;
            })
          });
        this.parentGroups()
          .find((g) => g.subscriberGroupId === SubscriberGroupEnum.Pendentes)
          ?.groups?.find(
            (gg) =>
              gg.subscriberGroupId ===
              SubscriberGroupEnum.Recorrencia_Desabilitada
          )
          ?.groups?.unshift({
            name: 'Início do mês',
            subscriberCount: this.oldestCounters
              ?.filter(
                (c) =>
                  c.subscriberGroupId ===
                    SubscriberGroupEnum.Recorrencia_Desabilitada ||
                  c.subscriberGroupId === SubscriberGroupEnum.Voucher_Expirando
              )
              .reduce((sum, c) => (sum += c.itemCount || 0), 0),
            subscriptions: this.subscriptionTypes?.map((st) => {
              const oldest = this.oldestCounters?.filter(
                (c) =>
                  (c.subscriberGroupId ===
                    SubscriberGroupEnum.Recorrencia_Desabilitada ||
                    c.subscriberGroupId ===
                      SubscriberGroupEnum.Voucher_Expirando) &&
                  c.subscriptionTypeId === st.subscriptionTypeId
              );
              return {
                name: st.subscriptionTypeName,
                subscriptionTypeId: st.subscriptionTypeId,
                subscriberCount:
                  oldest?.reduce((sum, o) => (sum += o.itemCount || 0), 0) || 0,
                subscriptionTypeName: st.subscriptionTypeName,
                subscriptionName: st.subscriptionName
              } as SubscriberControlGroupBySubscriptionType;
            })
          });
      }
      if (this.pastEdition) {
        const churn = this.parentGroups()
          .find((g) => g.subscriberGroupId === SubscriberGroupEnum.CHURN)
          ?.groups?.find(
            (gg) => gg.subscriberGroupId === SubscriberGroupEnum.CHURN
          );
        if (churn) {
          const groups = churn.groups
            .filter(
              (g) => g.subscriberGroupId === SubscriberGroupEnum.Canceladas
            )
            .concat([
              {
                name: 'Início do mês',
                subscriberCount: this.oldestCounters
                  ?.filter(
                    (c) =>
                      c.subscriberGroupId ===
                        SubscriberGroupEnum.Recorrencia_Desabilitada ||
                      c.subscriberGroupId ===
                        SubscriberGroupEnum.Voucher_Expirando
                  )
                  .reduce((sum, c) => (sum += c.itemCount || 0), 0),
                subscriptions: this.subscriptionTypes?.map((st) => {
                  const oldest = this.oldestCounters?.filter(
                    (c) =>
                      (c.subscriberGroupId ===
                        SubscriberGroupEnum.Recorrencia_Desabilitada ||
                        c.subscriberGroupId ===
                          SubscriberGroupEnum.Voucher_Expirando) &&
                      c.subscriptionTypeId === st.subscriptionTypeId
                  );
                  return {
                    name: st.subscriptionTypeName,
                    subscriptionTypeId: st.subscriptionTypeId,
                    subscriberCount:
                      oldest?.reduce(
                        (sum, o) => (sum += o.itemCount || 0),
                        0
                      ) || 0,
                    subscriptionTypeName: st.subscriptionTypeName,
                    subscriptionName: st.subscriptionName
                  } as SubscriberControlGroupBySubscriptionType;
                })
              }
            ])
            .concat(
              churn.groups.filter(
                (g) => g.subscriberGroupId === SubscriberGroupEnum.Expiradas
              )
            )
            .concat([
              {
                name: 'Não confirmado início',
                subscriberCount: this.oldestCounters
                  ?.filter(
                    (c) =>
                      c.subscriberGroupId === SubscriberGroupEnum.Pendentes ||
                      c.subscriberGroupId ===
                        SubscriberGroupEnum.Pendente_falha_de_pagamento
                  )
                  .reduce((sum, c) => (sum += c.itemCount || 0), 0),
                subscriptions: this.subscriptionTypes?.map((st) => {
                  const oldest = this.oldestCounters?.filter(
                    (c) =>
                      (c.subscriberGroupId === SubscriberGroupEnum.Pendentes ||
                        c.subscriberGroupId ===
                          SubscriberGroupEnum.Pendente_falha_de_pagamento) &&
                      c.subscriptionTypeId === st.subscriptionTypeId
                  );
                  return {
                    name: st.subscriptionTypeName,
                    subscriptionTypeId: st.subscriptionTypeId,
                    subscriberCount:
                      oldest?.reduce(
                        (sum, o) => (sum += o.itemCount || 0),
                        0
                      ) || 0,
                    subscriptionTypeName: st.subscriptionTypeName,
                    subscriptionName: st.subscriptionName
                  } as SubscriberControlGroupBySubscriptionType;
                })
              }
            ])
            .concat(
              churn.groups.filter(
                (g) =>
                  g.subscriberGroupId !== SubscriberGroupEnum.Expiradas &&
                  g.subscriberGroupId !== SubscriberGroupEnum.Canceladas
              )
            );
          churn.groups = groups;
        }
      }
    }
  }

  async findSubscriptionsDashboardByEditionId(): Promise<void> {
    try {
      const groups = await lastValueFrom(
        this.kpiService
          .findSubscriptionDashboardByEditionId(
            this.form().value.editionId as number
          )
          .pipe(
            map((data) => {
              data.result?.forEach((s) => {
                if (s.subscriptionTypeName?.toLowerCase() === 'anual.') {
                  s.subscriptionTypeName = 'Anual/mês';
                }
                if (
                  s.subscriberGroupId ===
                  SubscriberGroupEnum.Renovacao_com_troca_de_plano_cupom
                ) {
                  s.subscriberGroupId =
                    SubscriberGroupEnum.Pendentes_Convertidas;
                }
              });
              return data.result?.filter(
                (s) =>
                  (s.subscriberGroupId as number) !==
                    SubscriberGroupEnum.Edicao_Avulsa &&
                  (s.subscriberGroupId as number) !==
                    SubscriberGroupEnum.Canceladas_Fraude &&
                  (s.subscriberGroupId as number) !==
                    SubscriberGroupEnum.Estornos
              );
            })
          )
      );
      groups.sort((g1, g2) => g1.editionId - g2.editionId);
      this.subscriberControlGroups.set(groups);
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
      this.subscriberControlGroups.set([]);
    }
  }

  async findOldestCounters(): Promise<void> {
    try {
      this.oldestCounters = await lastValueFrom(
        this.kpiService
          .findSubscriptionDashboardOlderCounters(
            this.form().value.editionId || 0
          )
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      this.oldestCounters = [];
    }
  }

  async findEditions(): Promise<void> {
    try {
      const editions = await lastValueFrom(
        this.editionService
          .findEditionsBySubscriptionId(this.form().value.subscriptionId || 1)
          .pipe(map((data) => data.result))
      );
      if (this.form().value.subscriptionId === 0) {
        editions?.forEach((e) => (e.editionId = (e.editionId || 0) % 1000000));
      }
      this.editions.set(editions);
    } catch (error: any) {
      this.editions.set([]);
    }
  }

  async changeSubscription(): Promise<void> {
    LoaderService.showLoader();
    const form = { ...this.form().value };
    this.form.update((f) => {
      f.controls['editionId'].reset(
        (form.subscriptionId || 0) * 1000000 + ((form.editionId || 0) % 1000000)
      );
      return f;
    });
    this.navigate();
    LoaderService.showLoader(false);
  }

  async changeEdition(): Promise<void> {
    LoaderService.showLoader();
    this.navigate();
    LoaderService.showLoader(false);
  }

  editionDate(editionId: number): string {
    return FormUtil.editionDate(editionId);
  }

  editionDateName(editionId: number): string {
    return (
      this.datePipe.transform(
        new Date(
          `${((editionId % 1000000) / 100).toFixed(0)}-${editionId % 100}-02`
        ),
        'MMMM/yyyy'
      ) || this.editionDate(editionId)
    );
  }

  lastMonthDifference(current: number, last: number): number {
    return Number((current - last).toFixed(2));
  }

  lastMonthPercentage(current: number, last: number): number {
    return Number(
      (
        Number(this.lastMonthDifference(current, last) / (last || 1)) * 100
      ).toFixed(1)
    );
  }

  navigate(): void {
    this.router.navigate([
      '/dashboard/subscriptions/' + this.form().value.editionId
    ]);
  }

  subscriberGroupName(subcriberGroupId?: number): string | undefined {
    return (
      this.groupLabels.find((g) => g.subscriberGroupId === subcriberGroupId)
        ?.label ||
      (subcriberGroupId !== undefined
        ? SubscriberGroupEnum[subcriberGroupId].replaceAll('_', ' ')
        : undefined)
    );
  }

  subscriptionFullName(
    group: SubscriberControlGroupBySubscriptionType
  ): string {
    const name =
      this.titlecasePipe.transform(group.subscriptionName) +
      ' ' +
      group.subscriptionTypeName;
    if (name.length > 25) {
      return name.slice(0, 25) + '...';
    } else {
      return name;
    }
  }

  async showChart(group: SubscriberControlGroupDetail): Promise<void> {
    LoaderService.showLoader();
    try {
      const data = await lastValueFrom(
        this.kpiService
          .findSubscriberDailyReport(
            this.form().value.editionId as number,
            group.subscriberGroupId as number
          )
          .pipe(map((data) => data.result))
      );
      LoaderService.showLoader(false);
      if (data) {
        this.dialog.open(SubscriberGroupCounterChartDialogComponent, {
          header: group.name,
          width: '80%',
          height: '90vh',
          maximizable: true,
          data: {
            subscriberGroupCounters: data,
            graphType:
              group.subscriberGroupId ===
                SubscriberGroupEnum.Pagamentos_Garantidos ||
              group.subscriberGroupId ===
                SubscriberGroupEnum.Mantidas_com_voucher
                ? 'monthly'
                : 'daily',
            subscriptionTypes: this.subscriptionTypeList
          }
        });
      }
    } catch (error: any) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  hasChart(group: SubscriberControlGroupDetail): boolean {
    return (
      group.parentId !== undefined &&
      group.parentId >= SubscriberGroupEnum.Mantidas
    );
  }

  async findSubscribersAndExport(
    group: SubscriberControlGroupDetail
  ): Promise<void> {
    LoaderService.showLoader();
    try {
      const data = await lastValueFrom(
        this.kpiService
          .findSubscriberByEditionIdAndSubscriberGroupId(
            this.form().value.editionId as number,
            group.subscriberGroupId as number
          )
          .pipe(
            map((data) =>
              data.result?.map(
                (c) =>
                  ({
                    BoxId: c.boxId,
                    'Tipo de assinante': c.personType,
                    Nome: c.name,
                    Telefone: c.phone,
                    CPF: FormUtil.cpfFormatted(c.cpf as number),
                    'E-mail': c.email,
                    Assinatura: this.titlecasePipe.transform(
                      c.subscriptionName
                    ),
                    Plano: c.subscriptionTypeName,
                    Joia: c.badge,
                    Status: c.subscriberStatus,
                    Edição: c.theme,
                    Produto: this.titlecasePipe.transform(
                      c.subscriberEditionName
                    ),
                    Cupom: c.couponName || '-',
                    Composição: c.compositionName || '-',
                    'Status edição': c.subscriberEditionStatusName,
                    'Status Millennium': c.statusMillennium,
                    Total:
                      c.amount !== undefined
                        ? this.currencyPipe.transform(c.amount, 'BRL')
                        : '-',
                    'Data Pagamento': c.dateRenewed
                      ? this.datePipe.transform(
                          FormUtil.utcDate(c.dateRenewed),
                          'dd/MM/yyyy HH:mm:ss'
                        )
                      : '-'
                  } as SubscriberSummary)
              )
            )
          )
      );
      if (data?.length) {
        FileUtil.exportExcel(
          data,
          `subscribers_${group.editionId}_${FormUtil.semAcento(
            this.subscriberGroupName(group.subscriberGroupId)
          )}`,
          Object.keys(data[0])
        );
        LoaderService.showLoader(false);
      } else {
        AppDialogService.showErrorDialog({
          message: 'Sem dados para exportar.'
        });
      }
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
    LoaderService.showLoader(false);
  }

  async findSubscriptionTypes(): Promise<void> {
    try {
      if (this.form().value.subscriptionId)
        this.subscriptionTypeList = await lastValueFrom(
          this.subscriptionService
            .findSubscriptionTypeList(this.form().value.subscriptionId)
            .pipe(map((data) => data.result))
        );
      else {
        const subscriptions = await Promise.all(
          getAllSubscriptions().map((s) =>
            lastValueFrom(
              this.subscriptionService
                .findSubscriptionTypeList(s.value)
                .pipe(map((data) => data.result))
            )
          )
        );
        this.subscriptionTypeList = subscriptions.reduce(
          (subs: Array<SubscriptionType>, s) => (subs = subs.concat(s || [])),
          []
        );
      }
    } catch (error: any) {
      this.subscriptionTypes = [];
    }
  }

  compareToStartOfMonth(
    group:
      | SubscriberControlGroupDetail
      | SubscriberControlGroupBySubscriptionType
  ): number {
    return group.subscriberCount - this.startOfMonthCount(group);
  }

  startOfMonthCount(
    group:
      | SubscriberControlGroupDetail
      | SubscriberControlGroupBySubscriptionType
  ): number {
    return this.oldestCounters
      .filter((c) => {
        if (c.subscriberGroupId !== group.subscriberGroupId) return false;
        if (
          (group as SubscriberControlGroupBySubscriptionType).subscriptionTypeId
        ) {
          return (
            (group as SubscriberControlGroupBySubscriptionType)
              .subscriptionTypeId === c.subscriptionTypeId
          );
        } else {
          return true;
        }
      })
      .reduce((sum, c) => (sum += c.itemCount || 0), 0);
  }

  get editionImage(): string {
    return FormUtil.editionImage(this.edition?.editionId || 0);
  }

  get edition(): Edition | undefined {
    return this.editions()?.find(
      (e) => e.editionId === this.form().value.editionId
    );
  }

  get futureEdition(): boolean {
    return EditionId.isAfter(
      this.form().value.editionId,
      EditionId.currentEdition(this.form().value.subscriptionId)
    );
  }

  get pastEdition(): boolean {
    return EditionId.isBefore(
      this.form().value.editionId,
      EditionId.currentEdition(this.form().value.subscriptionId)
    );
  }
}
