import { DecimalPipe } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Product } from '@infrab4a/connect';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { ErpControllerService } from 'src/app/admin-api';
import {
  DropdownFilter,
  TableColumn,
  TableComponent
} from 'src/app/components/table';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { NullValue } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-shop-products',
  templateUrl: './shop-products.component.html',
  styleUrl: './shop-products.component.scss',
  providers: [DecimalPipe]
})
export class ShopProductsComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent;
  private title = inject(Title);
  protected productService = inject(ShopProductService);
  private erpService = inject(ErpControllerService);
  private messageService = inject(MessageService);

  cols = [
    new TableColumn.Builder()
      .setHeader('ID')
      .setField('id')
      .setRouterLink('product/')
      .setRouterLinkFieldName('id')
      .setCondition('equals')
      .build(),
    new TableColumn.Builder()
      .setHeader('Nome')
      .setField('name')
      .setRouterLink('product/')
      .setRouterLinkFieldName('id')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('EAN')
      .setField('EAN')
      .setRouterLink('product/')
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn.Builder()
      .setHeader('SKU')
      .setField('sku')
      .setRouterLink('product/')
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn.Builder()
      .setHeader('Tipo')
      .setField('type')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Marca')
      .setField('brand')
      .setCondition('contains')
      .setRouterLink('/shop-products/categories/category/')
      .setRouterLinkFieldName('category_id')
      .build(),
    new TableColumn.Builder()
      .setHeader('Possui variantes?')
      .setField('hasVariants')
      .setType('boolean')
      .build(),
    new TableColumn.Builder()
      .setHeader('Quantidade')
      .setField('stock')
      .setDisplayFunction(
        (product: Partial<Product>) => product.stock?.quantity || 0
      )
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ativo')
      .setField('published')
      .setType('inputSwitch')
      .build(),
    new TableColumn.Builder()
      .setHeader('Label')
      .setField('label')
      .setType('text')
      .setSortable(false)
      .setCondition('equals')
      .setDisplayFunction((product: Partial<Product>) =>
        ShopProductService.getProductLabel(product.label)
      )
      .build(),
    new TableColumn.Builder()
      .setHeader('Tags')
      .setField('tags')
      .setType('text')
      .setSortable(false)
      .setCondition('in')
      .build(),
    new TableColumn.Builder()
      .setHeader('Filtros')
      .setField('filters')
      .setType('text')
      .setSortable(false)
      .setCondition('in')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data criação')
      .setField('createdAt')
      .setType('date')
      .setCondition('between')
      .build(),
    new TableColumn.Builder()
      .setHeader('Últ. atualização')
      .setField('updatedAt')
      .setType('date')
      .setCondition('between')
      .build()
  ];
  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    published: [
      { label: 'Todos', value: NullValue },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ],
    hasVariants: [
      { label: 'Todos', value: NullValue },
      { label: 'Sim', value: true },
      { label: 'Não', value: false }
    ],
    label: [
      { label: 'Todos', value: '' },
      { label: 'Sem label', value: NullValue },
      { label: 'Outlet', value: 'outlet' },
      { label: 'Promoção', value: 'on-sale' },
      { label: 'Últimas unidades', value: 'last-units' },
      { label: 'Glamstar', value: 'glamstar' }
    ]
  };

  ngOnInit() {
    this.title.setTitle('Loja - Produtos');
  }

  async updatePublished(product: Product) {
    LoaderService.showLoader();
    try {
      await this.productService.updateProduct({
        id: product.id,
        published: product.published
      });
      await this.table.refresh();
      this.messageService.add({
        severity: 'success',
        detail: 'Produto atualizado com sucesso.'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async syncMillennium(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(this.erpService.executeSyncShopProduct());
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Sincronização realizada'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }
}
