/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreditCardPayment {
  creditCardPaymentId?: number;
  paymentId?: number;
  paymentGuid?: string;
  recurrence?: number;
  attempt?: number;
  dateCreated?: Date;
  dateAuthorized?: Date;
  amount?: number;
  installments?: number;
  personId?: number;
  subscriberId?: number;
  creditCardId?: number;
  gatewayId?: number;
  transactionStatusId?: number;
  authorizationCode?: string;
  authorizationMessage?: string;
  creditCardPaymentTypeId?: number;
  extraData?: string;
  ipAddress?: string;
  gatewaySubscriberId?: number;
  pan?: string;
  tid?: string;
  nsu?: string;
  lr?: string;
}
