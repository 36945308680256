<app-table
  [cols]="cols"
  pageFunctionName="findLivesTable"
  modelName="lives"
  [service]="liveService"
  addLabel="Nova live"
  sortField="active"
  [sortOrder]="1"
  (booleanAction)="updateLiveStatus($event)"
  *ngIf="cols"
/>
<p-toast />
<p-confirmDialog styleClass="danger" />
