/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PersonDetail {
  personId?: number;
  username?: string;
  name?: string;
  lastName?: string;
  phone?: string;
  phoneFormatted?: string;
  rewardBalance?: number;
  birthday?: Date;
  cpf?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  couponName?: string;
  badge?: string;
  badgeId?: number;
  personStatus?: number;
  postalCode?: string;
  street?: string;
  streetNumber?: string;
  streetExtensions?: string;
  neighborhood?: string;
  city?: string;
  cityCode?: number;
  state?: string;
  stateCode?: number;
  urlImageProfile?: string;
  newsletterOptIn?: boolean;
  glampointsMultiplier?: number;
  instagram?: string;
  facebookId?: number;
  facebookAccessToken?: string;
  cpfFormatted?: string;
  cpfString?: string;
  personTypeId?: number;
  personType?: string;
  addressId?: number;
  hexColorCode?: string;
  iconUrl?: string;
}
