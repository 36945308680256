import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  TitleCasePipe
} from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { CouponsReportModule } from 'src/app/components/coupons-report/coupons-report.module';
import { SubscriberGroupCounterChartModule } from 'src/app/components/subscriber-group-counter-chart/subscriber-group-counter-chart.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { ValueChangeModule } from 'src/app/directives/value-change/value-change.module';
import { EditionImageModule } from 'src/app/edition-image/edition-image.module';
import { AcquisitionDashboardComponent } from './acquisition-dashboard/acquisition-dashboard.component';
import { AnalyticsDashboardComponent } from './analytics-dashboard/analytics-dashboard.component';
import { ConsolidatedDashboardComponent } from './consolidated-dashboard/consolidated-dashboard.component';
import { CouponsDashboardComponent } from './coupons-dashboard/coupons-dashboard.component';
import { DashboardsRoutingModule } from './dashboards-routing.module';
import { DeliveryDashboardComponent } from './delivery-dashboard/delivery-dashboard.component';
import { IncomeDashboardComponent } from './income-dashboard/income-dashboard.component';
import { InvoicesDashboardComponent } from './invoices-dashboard/invoices-dashboard.component';
import { EditionRatingAndScoresDashboardComponent } from './rating-and-scores-dashboard/edition-rating-and-scores-dashboard/edition-rating-and-scores-dashboard.component';
import { RatingAndScoresDashboardComponent } from './rating-and-scores-dashboard/rating-and-scores-dashboard.component';
import { SubscriptionsDashboardComponent } from './subscriptions-dashboard/subscriptions-dashboard.component';
import { UpgradeDowngradeDashboardComponent } from './upgrade-downgrade-dashboard/upgrade-downgrade-dashboard.component';
import { WishlistDashboardComponent } from './wishlist-dashboard/wishlist-dashboard.component';

@NgModule({
  declarations: [
    AcquisitionDashboardComponent,
    IncomeDashboardComponent,
    SubscriptionsDashboardComponent,
    CouponsDashboardComponent,
    RatingAndScoresDashboardComponent,
    EditionRatingAndScoresDashboardComponent,
    UpgradeDowngradeDashboardComponent,
    DeliveryDashboardComponent,
    AnalyticsDashboardComponent,
    WishlistDashboardComponent,
    ConsolidatedDashboardComponent,
    InvoicesDashboardComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    ButtonModule,
    TooltipModule,
    TableModule,
    TabViewModule,
    SubscriberGroupCounterChartModule,
    DashboardsRoutingModule,
    EditionImageModule,
    ChartModule,
    CouponsReportModule,
    AppTableModule,
    ToastModule,
    ValueChangeModule,
    CalendarModule,
    FormsModule
  ],
  exports: [AnalyticsDashboardComponent, ConsolidatedDashboardComponent],
  providers: [
    CurrencyPipe,
    TitleCasePipe,
    CurrencyPipe,
    DialogService,
    DatePipe
  ]
})
export class DashboardsModule {}
