import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { BadgeFormModule } from 'src/app/components/badge-form/badge-form.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { ContentRoutingModule } from '../content/content-routing.module';
import { BadgeDetailsComponent } from './badge-details/badge-details.component';
import { BadgeComponent } from './badge/badge.component';
import { PayerInstitutionDetailComponent } from './payer-institutions/payer-institution-detail/payer-institution-detail.component';
import { PayerInstitutionsComponent } from './payer-institutions/payer-institutions.component';
import { SubscriptionSettingsRoutingModule } from './subscription-settings-routing.module';
import { SubscriptionTypeDetailsComponent } from './subscription-types/subscription-type-details/subscription-type-details.component';
import { SubscriptionTypePriceFormModalComponent } from './subscription-types/subscription-type-details/subscription-type-price-form-modal/subscription-type-price-form-modal.component';
import { SubscriptionTypesComponent } from './subscription-types/subscription-types.component';

@NgModule({
  declarations: [
    BadgeComponent,
    BadgeDetailsComponent,
    SubscriptionTypesComponent,
    SubscriptionTypeDetailsComponent,
    SubscriptionTypePriceFormModalComponent,
    PayerInstitutionsComponent,
    PayerInstitutionDetailComponent
  ],
  imports: [
    CommonModule,
    SubscriptionSettingsRoutingModule,
    ReactiveFormsModule,
    BadgeFormModule,
    AppTableModule,
    ButtonModule,
    InputTextModule,
    TabViewModule,
    ToastModule,
    ConfirmPopupModule,
    BadgeFormModule,
    DropdownModule,
    CalendarModule,
    ContentRoutingModule,
    OverlayPanelModule,
    InputTextareaModule,
    InputNumberModule,
    AutoFocusModule,
    ConfirmDialogModule,
    InputSwitchModule,
    FileUploadModule,
    NgxPermissionsModule.forChild()
  ],
  providers: [MessageService, ConfirmationService]
})
export class SubscriptionSettingsModule {}
