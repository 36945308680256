<form class="form-b4a" *ngIf="order" (submit)="submit()" [formGroup]="form">
  <span class="full-w">
    <small *ngIf="!allProducts">
      * Para estornar todos os itens, realize o cancelamento do pedido
    </small>
    <small *ngIf="!order?.payment?.card">
      ** O pagamento foi feito com
      <b>{{ order.payment.paymentMethod | uppercase }}</b
      >, será necessário reembolsar o valor em conta.
    </small>
    <small *ngIf="!reward"> ** Esse pedido não gerou glampoints </small>
  </span>
  <span class="full-w form-b4a" [formArray]="form.controls.products.controls">
    <span
      class="full-w form-b4a"
      [formGroup]="group"
      *ngFor="let group of form.controls.products.controls; let i = index"
    >
      <ng-container *ngIf="!allProducts">
        <span class="p-float-label half">
          <p-dropdown
            *ngIf="productsAvailable?.length"
            [options]="productsAvailable[i]"
            formControlName="product"
            optionLabel="name"
            [autoDisplayFirst]="false"
            appendTo="body"
            [inputId]="'product' + i"
            (onChange)="productChange(i)"
            [readonly]="allProducts"
          />
          <label [for]="'product' + i">Produto *</label>
        </span>
        <span class="p-float-label half">
          <p-inputNumber
            [name]="'quantity' + i"
            [inputId]="'quantity' + i"
            styleClass="p-inputwrapper p-inputwrapper-filled"
            formControlName="quantity"
            [max]="form.value.products[i].product?.quantity"
            [readonly]="
              form.value.products[i].product?.quantity === 1 || allproducts
            "
            (onInput)="qtyChange(i)"
            (onBlur)="qtyChange(i)"
          />
          <label [for]="'quantity' + i">Qtd *</label>
          <small *ngIf="form.value.products[i].product?.quantity > 1"
            >* Total de
            {{ form.value.products[i].product?.quantity }} unidades</small
          >
        </span>
        <span class="p-float-label half">
          <p-inputNumber
            [name]="'amount' + i"
            [inputId]="'amount' + i"
            styleClass="p-inputwrapper p-inputwrapper-filled"
            formControlName="amount"
            [minFractionDigits]="0"
            [maxFractionDigits]="2"
            mode="currency"
            currency="BRL"
            [readonly]="allProducts"
            locale="pt-BR"
          />
          <label [for]="'amount' + i">Valor a estornar *</label>
          <small *ngIf="form.value.products.at(i).quantity > 1"
            >*
            {{ form.value.products.at(i).product.pricePaid | currency : 'BRL' }}
            cada item</small
          >
        </span>
      </ng-container>
      <span class="refund-product" *ngIf="allProducts">
        <span>
          {{ form.value.products.at(i).quantity || 1 }}
          {{ form.value.products.at(i).product.name }}<br />
        </span>
        <span>
          <strong>Valor a estornar: </strong>
          {{ form.value.products.at(i).amount | currency : 'BRL' }}
        </span>
      </span>
      <span class="p-float-label half" *ngIf="reward">
        <p-inputNumber
          [name]="'glampoints' + i"
          [inputId]="'glampoints' + i"
          styleClass="p-inputwrapper p-inputwrapper-filled"
          formControlName="glampoints"
          mode="decimal"
          [minFractionDigits]="0"
          [maxFractionDigits]="2"
        />
        <label [for]="'glampoints' + i">Glampoints a estornar *</label>
        <small>* 0 (zero) para não estornar</small>
      </span>
      <p-button
        icon="pi pi-minus"
        size="small"
        severity="danger"
        type="button"
        (onClick)="removeProduct(i)"
        pTooltip="Remover produto"
        [rounded]="true"
        *ngIf="!allProducts && form.controls.products.controls.length > 1"
      />
      <span class="p-float-label full-w" *ngIf="!allProducts">
        <textarea
          [id]="'reason' + i"
          rows="5"
          cols="80"
          pInputTextarea
          formControlName="reason"
          [name]="'reason' + i"
          required
        ></textarea>
        <label [for]="'reason' + i">Motivo *</label>
        <small
          [class.danger]="
            form.controls.products.controls.at(i).controls.reason.touched &&
            form.controls.products.controls.at(i).controls.reason.dirty &&
            !form.controls.products.controls.at(i).controls.reason.valid
          "
        >
          {{ form.value.products.at(i)?.reason?.length || 0 }}/200 caracteres
        </small>
      </span>
    </span>
  </span>
  <span class="p-float-label full-w" *ngIf="allProducts">
    <textarea
      id="reason"
      rows="5"
      cols="80"
      pInputTextarea
      formControlName="reason"
      name="reason"
      required
      (input)="updateReasons()"
    ></textarea>
    <label for="reason">Motivo *</label>
    <small
      [class.danger]="
        form.controls.reason.touched &&
        form.controls.reason.dirty &&
        !form.controls.reason.valid
      "
    >
      {{ form.value.reason?.length || 0 }}/200 caracteres
    </small>
  </span>
  <span class="buttons">
    <p-button
      icon="pi pi-plus"
      size="small"
      type="button"
      (onClick)="addProduct()"
      severity="primary"
      [rounded]="true"
      pTooltip="Adicionar produto"
      *ngIf="
        !allProducts &&
        form.controls.products.controls.length < lineItems.length - 1
      "
    />
  </span>
  <span class="payment">
    <ng-container *ngIf="order.payment?.card">
      <span *ngIf="payment">
        <strong>Pagamento: </strong>
        <span
          class="status"
          [class.status--paid]="
            !refundedTransaction && paymentStatus === 'paid'
          "
          [class.status--refunded]="refundedTransaction"
        >
          {{ paymentStatus }}
        </span>
      </span>
      <span>
        <strong>TID: </strong> {{ order.payment.tid }}
        <p-button
          icon="pi pi-refresh"
          [text]="true"
          [rounded]="true"
          size="small"
          styleClass="p-button-xs"
          pTooltip="Atualizar Transação"
          (onClick)="findTID()"
          *ngIf="payment"
        />
        <i class="pi pi-spinner pi-spin" *ngIf="payment === undefined"></i>
      </span>
      <small *ngIf="!refundedTransaction">
        * Realize o estorno no gateway e atualize no botão acima
      </small>
    </ng-container>
    <span *ngIf="refundTotal">
      <strong>Valor a estornar: </strong> {{ refundTotal | currency : 'BRL' }}
    </span>
    <span *ngIf="glampointsTotal">
      <strong>Glampoints a estornar: </strong>
      {{ glampointsTotal | number : '1.0-2' }}
    </span>
  </span>

  <span class="buttons">
    <p-button type="submit" label="Enviar" [disabled]="!form.valid" />
  </span>
</form>
<p-confirmDialog />
