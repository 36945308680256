import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import {
  CreditCardPaymentSummary,
  FinancialAdminControllerService,
  FinancialControllerService,
  OpenFinancePayerInstitution,
  PageableFilter,
  PageableRequest,
  TransactionStatus
} from 'src/app/admin-api';
import { ImageUploadComponent } from 'src/app/components/image-upload/image-upload.component';
import {
  DropdownFilter,
  PageContent,
  TableColumn
} from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-payer-institution-detail',
  templateUrl: './payer-institution-detail.component.html',
  styleUrl: './payer-institution-detail.component.scss'
})
export class PayerInstitutionDetailComponent
  extends ImageUploadComponent<OpenFinancePayerInstitution>
  implements OnInit
{
  private activatedRoute = inject(ActivatedRoute);
  private financialAdminService = inject(FinancialAdminControllerService);
  private permissionService = inject(NgxPermissionsService);
  private title = inject(Title);
  private messageService = inject(MessageService);
  financialService = inject(FinancialControllerService);

  institution: OpenFinancePayerInstitution;
  permission = false;
  payerInstitutionId: number;
  fixedFilters: Array<PageableFilter>;

  override form: typeof this.institutionForm;
  paymentCols: Array<TableColumn> = [
    new TableColumn(
      'Id assinatura',
      'subscriberId',
      true,
      'number',
      '/users/subscribers/',
      'subscriberId'
    ),
    new TableColumn(
      'Assinante',
      'personName',
      true,
      'text',
      '/users/person/',
      'personId',
      true,
      'contains'
    ),
    new TableColumn('TID', 'tid', true, 'text', undefined, undefined, true),
    new TableColumn(
      'Gateway',
      'gatewayName',
      true,
      'text',
      undefined,
      undefined,
      true,
      'contains'
    ),
    new TableColumn('Valor', 'amount', false, 'currency'),
    new TableColumn('Parcelas', 'installments', false, 'text'),
    new TableColumn(
      'Data',
      'dateCreated',
      true,
      'date',
      undefined,
      undefined,
      true,
      'between'
    ),
    new TableColumn('Status', 'transactionStatusName', true, 'number'),
    new TableColumn('Detalhe', 'authorizationMessage', true, 'text'),
    new TableColumn('Tipo', 'creditCardPaymentType', true, 'text'),
    new TableColumn.Builder()
      .setHeader('Ação')
      .setField(' ')
      .setFilter(false)
      .setType('button')
      .build()
  ];
  dropdownFilters: {
    [field: string]: Array<DropdownFilter>;
  } = {
    transactionStatusName: []
  };
  transactionStatusList: Array<TransactionStatus> | undefined;
  selectedTab = 0;

  async ngOnInit() {
    const params = await firstValueFrom(this.activatedRoute.params);
    if (params['payerInstitutionId']) {
      try {
        LoaderService.showLoader();
        this.permission = await this.permissionService.hasPermission([
          roleAsObject(Role.Full_Administrator).enumValue,
          roleAsObject(Role.Financial).enumValue
        ]);
        this.payerInstitutionId = Number(params['payerInstitutionId']);
        await Promise.all([
          this.findPayerInstitution(),
          this.findTransactionStatusList()
        ]);
        this.title.setTitle(this.institution.institutionName);
      } catch (error) {
        AppDialogService.showErrorDialog(error, true);
      } finally {
        this.activatedRoute.queryParams.subscribe((params) => {
          if (params['tab'])
            try {
              this.selectedTab = Number(params['tab']);
            } catch (error) {
              this.selectedTab = 0;
            }
          LoaderService.showLoader(false);
        });
      }
    }
  }

  async findPayerInstitution() {
    try {
      this.payerInstitution = await lastValueFrom(
        this.financialService
          .findOpenFinanceInstitutionById(this.payerInstitutionId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findTransactionStatusList(): Promise<void> {
    try {
      this.transactionStatusList = await lastValueFrom(
        this.financialService
          .findTransactionStatusList()
          .pipe(map((data) => data.result))
      );
      this.dropdownFilters['transactionStatusName'] = [
        { label: 'Todos', value: '-1' }
      ].concat(
        this.transactionStatusList?.map(
          (t) =>
            ({
              label: t.transactionStatusName,
              value: t.transactionStatusId?.toString()
            } as { label: string; value: string })
        ) || []
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
    }
  }

  tabChanged($event: number): void {
    this.selectedTab = $event;
    this.router.navigate(
      [`subscription-settings/payer-institutions/${this.modelId}`],
      {
        queryParams: { tab: this.selectedTab },
        queryParamsHandling: 'merge'
      }
    );
  }

  async findPage(
    request: PageableRequest,
    service: unknown
  ): Promise<PageContent<CreditCardPaymentSummary>> {
    const filterTransaction = request.filters?.find(
      (f) => f.field === 'transactionStatusName'
    );
    if (filterTransaction?.value) {
      if (Number(filterTransaction.value) < 0) {
        filterTransaction.value = '';
      }
      request.filters?.push({
        condition: 'equals',
        field: 'transactionStatusId',
        fieldType: 'number',
        value: filterTransaction.value
      });
    }
    return lastValueFrom(
      (service as FinancialControllerService)
        .findPaymentTable(request)
        .pipe(
          map((data) => data.result as PageContent<CreditCardPaymentSummary>)
        )
    );
  }

  async saveInstitution() {
    if (this.form.valid)
      try {
        LoaderService.showLoader();
        this.payerInstitution = await lastValueFrom(
          this.financialAdminService
            .updateInstitution(
              this.form.value,
              this.institution.openFinancePayerInstitutionId
            )
            .pipe(map((data) => data.result))
        );
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          detail: 'Instituição atualizada'
        });
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
  }

  override get filePath(): string {
    return '/admin/payer_institutions/';
  }
  override get model(): OpenFinancePayerInstitution {
    return this.institution;
  }
  override get modelId(): string | number {
    return this.institution?.openFinancePayerInstitutionId;
  }

  get institutionForm() {
    return new FormGroup({
      active: new FormControl(
        { value: this.institution?.active, disabled: !this.permission },
        Validators.required
      ),
      iconUrl: new FormControl(
        { value: this.institution?.iconUrl, disabled: !this.permission },
        Validators.required
      ),
      logoUrl: new FormControl(
        { value: this.institution?.logoUrl, disabled: !this.permission },
        Validators.required
      )
    });
  }

  set payerInstitution(institution: OpenFinancePayerInstitution) {
    this.institution = institution;
    this.form = this.institutionForm;
    this.fixedFilters = [
      {
        condition: 'equals',
        field: 'payerInstitution',
        fieldType: 'text',
        value: this.institution.payerInstitutionId
      }
    ];
  }
}
