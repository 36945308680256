import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Editor } from 'primeng/editor';
import Quill from 'quill';
import { lastValueFrom, map } from 'rxjs';
import * as beautify from 'simply-beautiful';
import { Course, CourseControllerService } from 'src/app/admin-api';
import { BusinessUnit } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ImageUploadComponent } from '../image-upload/image-upload.component';

@Component({
  selector: 'app-course-form',
  templateUrl: './course-form.component.html',
  styleUrl: './course-form.component.scss'
})
export class CourseFormComponent
  extends ImageUploadComponent<Course>
  implements OnInit
{
  @Input()
  course: Course;

  @Input()
  permission = false;

  @ViewChild(Editor) editor: Editor;

  private courseService = inject(CourseControllerService);
  private messageService = inject(MessageService);
  cdRef = inject(ChangeDetectorRef);

  override form = new FormGroup({
    businessUnitId: new FormControl(BusinessUnit.Glam, [
      Validators.required,
      Validators.min(BusinessUnit.Glam)
    ]),
    name: new FormControl<string>(null, [
      Validators.required,
      Validators.maxLength(200)
    ]),
    subtitle: new FormControl<string>(null, [Validators.maxLength(200)]),
    description: new FormControl<string>(null),
    link: new FormControl<string>(null, Validators.maxLength(2000)),
    active: new FormControl(false),
    image: new FormControl<string>(null),
    author: new FormControl<string>(null),
    html: new FormControl(false)
  });
  businessUnits = [
    { label: 'Glam', value: BusinessUnit.Glam },
    { label: "Men's", value: BusinessUnit.Mens }
  ];
  toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [
      'bold',
      'italic',
      'underline',
      'strike',
      { script: 'sub' },
      { script: 'super' },
      'code-block'
    ], // toggled buttons
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['link', 'image'],

    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }]
  ];
  handlers = {
    image: this.handleImage
  };

  ngOnInit(): void {
    this.form.patchValue(this.course);
  }

  format() {
    this.form
      .get('description')
      .setValue(beautify.html(this.form.value.description, { indent_size: 2 }));
  }

  handleImage() {
    document.getElementById('inputFile').click();
  }

  async handleFile($event: Event) {
    try {
      LoaderService.showLoader();
      const link = await lastValueFrom(
        this.fileService
          .uploadFileForm(
            this.filePath.concat('assets/'),
            ($event.target as any).files[0],
            ($event.target as any).files[0].name
          )
          .pipe(map((data) => data.result))
      );
      this.form.controls.description.setValue(
        this.form.value.description + `<p><img src="${link}" /></p>`
      );
      this.editor.writeValue(this.form.value.description);
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Imagem adicionada ao final do texto.'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  addClassToImages() {
    (this.editor?.getQuill() as Quill)?.container
      .querySelectorAll('img')
      .forEach((i) => {
        if (!i.className?.includes('dimensioned'))
          i.classList.add('dimensioned');
      });
    this.form
      .get('description')
      .setValue(
        (this.editor?.getQuill() as Quill)?.getSemanticHTML() ||
          this.form.value.description
      );
  }

  async submit() {
    if (this.form.valid) {
      try {
        LoaderService.showLoader();
        if (this.course) {
          this.course = await lastValueFrom(
            this.courseService
              .updateCourse(this.form.value, this.modelId)
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Curso atualizado '
          });
        } else {
          const course = await lastValueFrom(
            this.courseService
              .createCourse(this.form.value)
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Curso criado'
          });
          this.router.navigate(['/content/course/' + course.courseId]);
        }
        LoaderService.showLoader(false);
        await this.afterSubmit();
      } catch (error) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(error);
      }
    }
  }

  get modelId() {
    return this.course?.courseId;
  }

  get filePath(): string {
    return '/courses/';
  }

  get model(): Course {
    return this.course;
  }
}
