<form class="form-b4a" [formGroup]="form" (submit)="submit()">
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="title"
      name="title"
      id="title"
    />
    <label for="title">Título *</label>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="subtitle"
      name="subtitle"
      id="subtitle"
    />
    <label for="subtitle">Subtítulo</label>
  </span>
  <span class="p-float-label">
    <p-inputNumber
      [min]="1"
      formControlName="minutesTotal"
      name="minutesTotal"
      inputId="minutesTotal"
    />
    <label for="minutesTotal">Duração vídeo *</label>
  </span>
  <span class="p-float-label">
    <input
      type="text"
      pInputText
      formControlName="code"
      name="code"
      id="code"
      (blur)="setUrl()"
    />
    <label for="code">Código incorporado</label>
  </span>
  <span class="full-w input-switch">
    <label for="active">Inativo</label>
    <p-inputSwitch formControlName="active" inputId="active" />
    <label for="active">Ativo</label>
  </span>
  <iframe
    *ngIf="form.value.code && url"
    width="640"
    height="360"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    [src]="url"
  ></iframe>
  <span class="image-upload full-w">
    <div class="file-upload">
      <span class="p-float-label">
        <input
          type="text"
          [disabled]="true"
          pInputText
          formControlName="image"
        />
        <label for="image">Imagem</label>
      </span>
      <p-fileUpload
        mode="basic"
        chooseLabel="Selecionar"
        name="file"
        [auto]="true"
        accept="image/*"
        [maxFileSize]="1000000"
        uploadIcon="pi pi-file"
        [customUpload]="true"
        (uploadHandler)="onUpload($event, 'image', fileUpload)"
        id="image"
        #fileUpload
        [disabled]="form.controls.image.disabled"
      />
    </div>
    <span class="image">
      <img *ngIf="form.value.image" [src]="form.value.image" />
      <a
        type="button"
        class="delete-icon"
        (click)="form.controls.image.reset()"
      >
        <i
          class="pi pi-times-circle"
          pTooltip="Remover imagem"
          *ngIf="form.value.image"
        ></i>
      </a>
    </span>
  </span>
  <span class="p-input-editor">
    <span class="label-option">
      <label for="description">Descrição </label>
      <span class="p-input-checbox">
        <p-checkbox
          formControlName="html"
          [binary]="true"
          inputId="html"
          name="html"
          (onChange)="format()"
        />
        <label for="html">Ver código fonte</label>
      </span>
    </span>
    <p-editor
      formControlName="description"
      id="description"
      (keyup)="cdRef.detectChanges()"
      (click)="cdRef.detectChanges()"
      [hidden]="form.value.html"
      [modules]="{
        toolbar: { handlers, container: toolbarOptions },
        blotFormatter2: {
          resize: {
            handleClassName: 'dimensioned'
          }
        }
      }"
    >
      <ng-template pTemplate="header" />
    </p-editor>
    <textarea
      name="description"
      id="description"
      formControlName="description"
      *ngIf="form.value.html"
      rows="50"
    >
    </textarea>
    <small class="hint" [class.danger]="!form.controls.description.valid">
      {{ form.value.description?.trim().length || 0 | number : '1.0-0' }}/50.000
    </small>
  </span>
  <span class="buttons">
    <p-button label="Salvar" [disabled]="!form.valid" type="submit" />
  </span>
</form>
<input type="file" hidden id="inputFile" (change)="handleFile($event)" />
