/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AttributeOption } from './attributeOption';

export interface Attribute {
  attributeId?: number;
  attributeGroupId?: number;
  attributeName?: string;
  dateCreated?: Date;
  dateUpdated?: Date;
  syncToCustomerIO?: boolean;
  attributeQuestion?: string;
  order?: number;
  attributeTypeId?: number;
  webImage?: string;
  mobileImage?: string;
  sensitiveContent?: boolean;
  options?: Array<AttributeOption>;
  active?: boolean;
  syncronizable?: boolean;
}
