/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { InfluencerCouponRequest } from '../model/influencerCouponRequest';
import { InfluencerCouponUpdateRequest } from '../model/influencerCouponUpdateRequest';
import { InfluencerUpdateRequest } from '../model/influencerUpdateRequest';
import { ResponseB4AInfluencer } from '../model/responseB4AInfluencer';
import { ResponseB4AInfluencerCoupon } from '../model/responseB4AInfluencerCoupon';
import { ResponseB4AInfluencerDetail } from '../model/responseB4AInfluencerDetail';
import { ResponseB4AListCouponDTO } from '../model/responseB4AListCouponDTO';
import { ResponseB4AListInfluencerCouponDetailView } from '../model/responseB4AListInfluencerCouponDetailView';
import { ResponseB4AListInfluencerStatus } from '../model/responseB4AListInfluencerStatus';
import { ResponseB4AListInfluencerTransactionType } from '../model/responseB4AListInfluencerTransactionType';
import { ResponseB4AString } from '../model/responseB4AString';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable()
export class InfluencerControllerService {
  protected basePath = 'https://admin-api.b4a.com.br';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addInfluencerCoupon(
    body: InfluencerCouponRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AInfluencerCoupon>;
  public addInfluencerCoupon(
    body: InfluencerCouponRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AInfluencerCoupon>>;
  public addInfluencerCoupon(
    body: InfluencerCouponRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AInfluencerCoupon>>;
  public addInfluencerCoupon(
    body: InfluencerCouponRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addInfluencerCoupon.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AInfluencerCoupon>(
      'post',
      `${this.basePath}/influencer/coupon`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param personId
   * @param couponId
   * @param subscriptionTypeId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteInfluencerCoupon(
    personId: number,
    couponId: number,
    subscriptionTypeId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public deleteInfluencerCoupon(
    personId: number,
    couponId: number,
    subscriptionTypeId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public deleteInfluencerCoupon(
    personId: number,
    couponId: number,
    subscriptionTypeId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public deleteInfluencerCoupon(
    personId: number,
    couponId: number,
    subscriptionTypeId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (personId === null || personId === undefined) {
      throw new Error(
        'Required parameter personId was null or undefined when calling deleteInfluencerCoupon.'
      );
    }

    if (couponId === null || couponId === undefined) {
      throw new Error(
        'Required parameter couponId was null or undefined when calling deleteInfluencerCoupon.'
      );
    }

    if (subscriptionTypeId === null || subscriptionTypeId === undefined) {
      throw new Error(
        'Required parameter subscriptionTypeId was null or undefined when calling deleteInfluencerCoupon.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'delete',
      `${this.basePath}/influencer/coupon/${encodeURIComponent(
        String(personId)
      )}/${encodeURIComponent(String(couponId))}/${encodeURIComponent(
        String(subscriptionTypeId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param personId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findInfluencerAvailableCoupons(
    personId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponDTO>;
  public findInfluencerAvailableCoupons(
    personId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCouponDTO>>;
  public findInfluencerAvailableCoupons(
    personId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCouponDTO>>;
  public findInfluencerAvailableCoupons(
    personId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (personId === null || personId === undefined) {
      throw new Error(
        'Required parameter personId was null or undefined when calling findInfluencerAvailableCoupons.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCouponDTO>(
      'get',
      `${this.basePath}/influencer/coupon/available/${encodeURIComponent(
        String(personId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param personId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findInfluencerCoupons(
    personId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListInfluencerCouponDetailView>;
  public findInfluencerCoupons(
    personId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListInfluencerCouponDetailView>>;
  public findInfluencerCoupons(
    personId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListInfluencerCouponDetailView>>;
  public findInfluencerCoupons(
    personId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (personId === null || personId === undefined) {
      throw new Error(
        'Required parameter personId was null or undefined when calling findInfluencerCoupons.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListInfluencerCouponDetailView>(
      'get',
      `${this.basePath}/influencer/coupon/${encodeURIComponent(
        String(personId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param personId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findInfluencerDetails(
    personId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AInfluencerDetail>;
  public findInfluencerDetails(
    personId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AInfluencerDetail>>;
  public findInfluencerDetails(
    personId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AInfluencerDetail>>;
  public findInfluencerDetails(
    personId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (personId === null || personId === undefined) {
      throw new Error(
        'Required parameter personId was null or undefined when calling findInfluencerDetails.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AInfluencerDetail>(
      'get',
      `${this.basePath}/influencer/info/${encodeURIComponent(
        String(personId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findInfluencerStatusList(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListInfluencerStatus>;
  public findInfluencerStatusList(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListInfluencerStatus>>;
  public findInfluencerStatusList(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListInfluencerStatus>>;
  public findInfluencerStatusList(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListInfluencerStatus>(
      'get',
      `${this.basePath}/influencer/status/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findInfluencerTransactionTypes(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListInfluencerTransactionType>;
  public findInfluencerTransactionTypes(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListInfluencerTransactionType>>;
  public findInfluencerTransactionTypes(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListInfluencerTransactionType>>;
  public findInfluencerTransactionTypes(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListInfluencerTransactionType>(
      'get',
      `${this.basePath}/influencer/transaction-type`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateInfluencer(
    body: InfluencerUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AInfluencer>;
  public updateInfluencer(
    body: InfluencerUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AInfluencer>>;
  public updateInfluencer(
    body: InfluencerUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AInfluencer>>;
  public updateInfluencer(
    body: InfluencerUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateInfluencer.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AInfluencer>(
      'put',
      `${this.basePath}/influencer`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateInfluencerCoupon(
    body: InfluencerCouponUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AInfluencerCoupon>;
  public updateInfluencerCoupon(
    body: InfluencerCouponUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AInfluencerCoupon>>;
  public updateInfluencerCoupon(
    body: InfluencerCouponUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AInfluencerCoupon>>;
  public updateInfluencerCoupon(
    body: InfluencerCouponUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateInfluencerCoupon.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AInfluencerCoupon>(
      'put',
      `${this.basePath}/influencer/coupon`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
