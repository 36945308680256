import { Inject, Injectable } from '@angular/core';
import { Buy2Win, Buy2WinRepository } from '@infrab4a/connect';
import { ConnectRequest } from 'src/app/models';
import { BaseConnectService } from './base-connect.service';

@Injectable({
  providedIn: 'root'
})
export class ShopBuyToWinService extends BaseConnectService<Buy2Win> {
  constructor(
    @Inject('Buy2WinRepository')
    private buyToWinRepository: Buy2WinRepository
  ) {
    super(buyToWinRepository);
  }

  async getCampaignById(id: string): Promise<Buy2Win> {
    return this.buyToWinRepository.get({ id });
  }

  async findAll(): Promise<Partial<Buy2Win>[]> {
    return (await this.buyToWinRepository.find()).data;
  }

  async getcampaignList(request: Partial<ConnectRequest>) {
    const result = await this.paginatedSearch(request);
    const content = result.data.map((category) => ({ ...category }));
    const totalPages = request.pageSize
      ? Math.floor(result.count / request.pageSize)
      : undefined;
    const totalElements = result.count;

    return {
      totalPages,
      totalElements,
      content,
      empty: result.count === 0
    };
  }

  async savecampaign(campaign: Partial<Buy2Win>) {
    return this.buyToWinRepository.create(Buy2Win.toInstance(campaign));
  }

  async updatecampaign(campaign: Partial<Buy2Win>) {
    return this.buyToWinRepository.update(Buy2Win.toInstance(campaign));
  }
}
