<form class="modal-form" [formGroup]="formGroup" (submit)="submit()">
  <span class="p-float-label">
    <p-dropdown
      [options]="cancellationReasons"
      optionLabel="subscriberCancellationReasonName"
      optionValue="subscriberCancellationReasonId"
      [autoDisplayFirst]="false"
      [filter]="true"
      filterBy="subscriberCancellationReasonName"
      formControlName="cancellationReasonId"
      inputId="subscriberCancellationReasonId"
      appendTo="body"
    />
    <label for="subscriberCancellationReasonId">Motivo do cancelamento</label>
  </span>
  <span class="p-float-label">
    <textarea
      id="cancellationReason"
      cols="30"
      rows="5"
      pInputTextarea
      formControlName="cancellationReason"
    ></textarea>
    <label for="cancellationReason">Observações</label>
    <small
      [class.danger]="
        formGroup.controls['cancellationReason'].touched &&
        formGroup.controls['cancellationReason'].dirty &&
        !formGroup.controls['cancellationReason'].valid
      "
      >{{ formGroup.value.cancellationReason?.length || 0 }}/4000
      caracteres</small
    >
  </span>
  <ng-container *ngIf="billings?.length">
    <p-pickList
      *ngIf="availableEditions"
      [source]="availableEditions"
      [target]="selectedEditions"
      [sourceHeader]="
        hasFee || hasRenewalFee ? 'Edições a manter' : 'Edições disponíveis'
      "
      targetHeader="Edições a estornar"
      [dragdrop]="!formGroup.value.automaticSelection"
      [responsive]="true"
      [sourceStyle]="{ height: '25vh' }"
      [targetStyle]="{ height: '25vh' }"
      breakpoint="768px"
      [showSourceControls]="false"
      [showTargetControls]="false"
      [disabled]="formGroup.value.automaticSelection"
    >
      <ng-template pTemplate="item" let-billing>
        {{ billingLabel(billing) }}
      </ng-template>
    </p-pickList>
    <span class="p-input-checkbox">
      <p-checkbox
        [binary]="true"
        inputId="automaticSelection"
        name="automaticSelection"
        formControlName="automaticSelection"
        (onChange)="autoSelectEditions()"
      />
      <label for="automaticSelection">Seleção automática das edições</label>
    </span>
    <div
      class="payment-block"
      *ngIf="lastPayment?.amount && selectedEditions?.length"
    >
      <span>
        <b>Total pagamento:</b> {{ lastPayment.amount | currency : 'BRL' }}
        <ng-container *ngIf="lastPayment.installments > 1"
          >em {{ lastPayment.installments }}x de
          {{
            lastPayment.amount / lastPayment.installments | currency : 'BRL'
          }}</ng-container
        >
      </span>
      <span *ngIf="shippingPrice">
        <b>Frete a estornar: </b
        ><ng-container *ngIf="selectedEditions?.length > 1"
          >{{ selectedEditions?.length }}x </ng-container
        >{{ shippingPrice | currency : 'BRL'
        }}<ng-container *ngIf="selectedEditions?.length > 1">
          =
          {{
            selectedEditions.length * shippingPrice | currency : 'BRL'
          }}</ng-container
        >
      </span>
      <span>
        <b>Edições a estornar ({{ selectedEditions?.length }}):</b>
        {{ selectedEditions?.length }}x
        {{ monthlyAmount - shippingPrice | currency : 'BRL' }} =
        {{
          (monthlyAmount * selectedEditions?.length || lastPayment?.amount) -
            selectedEditions.length * shippingPrice | currency : 'BRL'
        }}
      </span>
      <span>
        <b> Multa 40% </b>
        <i
          class="pi pi-question-circle"
          pTooltip="{{
            remainingAmount - selectedEditions.length * shippingPrice
              | currency : 'BRL'
          }} x 40%"
          tooltipPosition="bottom"
          *ngIf="fee > 0"
        ></i>
        <a
          class="copy data-link"
          type="button"
          (click)="copyToClipboard(fee || '')"
          pTooltip="Copiar para área de transferência"
        >
          {{ fee | currency : 'BRL' }}
          <i class="pi pi-copy"></i>
        </a>
        <ng-container *ngIf="!fee"> * </ng-container>
      </span>
      <span>
        <b>Valor a {{ pix ? 'reembolsar' : 'estornar' }}:</b>
        <a
          class="copy data-link"
          type="button"
          (click)="copyToClipboard(refundAmount || lastPayment.amount || '')"
          pTooltip="Copiar para área de transferência"
        >
          {{ refundAmount || lastPayment.amount | currency : 'BRL' }}
          <i class="pi pi-copy"></i>
        </a>
      </span>
      <span class="status-block">
        <ng-container *ngIf="lastPayment?.gatewayId !== 20">
          <b
            >Status ({{
              lastPayment?.gatewayName || 'Não informado' | titlecase
            }})</b
          >:
          <span
            class="status status--refunded"
            *ngIf="payment && refundedTransaction"
          >
            {{ paymentStatus }}
            {{ refundedAmount || 0 | currency : 'BRL' }}
          </span>
          <ng-container *ngIf="payment && !refundedTransaction">
            <span class="status status--paid"> pago </span>
            <p-button
              icon="pi pi-sync"
              pTooltip="Atualizar status"
              (onClick)="findTID()"
              size="small"
              [text]="true"
              [rounded]="true"
              styleClass="p-button-xs"
            />
          </ng-container>
          <ng-container *ngIf="payment === undefined">
            <i class="pi pi-spin pi-spinner"></i>
          </ng-container>
        </ng-container>
        <span>
          <b>TID: </b>
          <ng-container *ngIf="!gatewayUrl">
            {{ lastPayment.transactionId }}
          </ng-container>
          <a
            [href]="gatewayUrl"
            target="_blank"
            *ngIf="gatewayUrl"
            pTooltip="Abrir no gateway"
            class="data-link"
          >
            {{ lastPayment.transactionId }}
          </a>
        </span>
      </span>
      <span *ngIf="payment === null">
        * Não foi possível buscar as informações do pagamento na integração
        {{ lastPayment?.gatewayName }}
      </span>
      <span *ngIf="noFeeLimit && !restored && !restoredNoFee">
        * A assinante tem até {{ noFeeLimit | date : 'dd/MM/yyyy' }} às
        {{ noFeeLimit | date : 'HH:mm' }} para cancelar sem multa
      </span>
      <span *ngIf="!hasFee && hasRenewalFee">
        *O cancelamento está dentro do período de 7 dias, mas não é a primeira
        renovação da assinante
      </span>
      <span *ngIf="restored && restoredNoFee && !hasRenewalFee">
        *A edição {{ firstEdition?.theme }} foi devolvida dentro de 7 dias após
        a entrega
      </span>
      <span *ngIf="lastPayment && lastPayment.payerInstitution">
        * O pagamento foi feito com Pix. Será necesário realizar uma solicitação
        ao financeiro para débito em conta da assinante para devolução.
      </span>
    </div>
    <div class="payment-block" *ngIf="lastPayment?.amount === 0">
      * Assinante utiliza benefício 100%, sem estorno a ser realizado
    </div>
  </ng-container>

  <div
    class="payment-block"
    *ngIf="
      lastPayment?.amount && (hasFee || hasRenewalFee) && pendingAnnualMonthly
    "
  >
    <ng-container *ngIf="!selectedEditions?.length">
      <span>
        <b>Último pagamento: </b> {{ lastPayment.amount | currency : 'BRL' }}
      </span>
      <span *ngIf="shippingPrice">
        <b>Frete: </b>{{ shippingPrice | currency : 'BRL' }}
      </span>
    </ng-container>
    <span>
      <b>Edições pendentes (Anual/mês): </b>{{ pendingAnnualMonthly }}x
      {{ lastPayment?.amount - shippingPrice | currency : 'BRL' }} =
      {{
        pendingAnnualMonthly * (lastPayment?.amount - shippingPrice) || 0
          | currency : 'BRL'
      }}
    </span>
    <span>
      <b>Multa 40%: </b
      >{{
        pendingAnnualMonthly * (lastPayment?.amount - shippingPrice) * 0.4
          | currency : 'BRL'
      }}
      <i
        class="pi pi-question-circle"
        pTooltip="{{
          pendingAnnualMonthly * (lastPayment?.amount - shippingPrice) || 0
        }} x 40%"
        tooltipPosition="bottom"
      ></i>
    </span>
    <span>
      <b>Total a cobrar: </b>
      {{ amountToCharge | currency : 'BRL' }}
      <ng-container
        *ngIf="selectedEditions?.length && fee > 0 && !refundedTransaction"
      >
        - {{ refundAmount | currency : 'BRL' }} =
        {{ amountToCharge - refundAmount | currency : 'BRL' }}
      </ng-container>
      <ng-container
        *ngIf="selectedEditions?.length && fee > 0 && refundedTransaction"
      >
        + {{ refundAmount | currency : 'BRL' }} =
        {{
          pendingAnnualMonthly * montlySubscriptionType?.price * 0.4 +
            refundAmount | currency : 'BRL'
        }}
      </ng-container>
    </span>
  </div>
  <span class="buttons">
    <p-button
      label="Voltar"
      size="small"
      (onClick)="ref.close()"
      type="button"
    />
    <p-button
      label="Cancelar assinatura"
      severity="danger"
      size="small"
      type="submit"
      [disabled]="!formGroup.valid"
    />
  </span>
</form>
<p-confirmDialog />
