import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { LineItem, Order, Shops } from '@infrab4a/connect';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  PaymentControllerService,
  PersonDetail,
  Reward,
  ShopOrderControllerService,
  ShopOrderRefundDetail
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shop-order-refund-modal',
  templateUrl: './shop-order-refund-modal.component.html',
  styleUrl: './shop-order-refund-modal.component.scss',
  providers: [ConfirmationService, MessageService]
})
export class ShopOrderRefundModalComponent implements OnInit {
  order: Order = inject(DynamicDialogConfig).data.order;
  refunds: ShopOrderRefundDetail[] = inject(DynamicDialogConfig).data.refunds;
  person: PersonDetail = inject(DynamicDialogConfig).data.person;
  reward: Reward = inject(DynamicDialogConfig).data.reward;
  ref = inject(DynamicDialogRef);
  shopOrderService: ShopOrderControllerService = inject(
    ShopOrderControllerService
  );
  paymentService = inject(PaymentControllerService);
  confirmationService = inject(ConfirmationService);
  allProducts: boolean = inject(DynamicDialogConfig).data.allProducts || false;

  productsAvailable: LineItem[][];
  payment: any;

  form = new FormGroup({
    orderId: new FormControl(this.order?.id),
    personId: new FormControl(this.person?.personId),
    orderNumber: new FormControl(this.order?.orderNumber),
    rewardId: new FormControl(this.reward?.rewardId),
    products: new FormArray([this.newFormGroup]),
    reason: new FormControl<string>(
      { value: null, disabled: !this.allProducts },
      [Validators.required, Validators.minLength(6), Validators.maxLength(200)]
    )
  });

  ngOnInit(): void {
    if (!this.allProducts) this.checkProductsAvailable();
    if (this.allProducts) {
      this.productsAvailable = [];
      this.form.controls.products.removeAt(0);
      this.lineItems.forEach((li, i) => {
        this.form.controls.products.push(this.productFormGroup(li));
        this.productsAvailable[i] = [li];
      });
    }
    this.findTID();
  }

  checkProductsAvailable() {
    this.productsAvailable = [];
    this.form.value.products.forEach((r, i) => {
      this.productsAvailable[i] = this.lineItems?.filter(
        (li) =>
          li.EAN === r.product?.EAN ||
          (this.refunds?.every((ref) => ref.ean !== li.EAN) &&
            this.form.value.products.every(
              (ref) => ref.product?.EAN !== li.EAN
            ))
      );
    });
  }

  addProduct() {
    this.form.controls.products.push(this.newFormGroup);
    this.form.controls.products.controls.at(1).controls.reason;
    this.checkProductsAvailable();
  }

  removeProduct(index: number) {
    this.form.controls.products.removeAt(index);
    this.form.updateValueAndValidity();
    this.checkProductsAvailable();
  }

  productChange(index: number) {
    this.form.controls.products.controls
      .at(index)
      ?.controls.amount.setValue(
        this.form.value.products.at(index).product.pricePaid *
          (this.form.value.products.at(index).product.quantity || 1)
      );
    this.form.controls.products.controls
      .at(index)
      ?.controls.ean.setValue(this.form.value.products.at(index).product.EAN);
    this.form.controls.products.controls
      .at(index)
      ?.controls.glampoints.setValue(
        this.glampointsMultiplier *
          (this.form.value.products.at(index).product.quantity || 1)
      );
    this.form.controls.products.controls
      .at(index)
      ?.controls.quantity.setValue(
        this.form.value.products.at(index).product.quantity
      );
    this.checkProductsAvailable();
  }

  qtyChange(index: number) {
    if (
      this.form.value.products.at(index).quantity &&
      this.form.value.products.at(index).quantity <=
        this.form.value.products.at(index).product.quantity
    ) {
      this.form.controls.products.controls
        .at(index)
        ?.controls.amount.setValue(
          this.form.value.products.at(index).product.pricePaid *
            this.form.value.products.at(index).quantity
        );
      this.form.controls.products.controls
        .at(index)
        ?.controls.glampoints.setValue(
          (this.form.value.products.at(index).glampoints
            ? this.glampointsMultiplier
            : 0) * this.form.value.products.at(index).quantity
        );
    }
  }

  async submit(ignoreGatewayStatus = false) {
    if (!this.refundedTransaction && !ignoreGatewayStatus) {
      this.confirmationService.confirm({
        acceptLabel: 'Sim, farei o estorno depois',
        rejectLabel: 'Voltar',
        acceptButtonStyleClass: 'p-button-danger',
        rejectButtonStyleClass: 'p-button-info',
        header: 'Avançar sem estorno',
        message:
          'Deseja estornar os produtos sem realizar o estorno no gateway?',
        accept: () => this.submit(true)
      });
      return;
    }
    try {
      LoaderService.showLoader();
      const refunds = await lastValueFrom(
        this.shopOrderService
          .refundShopOrder(this.form.value)
          .pipe(map((data) => data.result))
      );

      this.ref.close(refunds);
    } catch (error) {
      LoaderService.showLoader(false);
      AppDialogService.showErrorDialog(error);
    }
  }

  async findTID() {
    if (this.order?.payment?.card && this.order?.payment?.tid)
      try {
        delete this.payment;
        this.payment = await lastValueFrom(
          this.paymentService
            .getTransactionByGatewayAndTID(
              this.order.payment.tid.toString(),
              environment.shopGatewayId
            )
            .pipe(map((data) => data.result))
        );
      } catch (error) {
        this.payment = null;
        console.error(error);
      }
  }

  productFormGroup(lineItem: LineItem) {
    const group = this.newFormGroup;
    group.controls.amount.setValue(
      lineItem.pricePaid * (lineItem.quantity || 1)
    );
    group.controls.ean.setValue(lineItem.EAN);
    group.controls.glampoints.setValue(this.glampointsMultiplier);
    group.controls.product.setValue(lineItem);
    group.controls.quantity.setValue(lineItem.quantity || 1);
    return group;
  }

  updateReasons() {
    this.form.controls.products.controls.forEach((c) =>
      c.controls.reason.setValue(this.form.value.reason)
    );
  }

  get newFormGroup() {
    return new FormGroup({
      product: new FormControl<LineItem>(null, Validators.required),
      amount: new FormControl<number>(null, [
        Validators.required,
        Validators.min(0.01)
      ]),
      glampoints: new FormControl<number>(null, Validators.required),
      ean: new FormControl<string>(null, Validators.required),
      quantity: new FormControl<number>(1, [
        Validators.required,
        Validators.min(1)
      ]),
      reason: new FormControl<string>(null, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(200)
      ])
    });
  }

  get refundedProducts() {
    return this.order?.lineItems
      .filter((li) => this.refunds?.some((r) => r.ean === li.EAN))
      .map((li) => ({
        ...li,
        refundAmount: this.refunds?.find((r) => r.ean === li.EAN)?.amount || 0
      })) as Array<LineItem & { refundAmount: number }>;
  }

  get orderRouterLink(): string {
    return this.order?.shop === Shops.GLAMPOINTS
      ? '/products/catalog/product-variant/'
      : '/shop-products/catalog/product/';
  }

  get glampointsMultiplier() {
    return (this.reward?.points || 0) / (this.lineItems?.length || 1);
  }

  get refundedTransaction() {
    return (
      this.payment?.status &&
      (this.payment.status === 'refunded' ||
        this.chargedBack ||
        this.payment.refunded_amount > 0 ||
        this.payment.transactionAmountRefunded > 0)
    );
  }

  get refundedAmount() {
    return (
      (this.refundedTransaction &&
        (this.payment.refunded_amount / 100 ||
          this.payment.transactionAmountRefunded)) ||
      (this.chargedBack &&
        (this.payment.amount / 100 || this.payment.transactionAmount))
    );
  }

  get chargedBack() {
    return (
      this.payment?.status === 'charged_back' ||
      this.payment?.status === 'chargedback'
    );
  }

  get paymentStatus() {
    return this.refundedTransaction && this.payment.status === 'paid'
      ? 'refunded'
      : this.payment.status;
  }

  get refundTotal() {
    return this.allProducts
      ? this.order.totalPrice
      : this.form.value.products.reduce((sum, r) => (sum += r.amount), 0);
  }

  get glampointsTotal() {
    return this.form.value.products.reduce(
      (sum, r) => (sum += r.glampoints),
      0
    );
  }

  get lineItems() {
    return this.order.lineItems?.filter((p) => p.pricePaid);
  }
}
