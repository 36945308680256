import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Buy2Win, Category, Product, Shops } from '@infrab4a/connect';
import { MessageService, PrimeIcons } from 'primeng/api';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
import {
  TableActionButton,
  TableActionEvent,
  TableColumn
} from 'src/app/components/table';
import { ShopBuyToWinService } from 'src/app/connect-api/api/shop/shop-buy-to-win.service';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { ShopAvailability } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { slugify } from 'src/app/utils/slug.util';

@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrl: './campaign-details.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class CampaignDetailsComponent implements OnInit {
  private shoppingCampaignService = inject(ShopBuyToWinService);
  private shoppingProductService = inject(ShopProductService);
  private shoppingCategoryService = inject(ShopCategoryService);
  private messageService = inject(MessageService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);

  campaignId: string;
  campaign: Partial<Buy2Win>;
  form = new FormGroup({
    name: new FormControl(null, Validators.required),
    cartValue: new FormControl(null, [Validators.required]),
    cartItensQuantityMin: new FormControl({ value: null, disabled: true }, [
      Validators.required,
      Validators.min(0)
    ]),
    cartValueMin: new FormControl({ value: null, disabled: true }, [
      Validators.required,
      Validators.min(0)
    ]),
    active: new FormControl(false),
    type: new FormControl<'value' | 'items'>('value', Validators.required),
    products: new FormControl<string[]>([]),
    categories: new FormControl<Category[]>({ value: [], disabled: true }, [
      Validators.required,
      Validators.minLength(1)
    ]),
    startDate: new FormControl(new Date(), Validators.required),
    endDate: new FormControl<Date>(null),
    shop: new FormControl<Shops>(null, Validators.required),
    activeCategory: new FormControl(false)
  });
  shops = [
    { label: ShopAvailability.ALL, value: Shops.ALL },
    { label: ShopAvailability.Glamshop, value: Shops.GLAMSHOP },
    { label: ShopAvailability.mensmarket, value: Shops.MENSMARKET }
  ];
  product = new FormControl<Partial<Product>[]>(null, [
    Validators.required,
    Validators.minLength(1)
  ]);
  products: Partial<Product>[] = [];
  categories: Category[] = [];
  productCols = [
    new TableColumn.Builder()
      .setHeader('Produto')
      .setField('name')
      .setRouterLink('/shop-products/catalog/product/')
      .setRouterLinkFieldName('id')
      .setFilter(false)
      .build(),
    new TableColumn.Builder()
      .setHeader('EAN')
      .setField('EAN')
      .setFilter(false)
      .setRouterLink('/shop-products/catalog/product/')
      .setRouterLinkFieldName('id')
      .build(),
    new TableColumn.Builder()
      .setHeader('SKU')
      .setField('sku')
      .setRouterLink('/shop-products/catalog/product/')
      .setRouterLinkFieldName('id')
      .setFilter(false)
      .build(),
    new TableColumn.Builder()
      .setHeader('Marca')
      .setField('brand')
      .setFilter(false)
      .build(),
    new TableColumn.Builder()
      .setHeader('Tipo')
      .setField('type')
      .setFilter(false)
      .build(),
    new TableColumn.Builder()
      .setHeader('Ativo')
      .setField('published')
      .setFilter(false)
      .setType('boolean')
      .build(),
    new TableColumn.Builder()
      .setHeader('Quantidade')
      .setField('stock.quantity')
      .setType('formattedInteger')
      .setFilter(false)
      .build(),
    new TableColumn.Builder().setHeader('Ação').setType('button')
  ];
  categoryCols = [
    new TableColumn.Builder()
      .setHeader('Nome')
      .setField('name')
      .setRouterLink('/shop-products/categories/category/')
      .setRouterLinkFieldName('id')
      .setFilter(false)
      .build(),
    new TableColumn.Builder()
      .setHeader('Loja')
      .setField('shop')
      .setFilter(false)
      .build(),
    new TableColumn.Builder()
      .setHeader('É uma marca?')
      .setField('brandCategory')
      .setType('boolean')
      .setFilter(false)
      .build(),
    new TableColumn.Builder()
      .setHeader('É uma coleção?')
      .setField('isCollection')
      .setType('boolean')
      .setFilter(false)
      .build(),
    new TableColumn.Builder()
      .setHeader('É uma wishlist?')
      .setField('isWishlist')
      .setType('boolean')
      .setFilter(false)
      .build(),
    new TableColumn.Builder()
      .setHeader('Ativo')
      .setField('published')
      .setType('boolean')
      .setFilter(false)
      .build(),
    new TableColumn.Builder().setHeader('Ação').setType('button')
  ];
  productsAvailable: Partial<Product>[];
  categoriesAvailable: Category[];
  actionButtons = [
    new TableActionButton.Builder()
      .setIcon(PrimeIcons.TRASH)
      .setAction('delete')
      .setTooltip('Remover')
      .setTooltipPosition('bottom')
      .setSeverity('danger')
      .setText(true)
      .setRounded(true)
      .build()
  ];

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      LoaderService.showLoader();
      if (params['campaignId'] && params['campaignId'] !== 'new') {
        this.campaignId = params['campaignId'];
        await this.findCampaign();
        this.form.patchValue({
          ...this.campaign,
          type: this.getCapamingType()
        });
        this.buy2winTypeAndCategoryValidation(
          {
            value: this.getCapamingType()
          },
          this.campaign
        );
      }
      LoaderService.showLoader(false);
    });
  }

  private getCapamingType(): 'items' | 'value' {
    if (this.campaign.type) return this.campaign.type;

    const campaignByValue =
      this.campaign.cartValue || this.campaign.cartValueMin;
    if (campaignByValue) return 'value';
    if (this.campaign.cartItensQuantityMin && !campaignByValue) return 'items';

    return 'value';
  }

  async findCampaign(): Promise<void> {
    try {
      this.campaign = await this.shoppingCampaignService.getCampaignById(
        this.campaignId
      );
      this.categories = this.campaign.categories || [];
      await this.findProducts();
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async submit(): Promise<void> {
    if (this.form.valid && this.form.value.products?.length) {
      try {
        LoaderService.showLoader();
        if (this.campaign) {
          this.campaign = await this.shoppingCampaignService.updatecampaign({
            ...this.form.getRawValue(),
            id: this.campaignId
          });
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Campanha atualizada com sucesso'
          });
        } else {
          const campaign = await this.shoppingCampaignService.savecampaign(
            this.form.getRawValue()
          );
          this.router.navigate(['/marketing/buy-2-win/' + campaign.id]);
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Campanha criada com sucesso'
          });
        }
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    } else if (!this.form.value.products?.length) {
      AppDialogService.showErrorDialog({
        message: 'Insira pelo menos 1 produto de brinde'
      });
    }
  }

  async findProducts(): Promise<void> {
    if (this.campaign?.products?.length) {
      try {
        this.products = await Promise.all(
          this.campaign.products.map((p) =>
            this.shoppingProductService.getProductBySKU(p)
          )
        );
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      }
    }
  }

  async searchProducts(event: AutoCompleteCompleteEvent): Promise<void> {
    try {
      const result = await Promise.all([
        this.shoppingProductService.getProductsList({
          filters: [
            {
              condition: 'contains',
              field: 'sku',
              value: event.query,
              fieldType: 'text'
            }
          ],
          page: 0,
          pageSize: 10
        }),
        this.shoppingProductService.getProductsList({
          filters: [
            {
              condition: 'contains',
              field: 'name',
              value: event.query,
              fieldType: 'text'
            }
          ],
          page: 0,
          pageSize: 10
        }),
        this.shoppingProductService.getProductsList({
          filters: [
            {
              condition: 'contains',
              field: 'EAN',
              value: event.query,
              fieldType: 'text'
            }
          ],
          page: 0,
          pageSize: 10
        })
      ]);
      this.productsAvailable = [
        ...new Set(
          result
            .reduce((products, r) => products.concat(r.content), [])
            .filter(
              (p) =>
                this.products?.every((pr) => pr.id !== p.id) &&
                (!this.productValid ||
                  this.product.value?.every((pr) => pr.id !== p.id))
            )
        )
      ];
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async addProduct(): Promise<void> {
    if (this.product.valid) {
      LoaderService.showLoader();
      try {
        if (this.productValid) {
          this.products = [
            ...new Set([...this.products].concat(this.product.value))
          ];
          this.form.controls.products.setValue(this.products.map((p) => p.sku));
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Produto(s) incluído(s) com sucesso.'
          });
          this.product.reset();
        }
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    }
  }

  async searchCategories(event: AutoCompleteCompleteEvent): Promise<void> {
    try {
      const result = await Promise.all([
        this.shoppingCategoryService.getCategoriesList({
          fields: ['id', 'slug', 'name', 'reference', 'shop', 'shops'],
          filters: [
            {
              condition: 'contains',
              field: 'slug',
              value: slugify(event.query.trim().toLowerCase()),
              fieldType: 'text'
            }
          ],
          page: 0,
          pageSize: 10
        }),
        this.shoppingCategoryService.getCategoriesList({
          fields: ['id', 'slug', 'name', 'reference', 'shop', 'shops'],
          filters: [
            {
              condition: 'contains',
              field: 'name',
              value: event.query,
              fieldType: 'text'
            }
          ],
          page: 0,
          pageSize: 10
        })
      ]);
      this.categoriesAvailable = [
        ...new Set(
          result
            .reduce((cats, r) => cats.concat(r.content), [])
            .filter(
              (c) =>
                this.categories?.every((cat) => cat.id !== c.id) &&
                (!this.form.controls.categories.valid ||
                  this.form.value.categories?.every((cat) => cat.id !== c.id))
            )
        )
      ];
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  remove($event: TableActionEvent): void {
    LoaderService.showLoader();
    if ($event.item instanceof Product) {
      this.products = this.products.filter((p) => p.id !== $event.item.id);
    } else {
      this.categories = this.categories.filter((p) => p.id !== $event.item.id);
    }
    this.form.controls.products.setValue(this.products.map((p) => p.sku));
    this.form.controls.categories.setValue(this.categories);
    LoaderService.showLoader(false);
  }

  get productValid() {
    return (
      this.product.valid &&
      this.product.value.length &&
      Array.isArray(this.product.value)
    );
  }

  buy2winTypeAndCategoryValidation(
    event?: SelectButtonChangeEvent | { value: string },
    campaign?: any
  ) {
    const e = event || { value: this.form.value.type };
    if (this.form.value.activeCategory) {
      switch (e.value) {
        case 'items':
          this.form.controls['cartValue'].disable();
          this.form.controls['cartValue'].reset(null);
          this.form.controls['cartValueMin'].disable();
          this.form.controls['cartValueMin'].reset(null);
          this.form.controls['cartItensQuantityMin'].enable();
          this.form.controls['cartItensQuantityMin'].reset(
            campaign?.cartItensQuantityMin ?? null
          );
          break;
        case 'value':
          this.form.controls['cartItensQuantityMin'].disable();
          this.form.controls['cartItensQuantityMin'].reset(null);
          this.form.controls['cartValue'].disable();
          this.form.controls['cartValue'].reset(null);
          this.form.controls['cartValueMin'].enable();
          this.form.controls['cartValueMin'].reset(
            campaign?.cartValueMin ?? null
          );
          break;
      }
      this.form.controls['categories'].enable();
    } else {
      switch (e.value) {
        case 'items':
          this.form.controls['cartValue'].disable();
          this.form.controls['cartValue'].reset(null);
          this.form.controls['cartValueMin'].disable();
          this.form.controls['cartValueMin'].reset(null);
          this.form.controls['cartItensQuantityMin'].enable();
          this.form.controls['cartItensQuantityMin'].reset(
            campaign?.cartItensQuantityMin ?? null
          );
          break;
        case 'value':
          this.form.controls['cartItensQuantityMin'].disable();
          this.form.controls['cartItensQuantityMin'].reset(null);
          this.form.controls['cartValue'].enable();
          this.form.controls['cartValue'].reset(campaign?.cartValue ?? null);
          this.form.controls['cartValueMin'].disable();
          this.form.controls['cartValueMin'].reset(null);
          break;
      }
      this.form.controls['categories'].disable();
      this.form.controls['categories'].reset([]);
    }
  }
}
