/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SubscriberBillingHistoryEdition } from './subscriberBillingHistoryEdition';

export interface SubscriberPaymentHistory {
  subscriberPaymentHistoryId?: number;
  paymentId?: number;
  typeId?: number;
  subscriberId?: number;
  dateCreated?: Date;
  amount?: number;
  transactionStatusId?: number;
  installments?: number;
  paymentGuid?: string;
  authorizationMessage?: string;
  authorizationCode?: string;
  transactionId?: string;
  gatewayName?: string;
  gatewayId?: number;
  creditCardPaymentTypeId?: number;
  transactionStatusName?: string;
  paymentTypeName?: string;
  subscriptionTypeId?: number;
  paymentType?: string;
  billings?: Array<SubscriberBillingHistoryEdition>;
  nextStep?: string;
  payerInstitution?: string;
}
