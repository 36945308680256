<div class="batch-update__header">Produtos da Loja - Batch Update</div>
<p-tabView [(activeIndex)]="activeIndex">
  <p-tabPanel header="Categorias">
    <ng-container *ngTemplateOutlet="categories" />
    <div
      *ngIf="
        batchService?.itemsForUpload?.length &&
        type === 'category' &&
        !results['category']?.length
      "
      class="batch-update__summary"
    >
      <div>
        <p-progressBar
          [value]="batchService?.importProgress"
          [showValue]="false"
          [style]="{ height: '20px' }"
        >
        </p-progressBar>
      </div>
    </div>
    <app-table
      *ngIf="results['category']?.length"
      [lazy]="false"
      [cols]="categoryCols"
      [addButton]="false"
      tableTitle="Categorias atualizadas"
      [elements]="results['category']"
      modelName="categorias"
      [dropdownFilters]="dropdownFilters"
    />
  </p-tabPanel>
  <p-tabPanel header="Categorias/Mais relevantes">
    <ng-container *ngTemplateOutlet="categoryMostRelevant" />
    <div
      *ngIf="
        batchService?.itemsForUpload?.length &&
        type === 'mostRelevant' &&
        !results['mostRelevant']?.length
      "
      class="batch-update__summary"
    >
      <div>
        <p-progressBar
          [value]="batchService?.importProgress"
          [showValue]="false"
          [style]="{ height: '20px' }"
        >
        </p-progressBar>
      </div>
    </div>
    <app-table
      *ngIf="results['mostRelevant']?.length"
      [lazy]="false"
      [cols]="categoryCols"
      [addButton]="false"
      tableTitle="Categorias atualizadas"
      [elements]="results['mostRelevant']"
      modelName="categorias"
      [dropdownFilters]="dropdownFilters"
    />
  </p-tabPanel>
  <p-tabPanel header="Produtos">
    <ng-container *ngTemplateOutlet="products" />
    <div
      *ngIf="
        batchService?.itemsForUpload?.length &&
        type === 'product' &&
        !results['product']?.length
      "
      class="batch-update__summary"
    >
      <div>
        <p-progressBar
          [value]="batchService?.importProgress"
          [showValue]="false"
          [style]="{ height: '20px' }"
        >
        </p-progressBar>
      </div>
    </div>
    <app-table
      *ngIf="results['product']?.length"
      [lazy]="false"
      [cols]="productCols"
      [addButton]="false"
      tableTitle="Produtos atualizadas"
      [elements]="results['product']"
      modelName="produtos"
    />
  </p-tabPanel>
  <p-tabPanel header="Avaliações">
    <ng-container *ngTemplateOutlet="reviews" />
    <div
      *ngIf="
        batchService?.itemsForUpload?.length &&
        type === 'review' &&
        !results['review']?.length
      "
      class="batch-update__summary"
    >
      <div>
        <p-progressBar
          [value]="batchService?.importProgress"
          [showValue]="false"
          [style]="{ height: '20px' }"
        >
        </p-progressBar>
      </div>
    </div>
    <app-table
      *ngIf="results['review']?.length"
      [lazy]="false"
      [cols]="reviewCols"
      [addButton]="false"
      tableTitle="Avaliações atualizadas"
      [elements]="results['review']"
      modelName="Avaliações"
    />
  </p-tabPanel>
</p-tabView>

<div *ngIf="batchService?.errors?.length">
  <span *ngFor="let error of batchService.errors">
    {{ error | json }}
  </span>
</div>

<ng-template #categories>
  <div class="batch-update__section">
    <p-fileUpload
      mode="basic"
      chooseLabel="Escolher CSV"
      name="categoryUpload"
      [auto]="true"
      [customUpload]="true"
      (uploadHandler)="onFileSelected($event, categoryUpload, 'category')"
      accept=".csv"
      [maxFileSize]="10 * 1024 * 1024"
      uploadIcon="pi pi-file"
      id="categoryUpload"
      #categoryUpload
    />
    <p-button label="Baixar Template" (onClick)="downloadCategoryTemplate()" />
  </div>
  <p>Observações:</p>
  <p><strong>1.</strong> A colunas "slug" e "shops" são obrigatórias.</p>
  <p>
    <strong>2.</strong>
    Caso informado o valor "Glamshop,mensmarket" (ambas) na coluna shops, serão
    atualizadas todas as categorias (caso exista mais de uma).
  </p>
  <p>
    <strong>3.</strong>
    Lembre-se de remover os campos não utilizados e utilizar o ponto para
    delimitador de casas decimais ao invés de vírgula.
  </p>
  <p>
    <strong>4.</strong>
    Para atualizar tags, use o separador ponto e vírgula (;) na coluna
    designada. Ex: tag-1;tag-2;tag-3
  </p>
  <p>
    <strong>5.</strong>
    Para atualizar os filtros dos categoria, insira o
    <strong>slug do filtro</strong> e use o separador ponto e vírgula (;) na
    coluna designada.
    <br />
    Ex: filtro-slug-1;filtro-slug-2;filtro-slug-3
  </p>
</ng-template>

<ng-template #categoryMostRelevant>
  <div class="batch-update__section">
    <p-fileUpload
      mode="basic"
      chooseLabel="Escolher CSV"
      name="categoryUpload"
      [auto]="true"
      [customUpload]="true"
      (uploadHandler)="onFileSelected($event, categoryUpload, 'mostRelevant')"
      accept=".csv"
      [maxFileSize]="10 * 1024 * 1024"
      uploadIcon="pi pi-file"
      id="categoryUpload"
      #categoryUpload
    />
    <a
      class="p-button p-button-primary"
      href="assets/documents/Modelo Batch update mais relevantes.csv"
      download="assets/documents/Modelo Batch update mais relevantes.csv"
    >
      Baixar Template
    </a>
    <!-- <p-button
      label="Baixar Template"
      (onClick)="downloadMostRelevantTemplate()"
    /> -->
  </div>
  <p>Observações:</p>
  <p>
    <strong>1.</strong> A coluna 'A' é de guia/referencia para importação, por
    favor não remover
  </p>
  <p>
    <strong>2.</strong> Cada coluna deverá representar uma categoria com seu
    slug e a loja da categoria (shops), e todos os produtos daquela coluna serão
    adicionados aos mais relevantes da categoria
  </p>
  <p>
    <strong>3.</strong> A primeira linha deve sempre conter o slug da categoria
    a ser atualizada.
  </p>
  <p>
    <strong>4.</strong> A segunda linha deve sempre conter a exibição de loja da
    categoria. Para categorias com exibição em ambas as lojas, usar vírgula para
    separar <br />Ex: "Glamshop,mensmarket"
  </p>
  <p>
    <strong>5.</strong> A terceira linha deve sempre conter os eans dos produtos
    mais relevantes da categoria. Para produtos com exibição em ambas as lojas,
    usar vírgula para separar <br />Ex: "1234567890,1234567891"
  </p>
</ng-template>

<ng-template #products>
  <div class="batch-update__section">
    <p-fileUpload
      mode="basic"
      chooseLabel="Escolher CSV"
      name="productUpload"
      [auto]="true"
      [customUpload]="true"
      (uploadHandler)="onFileSelected($event, productUpload, 'product')"
      accept=".csv"
      [maxFileSize]="10 * 1024 * 1024"
      uploadIcon="pi pi-file"
      id="productUpload"
      #productUpload
    />
    <p-button label="Baixar Template" (onClick)="downloadProductTemplate()" />
  </div>
  <p><strong>1.</strong> A coluna EAN é obrigatória</p>
  <p>
    <strong>2.</strong> Lembre-se de remover os campos não utilizados e utilizar
    o ponto para delimitador de casas decimais ao invés de vírgula
  </p>
  <p>
    <strong>3.</strong>
    Para atualizar tags, use o separador ponto e vírgula (;) na coluna
    designada. Ex: tag-1;tag-2;tag-3
  </p>
  <p>
    <strong>4.</strong>
    Para atualização do label do produto, o mesmo deve ser uma das seguintes
    opções: outlet, promocao, ultimasunidades, glamstar ou informe null para
    remover a label
  </p>
</ng-template>

<ng-template #reviews>
  <div class="batch-update__section">
    <p-fileUpload
      mode="basic"
      chooseLabel="Escolher CSV"
      name="reviewUpload"
      [auto]="true"
      [customUpload]="true"
      (uploadHandler)="onFileSelected($event, reviewUpload, 'review')"
      accept=".csv"
      [maxFileSize]="10 * 1024 * 1024"
      uploadIcon="pi pi-file"
      id="reviewUpload"
      #reviewUpload
    />
    <p-button label="Baixar Template" (onClick)="downloadReviewTemplate()" />
  </div>
  <p>
    <strong>1.</strong> Todas as colunas são obrigatórias, exceto "createdAt"
  </p>
  <p>
    <strong>2.</strong> A data de criação deve respeitar o format dd/MM/yyyy
    HH:mm:ss. Caso não informada, será criada com a data atual.
  </p>
</ng-template>
