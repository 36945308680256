import {
  Component,
  inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  Category,
  Product,
  ShopBanner,
  ShopBrand,
  ShopBrands,
  ShopCollection,
  Shops,
  ShopSection,
  ShopSettings
} from '@infrab4a/connect';
import { GridStackOptions, GridStackWidget } from 'gridstack';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CarouselResponsiveOptions } from 'primeng/carousel';
import { GridstackComponent } from 'src/app/components/gridstack/gridstack.component';
import {
  CarouselSection,
  isBanner,
  isBrands,
  isCarousel,
  isCollection,
  ShopSettingsService
} from 'src/app/connect-api/api/shop-settings/shop-settings.service';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import {
  getSubscriptionIdsByPriority,
  getSubscriptionImage,
  getSubscriptionName
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-glam-home',
  templateUrl: './glam-home.component.html',
  styleUrl: './glam-home.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class GlamHomeComponent implements OnInit {
  static documentId = 'GLAM_HOME';

  @ViewChild(GridstackComponent) gridComp?: GridstackComponent;
  private shopSettingsService = inject(ShopSettingsService);
  private categoryService = inject(ShopCategoryService);
  private productService = inject(ShopProductService);
  private title = inject(Title);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
  settings: Partial<ShopSettings> & { sections?: ShopSection[] };
  firstSections: ShopSection[];
  movableSections: ShopSection[];
  lastSections: ShopSection[];
  brands: ShopBrand[];
  gridOptions: GridStackOptions = {
    margin: 5,
    minRow: 1,
    cellHeight: '200px',
    removable: false,
    column: 1
  };
  responsiveOptionsSmall: CarouselResponsiveOptions[] = [
    {
      breakpoint: '1199px',
      numVisible: 4,
      numScroll: 4
    },
    {
      breakpoint: '991px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 2
    }
  ];
  responsiveOptionsBig = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '991px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  products: { [id: string]: Array<Partial<Product>> } = {};
  categories: Partial<Category>[];
  plans: { name: string; id: number; image: string }[] =
    getSubscriptionIdsByPriority().map(
      (s) =>
        ({
          name: getSubscriptionName(s).replace('Glam', ''),
          id: s,
          image: getSubscriptionImage(s)
        } as { name: string; id: number; image: string })
    );
  carrouselBanners: any[];

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    try {
      this.title.setTitle('Customizar Home da Glam');
      this.homeSettings = await this.shopSettingsService.getById(
        GlamHomeComponent.documentId
      );
      const collections = this.movableSections.filter(
        (s) => isCollection(s) && s.categoryId
      ) as ShopCollection[];
      if (collections?.length)
        this.categories = await Promise.all(
          collections.map((s) =>
            this.categoryService.getCategoryById(s.categoryId)
          )
        );
      else this.categories = [];
      for (const cat of this.categories) {
        if (cat.getMostRelevantByShop(Shops.GLAMSHOP)) {
          this.products[cat.id] = await Promise.all(
            cat
              .getMostRelevantByShop(Shops.GLAMSHOP)
              .slice(0, 4)
              .map((p) => this.productService.getProductById(p))
          );
        } else if (cat.products?.length) {
          this.products[cat.id] = await Promise.all(
            cat.products
              .slice(0, 4)
              .map((p) => this.productService.getProductById(p))
          );
        } else {
          this.products[cat.id] = [];
        }
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async listChanged(): Promise<void> {
    LoaderService.showLoader();
    const grid = this.gridComp?.grid?.save() as GridStackWidget[];
    let sections = [...this.firstSections];
    grid.forEach((i) =>
      sections.push(this.movableSections?.find((s) => s.id === i.id))
    );
    sections = sections.concat(this.lastSections);
    try {
      this.settings = await this.shopSettingsService.update({
        ...this.settings,
        sections
      });
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Componentes atualizados com sucesso!'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  sectionTitle(section: ShopSection): string {
    return section.type === 'banner' ||
      section.type === 'collection' ||
      section.type === 'collection premium'
      ? section.type
      : section.title;
  }

  category(categoryId: string): Partial<Category> {
    if (!FormUtil.isOnlyNumbers(categoryId))
      return this.categories?.find(
        (c) => c['firestoreId'] && c['firestoreId'] === categoryId
      );
    return this.categories?.find((c) => c.id === categoryId);
  }

  deleteSection(
    $event: MouseEvent,
    section: ShopBanner | ShopCollection
  ): void {
    if (!isBanner(section) && !isCollection(section)) {
      this.messageService.add({
        severity: 'error',
        detail: 'Não é possível remover esta seção',
        summary: 'Erro'
      });
      return;
    }
    this.confirmationService.confirm({
      acceptLabel: 'Remover',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Voltar',
      target: $event.target,
      message: `Desejar remover ${
        section.type === 'banner' ? 'o banner' : 'a coleção'
      } ${isCollection(section) ? section.title : section.altText}?`,
      accept: async () => {
        try {
          LoaderService.showLoader();
          const widget = this.gridComp?.gridstackItems?.find(
            (i) => i.options.id === section.id
          )?.el;
          if (!widget)
            AppDialogService.showErrorDialog({
              message:
                'Não foi possível remover a seção. Atualize a página e tente novamente.'
            });
          else {
            const index = this.gridComp?.gridstackItems
              .map((i) => i.options.id)
              .indexOf(section.id);
            const callSubmit =
              index + 1 === this.gridComp?.gridstackItems.length;
            this.gridComp?.grid?.removeWidget(widget);
            if (callSubmit) await this.listChanged();
          }
        } catch (error) {
          AppDialogService.showErrorDialog(error);
        }
      }
    });
  }

  set homeSettings(settings: Partial<ShopSettings>) {
    this.settings = settings;
    this.firstSections = this.settings?.sections?.filter(
      (s) =>
        s.type === 'anuncy' ||
        s.type === 'live-embedded' ||
        s.type === 'carousel' ||
        s.type === 'benefits' ||
        s.type === 'beauty-profile'
    );
    this.lastSections = this.settings?.sections?.filter(
      (s) => s.type === 'newsletter'
    );
    this.movableSections = this.settings?.sections?.filter(
      (s) =>
        s.type === 'banner' ||
        s.type === 'collection' ||
        s.type === 'collection premium' ||
        s.type === 'plans' ||
        s.type === 'brands'
    );
    this.brands = (
      this.movableSections?.find((s) => isBrands(s)) as ShopBrands
    )?.brands;
    this.carrouselBanners =
      (
        this.firstSections.find((s) => isCarousel(s)) as CarouselSection
      )?.banners?.filter((b) => b.published) || [];
  }

  get maxBanners(): boolean {
    return this.movableSections?.filter((s) => isBanner(s)).length >= 5;
  }

  get maxCollections(): boolean {
    return this.movableSections?.filter((s) => isCollection(s)).length >= 6;
  }

  collectionUrl(collection: ShopCollection): string {
    if (!collection.slug) return null;
    return `${environment.glamUrl}/loja/collections/${collection.slug}`;
  }
}
