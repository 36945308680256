<div>
  <div *ngIf="!course" class="page-title">Novo curso</div>
  <div *ngIf="course" class="page-title">
    <ng-container *ngIf="permission"> Editar </ng-container>
    <ng-container *ngIf="!permission"> Visualizar </ng-container>
    curso
  </div>
</div>

<div class="details course-detail" *ngIf="course">
  <div class="details__left">
    <div class="card card-user">
      <div class="card-body">
        <div>
          <h4>{{ course.name }}</h4>
          <p>{{ activeModules?.length || 0 }} módulo(s)</p>
          <p>
            <i class="pi pi-play-circle"></i>
            {{ activeContent?.length || 0 }} vídeo(s)
          </p>
          <p>
            <i class="pi pi-clock"></i>
            {{ totalActiveDuration | number : '1.0-0' }} minuto(s)
          </p>
          <p *ngIf="likes">
            <i class="pi pi-thumbs-up"></i>
            {{ totalLikes | number : '1.0-0' }} like(s)
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="details__right">
    <p-tabView [activeIndex]="tabIndex" (activeIndexChange)="navigate($event)">
      <p-tabPanel>
        <ng-template pTemplate="header"> Informações </ng-template>
        <app-course-form [course]="course" [permission]="permission" />
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          Módulos ({{ course.modules?.length || 0 }})
        </ng-template>
        <div class="course-modules">
          <div
            class="course-modules__buttons"
            *ngxPermissionsOnly="permissionsAccepted"
          >
            <p-button
              severity="primary"
              label="Adicionar módulo"
              icon="pi pi-plus"
              (onClick)="changeModule()"
              type="button"
            />
          </div>
          <p-tree
            [value]="modules"
            class="w-full md:w-30rem"
            [draggableNodes]="permission"
            [droppableNodes]="permission"
            draggableScope="self"
            droppableScope="self"
            (onNodeDrop)="changeOrder($event)"
            *ngIf="modules?.length"
          >
            <ng-template let-node pTemplate="module" let-index>
              <span
                class="course-modules__course-module"
                [class.course-modules__course-module--no-action]="!permission"
              >
                <b class="label" [class.label--disabled]="!node.data.active">
                  {{ node.data.order }}. {{ node.data.name }}
                  <small *ngIf="!node?.children?.length">
                    (* Sem videos)
                  </small>
                </b>

                <b>
                  {{ moduleViews(node.data) | number : '1.0-0' }}
                </b>
                <span class="input-switch">
                  <p-inputSwitch
                    [inputId]="'moduleStatus' + index"
                    [(ngModel)]="node.data.active"
                    (onChange)="changeModuleStatus(node.data)"
                    [disabled]="!permission"
                  />
                </span>
                <span class="buttons" *ngxPermissionsOnly="permissionsAccepted">
                  <p-button
                    icon="pi pi-pencil"
                    styleClass="p-button-xs"
                    severity="primary"
                    [rounded]="true"
                    [text]="true"
                    pTooltip="Editar módulo"
                    tooltipPosition="left"
                    (onClick)="changeModule(node.data)"
                  />
                  <p-button
                    icon="pi pi-plus-circle"
                    styleClass="p-button-xs"
                    severity="success"
                    [rounded]="true"
                    [text]="true"
                    pTooltip="Adicionar video"
                    tooltipPosition="left"
                    (onClick)="changeContent(node.data.moduleId)"
                    *ngIf="node.data.active"
                  />
                </span>
              </span>
            </ng-template>
            <ng-template pTemplate="content" let-node let-index>
              <span
                class="course-modules__course-module-content"
                [class.course-modules__course-module-content--no-action]="
                  !permission
                "
              >
                <b class="label" [class.label--disabled]="!node.data.active">
                  {{ node.data.order }}. {{ node.data.title }}
                </b>
                <b>
                  {{ contentViews(node.data) | number : '1.0-0' }}
                </b>
                <span class="input-switch">
                  <p-inputSwitch
                    [inputId]="'contentStatus' + index"
                    [(ngModel)]="node.data.active"
                    (onChange)="changeContentStatus(node.data)"
                    [disabled]="!permission"
                  />
                </span>
                <span class="buttons" *ngxPermissionsOnly="permissionsAccepted">
                  <p-button
                    icon="pi pi-pencil"
                    styleClass="p-button-xs"
                    severity="primary"
                    [rounded]="true"
                    [text]="true"
                    pTooltip="Editar video"
                    tooltipPosition="left"
                    (onClick)="changeContent(node.data.moduleId, node.data)"
                  />
                </span>
              </span>
            </ng-template>
            <ng-template pTemplate="header">
              <ng-template
                *ngTemplateOutlet="headerTemp; context: { $implicit: node }"
              ></ng-template>
            </ng-template>
          </p-tree>
        </div>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <i class="pi pi-thumbs-up"></i>&nbsp;Likes ({{
            likes?.length || 0 | number : '1.0-0'
          }})
        </ng-template>
        <app-table
          [lazy]="false"
          [cols]="likeCols"
          [elements]="likes"
          *ngIf="likes"
          modelName="likes"
          sortField="dateUpdated"
        />
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <i class="pi pi-eye"></i>&nbsp;Visualizações ({{
            access?.length || 0 | number : '1.0-0'
          }})
        </ng-template>
        <app-table
          [lazy]="false"
          [cols]="accessCols"
          [elements]="access"
          *ngIf="access"
          modelName="visualizações"
          sortField="dateUpdated"
        />
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
<ng-container *ngIf="!course">
  <app-course-form [permission]="permission" />
</ng-container>
<ng-template #headerTemp let-node>
  <span
    class="course-modules__header"
    [class.course-modules__header--no-action]="!permission"
  >
    <b class="label">Nome</b>
    <b class="label">Acessos</b>
    <b class="course-modules__header__status">Inativo/Ativo</b>
    <b
      class="course-modules__header__buttons"
      *ngxPermissionsOnly="permissionsAccepted"
      >Ações</b
    >
  </span>
</ng-template>
<p-toast />
