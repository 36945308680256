import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { lastValueFrom, map } from 'rxjs';
import {
  Badge,
  BadgeControllerService,
  FileControllerService
} from 'src/app/admin-api';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
@Component({
  selector: 'app-badge-form',
  templateUrl: './badge-form.component.html',
  styleUrl: './badge-form.component.scss',
  providers: [MessageService]
})
export class BadgeFormComponent
  extends ImageUploadComponent<Badge>
  implements OnInit
{
  @Input()
  badge: Badge | undefined;

  @Output()
  updated = new EventEmitter<Badge>();

  permission = false;

  constructor(
    private badgeService: BadgeControllerService,
    private messageService: MessageService,
    override fileService: FileControllerService,
    private permissionsService: NgxPermissionsService,
    override router: Router
  ) {
    super(fileService, router);
  }
  async ngOnInit(): Promise<void> {
    this.permission = await this.permissionsService.hasPermission([
      roleAsObject(Role.Full_Administrator).enumValue
    ]);
    this.form = new FormGroup({
      badgeId: new FormControl({
        value: this.badge?.badgeId,
        disabled: !this.permission
      }),
      title: new FormControl(
        { value: this.badge?.title, disabled: !this.permission },
        [Validators.minLength(4), Validators.maxLength(30), Validators.required]
      ),
      glampointsMultiplier: new FormControl(
        { value: this.badge?.glampointsMultiplier, disabled: !this.permission },
        [Validators.required, Validators.min(1)]
      ),
      consecutiveEditionsToAchieve: new FormControl(
        {
          value: this.badge?.consecutiveEditionsToAchieve,
          disabled: !this.permission
        },
        [Validators.required, Validators.min(1)]
      ),
      monthsToExpiration: new FormControl(
        {
          value: this.badge?.monthsToExpiration,
          disabled: !this.permission
        },
        [Validators.required, Validators.min(1)]
      ),
      level: new FormControl(
        { value: this.badge?.level, disabled: !this.permission },
        [Validators.required, Validators.min(1)]
      ),
      iconUrl: new FormControl(
        { value: this.badge?.iconUrl, disabled: !this.permission },
        Validators.required
      ),
      hexColorCode: new FormControl(
        {
          value:
            this.badge?.hexColorCode === null
              ? '#00000'
              : this.badge?.hexColorCode,
          disabled: !this.permission
        },
        Validators.required
      )
    });
  }

  async saveBadge(): Promise<void> {
    if (this.form?.valid) {
      LoaderService.showLoader();
      try {
        if (this.badge?.badgeId) {
          const request: Badge = { ...this.form.value };
          this.badge = await lastValueFrom(
            this.badgeService
              .updateBadge(request)
              .pipe(map((data) => data.result))
          );
          this.updated.emit(this.badge);
          this.messageService.add({
            severity: 'success',
            detail: 'Jóia atualizada com sucesso',
            summary: 'Sucesso'
          });
        } else {
          const request: Badge = { ...this.form.value };
          this.badge = await lastValueFrom(
            this.badgeService
              .createBadge(request)
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            severity: 'success',
            detail: 'Jóia cadastrada com sucesso',
            summary: 'Sucesso'
          });
        }
        await this.afterSubmit();
        this.updated.emit(this.badge);
      } catch (error: any) {
        AppDialogService.showErrorDialog(error);
      }
      LoaderService.showLoader(false);
    }
  }

  get filePath(): string {
    return '/admin/badges/';
  }

  override get model() {
    return this.badge;
  }

  override get modelId() {
    return this.badge?.badgeId || 0;
  }
}
