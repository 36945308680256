<form class="modal-form" [formGroup]="form" (submit)="submit()">
  <span class="p-input-editor" *ngIf="form?.controls?.html?.enabled">
    <span class="label-option">
      <label for="html">Conteúdo *</label>
      <span class="p-input-checbox">
        <p-checkbox
          formControlName="HTML"
          [binary]="true"
          inputId="HTML"
          name="HTML"
          (onChange)="format()"
        />
        <label for="HTML">Ver código fonte</label>
      </span>
    </span>
    <p-editor
      [readonly]="!permission"
      formControlName="html"
      id="html"
      (keyup)="cdRef.detectChanges()"
      (click)="cdRef.detectChanges()"
      [hidden]="form.value.HTML"
      [modules]="{
        toolbar: { handlers, container: toolbarOptions },
        blotFormatter2: {
          resize: {
            handleClassName: 'dimensioned'
          }
        }
      }"
    >
      <ng-template pTemplate="header" />
    </p-editor>
    <textarea
      name="html"
      id="html"
      formControlName="html"
      *ngIf="form.value.HTML"
      rows="20"
    >
    </textarea>
    <small class="hint" [class.danger]="!form.controls.html.valid">
      {{ form.value.html?.trim().length || 0 | number : '1.0-0' }}/50.000
    </small>
  </span>
  <span
    class="p-float-label"
    *ngIf="form?.controls?.collectionId?.enabled && categories?.length"
  >
    <p-dropdown
      [options]="categories"
      optionLabel="name"
      optionValue="id"
      formControlName="collectionId"
      [autoDisplayFirst]="false"
      [filter]="true"
      filterBy="name,slug,description"
      inputId="collectionId"
      name="collectionId"
      appendTo="body"
      [virtualScroll]="true"
      [virtualScrollItemSize]="38"
      (onChange)="categoryChange()"
    />
    <label for="collectionId">Coleção *</label>
  </span>

  <ng-container *ngIf="products?.length">
    <strong>Preview da coleção</strong>
    <div class="shop-products">
      <span *ngFor="let product of products" class="shop-products__product">
        <img
          [src]="product.images?.at(0) || 'assets/images/no_img_available.png'"
        />
        {{ product.name }}
      </span>
    </div>
    <small>{{ totalProducts | number : '1.0-0' }} produtos no total</small>
  </ng-container>

  <span class="buttons">
    <p-button label="Voltar" (onClick)="ref.close()" severity="danger" />
    <p-button label="Salvar" type="submit" [disabled]="!form.valid" />
  </span>
</form>
<input type="file" hidden id="inputFile" (change)="handleFile($event)" />
<p-toast />
