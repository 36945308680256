import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  Order,
  OrderRepository,
  OrderStatus,
  Shops,
  UserAddress,
  Where
} from '@infrab4a/connect';
import { getNationalHolidaysByYear } from 'brazil-holidays';
import { ConnectRequest } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { getShop } from '../../enums/ShopMap';
import { BaseConnectService } from './base-connect.service';

@Injectable({
  providedIn: 'root'
})
export class ShopOrderService extends BaseConnectService<Order> {
  holidays: { date: string; name: string; type: string }[];

  constructor(
    @Inject('OrderRepository')
    private orderRepository: OrderRepository
  ) {
    super(orderRepository);
    getNationalHolidaysByYear(new Date().getFullYear())
      .then((holidays) => (this.holidays = holidays))
      .catch((error) => AppDialogService.showErrorDialog(error));
  }

  async getOrderById(id: string): Promise<Order> {
    return await this.orderRepository.get({ id });
  }

  async getOrdersByEmail(email: string): Promise<Order[]> {
    return this.orderRepository
      .find({
        filters: {
          user: {
            email
          }
        }
      })
      .then((res) => res.data);
  }

  async getOrdersByPersonId(id: number): Promise<Order[]> {
    return this.orderRepository
      .find({
        filters: {
          user: {
            id: id.toString()
          }
        }
      })
      .then((res) => res.data);
  }

  async updateOrderStatus(status: string, id: string) {
    return this.orderRepository.update(new Order({ id, status }));
  }

  async getOrders(dateInit: Date, shop?: string): Promise<Array<Order>> {
    const filters = {
      createdAt: {
        operator: Where.GTE,
        value: dateInit.getTime()
      }
      // status: {
      //   operator: Where.EQUALS,
      //   value: 'Entregue'
      // },
      // 'shipping.ShippingCompanyName': {
      //   operator: Where.EQUALS,
      //   value: 'Glam'
      // }
    };
    if (shop) {
      filters['shop'] = {
        operator: Where.EQUALS,
        value: getShop(shop)
      };
    }
    return (
      await this.orderRepository.find({
        filters
      } as any)
    ).data.map((o) => {
      o.user.displayName =
        o.user?.firstName || o.user.lastName
          ? o.user.firstName + ' ' + o.user.lastName
          : o.user.displayName;
      return o;
    });
  }

  async paidToday() {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const orders = await this.getOrdersList({
      filters: [
        {
          condition: 'gte',
          field: 'createdAt',
          fieldType: 'date',
          value: date
        },
        {
          condition: 'equals',
          field: 'payment.status',
          fieldType: 'text',
          value: 'paid'
        }
      ],
      sortBy: 'createdAt',
      sortDirection: 'desc',
      page: 0,
      pageSize: 9999
    });
    return orders.content.filter(
      (o) =>
        o.status !== OrderStatus.CANCELADO &&
        [Shops.GLAMSHOP, Shops.MENSMARKET].includes(o.shop)
    );
  }

  async updateOrderAddress(
    address: Partial<UserAddress>,
    type: 'billing' | 'shipping' = 'billing',
    id: string
  ) {
    const body = { id };
    body[type + 'Address'] = {
      ...address,
      mailbox: address.mailboxNumber?.length > 0
    } as UserAddress;
    return this.orderRepository.update(new Order(body));
  }

  async getOrdersList(request: Partial<ConnectRequest>) {
    const result = await this.paginatedSearch(request);
    const content = result.data.map((category) => ({ ...category } as Order));
    const totalPages = request.pageSize
      ? Math.floor(result.count / request.pageSize)
      : undefined;
    const totalElements = result.count;

    return {
      totalPages,
      totalElements,
      content,
      empty: result.count === 0
    };
  }

  deliveryDate(order: Partial<Order>): string {
    if (!order.payment?.paidAt) {
      return '';
    }
    if (!order.shipping?.DaysToDelivery) {
      return (
        (order.shipping as any)?.additionalDescription ||
        (order.shipping as any)?.description ||
        'Indeterminado'
      );
    }
    const datePipe = new DatePipe('pt-BR');
    const date = new Date(order.payment.paidAt);
    for (let index = 0; index < order.shipping.DaysToDelivery; index++) {
      date.setDate(date.getDate() + 1);
      while (
        date.getDay() === 0 ||
        date.getDay() === 6 ||
        this.holidays?.some(
          (h) => h.date === datePipe.transform(date, 'yyyy-MM-dd')
        )
      ) {
        date.setDate(date.getDate() + 1);
      }
    }
    return datePipe.transform(date, 'dd/MM/yyyy');
  }
}
