<div class="invoices-dashboard">
  <h3>Dashboard de DANFE's Emitidas</h3>
  <p>
    Esse dashboard contempla apenas as DANFEs emitidas referentes à assinatura
    feminina. As DANFEs de trocas e brindes não estão sendo contabilizadas aqui.
  </p>

  <form [formGroup]="filterForm" (ngSubmit)="onSubmit()" class="filter-form">
    <label for="ano">Ano:</label>
    <p-dropdown
      id="ano"
      formControlName="ano"
      [options]="availableYears"
      placeholder="Selecione o Ano"
      appendTo="body"
      styleClass="dropdown"
      required
    ></p-dropdown>

    <label for="mes">Mês:</label>
    <p-dropdown
      id="mes"
      formControlName="mes"
      [options]="availableMonths"
      placeholder="Selecione o Mês"
      appendTo="body"
      styleClass="dropdown"
      required
      [optionLabel]="'name'"
      [optionValue]="'value'"
    >
      ></p-dropdown
    >

    <p-button
      type="submit"
      label="Buscar"
      [disabled]="filterForm.invalid"
      icon="pi pi-search"
      class="search-button"
    ></p-button>
  </form>

  <p-table
    *ngIf="invoices.length"
    [value]="invoices"
    [tableStyle]="{ 'min-width': '50rem' }"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Dia</th>
        <th>Quantidade</th>
        <th>Total</th>
        <th>Média</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-invoice>
      <tr>
        <td>{{ invoice.dateUpdated | date : 'dd' : 'UTC' }}</td>
        <td>{{ invoice.quantidade }}</td>
        <td>{{ invoice.total | currency : 'BRL' }}</td>
        <td>{{ invoice.media | currency : 'BRL' }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr>
        <td><strong>Total</strong></td>
        <td>
          <strong>{{ totalQuantidade }}</strong>
        </td>
        <td>
          <strong>{{ totalValue | currency : 'BRL' }}</strong>
        </td>
        <td>
          <strong>{{ averageValue | currency : 'BRL' }}</strong>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
