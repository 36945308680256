<div class="order-detail__header">Loja - Detalhes do pedido</div>
<div class="order-detail__content" *ngIf="order">
  <div class="order-detail__content__left">
    <app-person-card
      *ngIf="person"
      [showProfileLink]="order.shop !== 'mensmarket'"
      [background]="order.shop === 'mensmarket' && 'blue'"
      [showResetPassword]="order.shop !== 'mensmarket'"
      [person]="person"
      [showProfileLink]="person?.personId > 0"
      [showResetPassword]="person?.personId > 0"
    />
  </div>
  <div class="order-detail__content__right">
    <div class="card shipping-order">
      <span class="info">
        <strong>Loja:</strong>&nbsp;
        <span>{{ shopName | titlecase }}</span>
      </span>
      <span class="info">
        <strong>ID</strong>:
        <span>{{ order.orderNumber || order.id }}</span>
      </span>
      <span class="info">
        <strong>Data do pedido</strong>:
        {{ order.createdAt | date : 'dd/MM/yyyy HH:mm' }}
      </span>
      <span class="info">
        <strong>Status</strong>:
        <span
          class="status change"
          [ngClass]="{
            'status--active': order.status === 'Entregue',
            'status--warning': isWarningStatus,
            'status--danger': order.status === 'Cancelado'
          }"
          (click)="showDropdown()"
          *ngIf="!changeStatus"
        >
          {{ order.status }}
        </span>
        <span *ngIf="changeStatus && statusList">
          <p-dropdown
            [options]="statusList"
            [formControl]="status"
            (onChange)="changeOrderStatus()"
            (onBlur)="changeStatus = false"
            appendTo="body"
            (onHide)="changeStatus = false"
            [autoDisplayFirst]="false"
          >
          </p-dropdown>
        </span>
      </span>
      <span class="info" *ngIf="order?.coupon?.id && order?.coupon?.nickname">
        <strong>Cupom</strong>:
        {{ order.coupon.nickname }}
      </span>
      <span class="info" *ngIf="couponDiscountLabel">
        <strong>Desconto</strong>:
        {{ couponDiscountLabel }}
      </span>
      <span class="info" *ngIf="order.pedidov">
        <strong>PedidoV</strong>: {{ order.pedidov }}
      </span>
      <span class="info" *ngIf="order.orderTiny">
        <strong>OrderTiny</strong>: {{ order.orderTiny }}
      </span>
      <div class="shipping-summary">
        <span class="info"
          ><strong>Frete</strong>:&nbsp;
          <ng-container *ngIf="order.shipping?.ShippingPrice">
            {{ order.shipping.ShippingPrice | currency : 'BRL' }}
          </ng-container>
          <ng-container *ngIf="!order.shipping?.ShippingPrice"
            >Grátis</ng-container
          ></span
        >
        <span
          class="info"
          *ngIf="order.shipping?.description || order.shipping?.DaysToDelivery"
        >
          <strong>Prazo de entrega</strong>:
          {{
            order.shipping?.description ||
              order.shipping?.DaysToDelivery + ' dia(s)'
          }}
          <p-button
            size="small"
            styleClass="p-button-xs"
            severity="primary"
            icon="pi pi-truck"
            [rounded]="true"
            (onClick)="openTracking()"
            pTooltip="Ver rastreio"
            *ngIf="order.shipping?.invoiceNumber && tracking?.length"
          />
        </span>
        <span class="info" *ngIf="order.shipping?.ShippingCompanyName">
          <strong>Transportadora</strong>:
          {{ order.shipping?.ShippingCompanyName }}
        </span>
        <span class="info" *ngIf="order.shipping?.invoiceNumber">
          <strong>NF</strong>: {{ order.shipping?.invoiceNumber }}
        </span>
        <span class="info" *ngIf="order.trackingCode">
          <strong>Código de rastreio</strong>: {{ order.trackingCode }}
        </span>
        <span class="info" *ngIf="order.trackingUrl">
          <strong>URL de rastreio</strong>:
          <a [href]="order.trackingUrl" target="_blank">
            {{ order.trackingUrl }}
          </a>
        </span>
        <span
          class="info info--full"
          *ngIf="
            order?.shippingAddress?.street &&
            order?.shippingAddress?.city &&
            order?.shippingAddress?.state
          "
        >
          <strong>Endereço</strong>:
          {{ order.shippingAddress.street }}
          {{ order.shippingAddress.number
          }}<span *ngIf="order.shippingAddress.extension?.length"
            >, {{ order.shippingAddress.extension }}</span
          >
          - {{ order.shippingAddress.district }},
          {{ order.shippingAddress.city }} - {{ order.shippingAddress.state }},
          Brasil, CEP
          {{ order.shippingAddress.zip }}
        </span>
        <span
          class="info info--full"
          *ngIf="order.shipping?.additionalDescription"
        >
          <strong>Detalhes</strong>:
          {{ order.shipping?.additionalDescription }}
        </span>
      </div>
      <div class="payment-summary">
        <span>
          <p>
            <strong>
              <i
                class="pi"
                [class.pi-barcode]="order.payment?.paymentMethod === 'boleto'"
                [class.pi-qrcode]="order.payment?.paymentMethod === 'pix'"
                [class.pi-credit-card]="
                  order.payment?.paymentMethod === 'credit_card'
                "
              ></i>
              {{ methodName | titlecase }}
            </strong>
          </p>
          <p *ngIf="order.payment?.id">
            <strong>ID Pagamento</strong>: {{ order.payment?.id }}
          </p>
          <p *ngIf="order.payment?.acquirerName">
            <strong>Gateway</strong>:
            {{ order.payment?.acquirerName | titlecase }}
          </p>
          <p>
            <strong>Valor</strong>:
            {{ order.totalPrice | currency : 'BRL' }}
            <ng-container *ngIf="order.payment?.installments > 1">
              em {{ order.payment?.installments }}x de
              {{
                order.totalPrice / order.payment?.installments
                  | currency : 'BRL'
              }}
            </ng-container>
            <ng-container *ngIf="order.payment?.installments === 1">
              à vista
            </ng-container>
            <span
              class="status"
              [ngClass]="{
                'status--active': order.payment?.status === 'paid',
                'status--warning':
                  order.payment?.status === 'waiting_payment' ||
                  order.payment?.status === 'pending' ||
                  order.payment?.status === 'processing',
                'status--danger': order.payment?.status === 'Estornado'
              }"
            >
              {{ paymentStatus }}
            </span>
          </p>
          <app-credit-card-view
            *ngIf="order.payment?.card"
            [creditCard]="order.payment?.card"
          />
          <ng-container
            *ngIf="
              !order.payment?.card && order.payment?.paymentMethod === 'boleto'
            "
          >
            <p
              *ngIf="
                order.payment?.boletoExpirationDate &&
                order.payment?.status !== 'paid'
              "
            >
              <strong>Vencimento: </strong>
              {{ order.payment?.boletoExpirationDate | date : 'dd/MM/yyyy' }}
              <strong
                *ngIf="expired"
                [pTooltip]="getTooltip()"
                tooltipPosition="bottom"
              >
                &nbsp;<i class="pi pi-calendar-times"></i
              ></strong>
            </p>
            <p *ngIf="order.payment?.paidAt">
              <strong>Data pagamento: </strong
              >{{ order.payment?.paidAt | date : 'dd/MM/yyyy HH:mm:ss' }}
            </p>
            <p
              *ngIf="
                order.payment?.boletoBarcode && order.payment?.status !== 'paid'
              "
            >
              <strong>Código de barras:</strong>
              <a
                class="data-link"
                type="button"
                (click)="copyToClipboard(order.payment.boletoBarcode)"
                [pTooltip]="getTooltip('boletoBarcode')"
                tooltipPosition="bottom"
                >{{ order.payment?.boletoBarcode
                }}<span class="copy"> &nbsp;<i class="pi pi-copy"></i></span>
              </a>
            </p>
            <p
              *ngIf="order.payment?.boletoUrl"
              [pTooltip]="getTooltip('boletoUrl')"
              tooltipPosition="bottom"
            >
              <strong>URL:</strong>
              <a
                class="data-link"
                [class.data-link--line-through]="
                  order.payment.status === 'paid' || expired
                "
                [href]="order.payment?.boletoUrl"
                target="_blank"
                >{{ order.payment?.boletoUrl
                }}<span class="copy" *ngIf="order.payment.status !== 'paid'">
                  &nbsp;<i
                    class="pi"
                    [class.pi-external-link]="!expired"
                    [class.pi-calendar-times]="expired"
                  ></i
                ></span>
              </a>
              <strong *ngIf="order.payment.status === 'paid'">
                &nbsp;<i class="pi pi-verified"></i>
              </strong>
            </p>
          </ng-container>
          <ng-container
            *ngIf="
              !order.payment?.card && order.payment?.paymentMethod === 'pix'
            "
          >
            <p
              *ngIf="
                order.payment?.pixExpirationDate &&
                order.payment?.status !== 'paid'
              "
            >
              <strong>Vencimento: </strong>
              {{
                order.payment.pixExpirationDate | date : 'dd/MM/yyyy HH:mm:ss'
              }}
              <strong
                *ngIf="expired"
                [pTooltip]="getTooltip()"
                tooltipPosition="bottom"
              >
                &nbsp;<i class="pi pi-calendar-times"></i
              ></strong>
            </p>
            <p *ngIf="order.payment?.paidAt">
              <strong>Data pagamento: </strong>
              {{ order.payment.paidAt | date : 'dd/MM/yyyy HH:mm:ss' }}
            </p>
            <p *ngIf="order.payment?.pixQrcode">
              <strong>Código PIX:</strong>
              <a
                class="data-link"
                type="button"
                (click)="copyToClipboard(order.payment.pixQrcode)"
                [class.data-link--line-through]="
                  order.payment.status === 'paid'
                "
                [pTooltip]="getTooltip('pixQrcode')"
                tooltipPosition="bottom"
              >
                {{ order.payment.pixQrcode }}
                <i class="pi pi-copy"></i>
              </a>
            </p>
          </ng-container>
        </span>
        <span class="payment-summary__summary">
          <h3>Resumo</h3>
          <p *ngIf="order.shop !== 'Glampoints'">
            <strong>Subtotal:</strong>
            <span>{{ productsTotal | currency : 'BRL' }}</span>
          </p>
          <p *ngIf="order.shipping?.ShippingPrice">
            <strong>Entrega:</strong>
            <span>{{ order.shipping?.ShippingPrice | currency : 'BRL' }}</span>
          </p>
          <p *ngIf="!order.shipping?.ShippingPrice">
            <strong>Entrega:</strong><span>Grátis</span>
          </p>
          <p *ngIf="subscriberDiscount > 0 && order.shop !== 'Glampoints'">
            <strong>Desconto clube (-):</strong>
            <span class="payment-summary__discount">{{
              subscriberDiscount | currency : 'BRL'
            }}</span>
          </p>
          <p *ngIf="order.discount && order.shop !== 'Glampoints'">
            <strong>Cupom (-):</strong>
            <span class="payment-summary__discount">
              {{ order.discount | currency : 'BRL' }}</span
            >
          </p>
          <p>
            <strong>Total:</strong>
            <span>{{ order.totalPrice | currency : 'BRL' }}</span>
          </p>
          <p *ngIf="refunds?.length">
            <strong>* Estornos (-):</strong>
            <span class="payment-summary__discount">{{
              refundedAmount | currency : 'BRL'
            }}</span>
          </p>
        </span>
      </div>
      <span
        class="buttons"
        *ngIf="order.shop !== 'Glampoints' && !refunds?.length"
      >
        <p-button
          label="Estornar pedido"
          severity="danger"
          (onClick)="refundOrder(true)"
          *ngIf="order.status !== 'Canceledado'"
        />
        <p-button
          label="Estornar produto(s)"
          styleClass="p-button-primary"
          *ngIf="lineItems?.length > 1"
          (onClick)="refundOrder()"
        />
      </span>
    </div>
    <div class="card">
      <p-tabView
        [activeIndex]="selectedTab"
        (activeIndexChange)="tabChanged($event)"
      >
        <p-tabPanel header="Produtos">
          <div class="order-detail__tab">
            <app-table
              [cols]="cols"
              *ngIf="order && tableReady"
              modelName="produtos"
              tableTitle="Itens do pedido"
              [elements]="products"
              [lazy]="false"
              [addButton]="false"
              [defaultRows]="25"
              [dropdownFilters]="tableFilters"
            />
          </div>
        </p-tabPanel>
        <p-tabPanel header="Endereços">
          <div class="order-detail__tab">
            <p-fieldset legend="Entrega">
              <app-shop-order-address-form
                *ngIf="order && states"
                [states]="states"
                [order]="order"
                type="shipping"
              />
            </p-fieldset>
            <p-fieldset legend="Cobrança">
              <app-shop-order-address-form
                *ngIf="order && states"
                [states]="states"
                [order]="order"
                type="billing"
              />
            </p-fieldset>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Estornos" *ngIf="refunds?.length">
          <div class="order-detail__tab">
            <app-table
              [cols]="refundedCols"
              *ngIf="order && tableReady"
              modelName="Estornos"
              tableTitle="Itens estornados"
              [elements]="refundedProducts"
              [lazy]="false"
              [addButton]="false"
              [defaultRows]="25"
            />
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>

<p-toast />
<p-confirmDialog />
