/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProductVariantBrand {
  productVariantId?: number;
  productId?: number;
  productVariantName?: string;
  variantSummary?: string;
  costPrice?: number;
  invoicePrice?: number;
  invoiceName?: string;
  unitName?: string;
  unitMeasure?: number;
  inStock?: number;
  weight?: number;
  salePrice?: number;
  saleStock?: number;
  isSellable?: boolean;
  tradePoints?: number;
  tradeStock?: number;
  maxTradesPerPerson?: number;
  tradeActive?: boolean;
  isTradeable?: boolean;
  defaultImageURL?: string;
  externalStoreUrl?: string;
  orderCount?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  tradeCount?: number;
  longExternalStoreUrl?: string;
  isSellableUrl?: boolean;
  wmsId?: number;
  displayOrder?: number;
  syncedOnBling?: Date;
  internalEAN?: string;
  externalId?: number;
  millenniumSKU?: string;
  inStockInfluencerB4A?: number;
  brandId?: number;
  brandEquity?: number;
  brandName?: string;
  productName?: string;
  expiration?: string;
  inStockReserveMarketing?: number;
  inStockAcquisition?: number;
  inStockFidelization?: number;
  cst?: string;
  extipi?: string;
  genero?: string;
  ncm?: string;
  cean?: string;
  cfop?: string;
}
