import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { CourseControllerService, CourseDetail } from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrl: './courses.component.scss',
  providers: [MessageService]
})
export class CoursesComponent implements OnInit {
  courseService = inject(CourseControllerService);
  permissionService = inject(NgxPermissionsService);
  messageService = inject(MessageService);
  title = inject(Title);

  permission = signal(false);

  cols = computed(() => [
    new TableColumn.Builder()
      .setHeader('Id')
      .setField('courseId')
      .setType('number')
      .setRouterLink('')
      .setRouterLinkFieldName('courseId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Nome')
      .setField('name')
      .setCondition('contains')
      .setRouterLink('')
      .setRouterLinkFieldName('courseId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Ativo')
      .setField('active')
      .setType(this.permission ? 'inputSwitch' : 'boolean')
      .setFilter(true)
      .build(),
    new TableColumn.Builder()
      .setHeader('Likes')
      .setField('likes')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Módulos')
      .setField('activeModules')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Vídeos')
      .setField('activeContents')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Duração (min)')
      .setField('minutesTotal')
      .setType('formattedInteger')
      .setCondition('gte')
      .build(),
    new TableColumn.Builder()
      .setHeader('Autor')
      .setField('author')
      .setCondition('contains')
      .build(),
    new TableColumn.Builder()
      .setHeader('Criação')
      .setField('dateCreated')
      .setType('date')
      .setCondition('between')
      .build(),
    new TableColumn.Builder()
      .setHeader('Últ. atualização')
      .setField('dateUpdated')
      .setType('date')
      .setCondition('between')
      .build()
  ]);

  async ngOnInit(): Promise<void> {
    try {
      this.permission.set(
        await this.permissionService.hasPermission([
          roleAsObject(Role.Full_Administrator).enumValue,
          roleAsObject(Role.Content).enumValue
        ])
      );
    } catch (error) {
      console.error(error);
      this.permission.set(false);
    }
    this.title.setTitle('Cursos');
  }

  async updateCourseStatus(course: CourseDetail) {
    try {
      LoaderService.showLoader();
      await lastValueFrom(
        this.courseService.updateCourse(course, course.courseId)
      );
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Status do curso atualizado'
      });
    } catch (error) {
      course.active = !course.active;
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }
}
