<app-table
  pageFunctionName="findOpenFinanceInstitutionsTable"
  [service]="financialService"
  [cols]="cols"
  *ngIf="cols"
  modelName="instituições"
  tableTitle="Instituções financeiras do Open Finance"
  [defaultRows]="10"
  sortBy="active"
  [addButton]="false"
  (booleanAction)="updateInstitutionStatus($event)"
  [extraButton]="permission"
  extraLabel="Atualizar instituições"
  (extraButtonClick)="refreshInstitutions()"
/>
<p-toast />
<p-confirmDialog />
