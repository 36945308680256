/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CompositionProductVariantDTO } from './compositionProductVariantDTO';

export interface SubscriberEditionCompositionSummary {
  subscriberId?: number;
  editionId?: number;
  compositionId?: number;
  paymentOrderId?: number;
  recurrentPaymentId?: number;
  shippingOrderId?: number;
  invoiceNumber?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  compositionName?: string;
  subscriberEditionStatusId?: number;
  subscriberEditionStatusName?: string;
  theme?: string;
  title?: string;
  chaveNFE?: string;
  dateCreatedMillenium?: Date;
  dateUpdatedMillenium?: Date;
  statusMillenium?: string;
  imageURL?: string;
  trackingCode?: string;
  personName?: string;
  boxId?: string;
  personId?: number;
  subscriptionId?: number;
  subscriptionName?: string;
  products?: Array<CompositionProductVariantDTO>;
  isGlamclub?: boolean;
}
