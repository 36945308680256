<form class="form-b4a" (submit)="submit()" [formGroup]="form">
  <span class="p-float-label">
    <input id="title" type="text" pInputText formControlName="title" />
    <label for="title">Título *</label>
  </span>
  <span class="p-float-label">
    <input id="subtitle" type="text" pInputText formControlName="subtitle" />
    <label for="subtitle">Subtítulo *</label>
  </span>
  <span class="p-float-label">
    <input id="link" type="text" pInputText formControlName="link" />
    <label for="link">Link *</label>
  </span>
  <span class="p-float-label">
    <input
      id="couponName"
      type="text"
      pInputText
      formControlName="couponName"
    />
    <label for="couponName">Cupom</label>
  </span>
  <span class="input-switch">
    <label for="active">Inativo</label>
    <p-inputSwitch formControlName="active" inputId="active" />
    <label for="active">Ativo</label>
  </span>
  <span class="file-upload">
    <label for="image">
      <span>Imagem padrão</span>
      <img
        [src]="form.value?.image"
        *ngIf="form.value?.image"
        alt="Imagem padrão"
      />
      <img
        src="assets/images/no_img_available.jpg"
        *ngIf="!form.value?.image"
        alt="Imagem padrão"
      />
    </label>
    <p-fileUpload
      mode="basic"
      chooseLabel="Selecionar"
      name="file"
      [auto]="true"
      accept="image/*"
      [maxFileSize]="1000000"
      uploadIcon="pi pi-file"
      [customUpload]="true"
      (uploadHandler)="cropImageBeforeUpload($event, fileUpload)"
      id="image"
      #fileUpload
      [disabled]="!permission"
    />
  </span>
  <span class="buttons">
    <p-button styleClass="p-button-danger" routerLink="../" label="Voltar" />
    <p-button
      styleClass="p-button-primary"
      type="submit"
      [disabled]="!form.valid"
      label="Enviar"
    />
  </span>
</form>
<p-toast />
