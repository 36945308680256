<div>
  <div *ngIf="!live" class="page-title">Nova live</div>
  <div *ngIf="live" class="page-title">
    <ng-container *ngIf="permission"> Editar </ng-container>
    <ng-container *ngIf="!permission"> Visualizar </ng-container>
    live
  </div>
</div>

<div class="details live-detail" *ngIf="live">
  <div class="details__left">
    <div class="card card-user">
      <div class="card-body">
        <div class="live-detail__info">
          <h4 class="live-detail__info__title">
            {{ live.title }}
            <span *ngIf="live.active" class="live-detail__info__active">
              <img src="assets/icons/live.png" />AO VIVO
            </span>
          </h4>
          <p>
            {{ live.subtitle }}
          </p>
          <p *ngIf="live.couponName">
            <strong>Cupom: </strong>
            <a
              class="data-link coupon"
              type="button"
              (click)="copyToClipboard(live.couponName)"
              pTooltip="Copiar para área de transferência"
            >
              {{ live?.couponName }}
              <i class="pi pi-copy"></i>
            </a>
          </p>
          <p *ngIf="live.link && live.active">
            <strong>Link: </strong>
            <a target="_blank" class="data-link" [href]="live.link">{{
              live.link
            }}</a>
          </p>
          <p *ngIf="shopCategory">{{ shopCategory?.name }}</p>
          <p>
            <i class="pi pi-thumbs-up"></i>
            {{ totalLikes | number : '1.0-0' }}
          </p>
          <p>
            <i class="pi pi-eye"></i>
            {{ totalViews | number : '1.0-0' }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="details__right">
    <p-tabView
      (activeIndexChange)="indexChange($event)"
      [activeIndex]="tabIndex"
    >
      <p-tabPanel>
        <ng-template pTemplate="header"> Informações </ng-template>
        <app-live-form
          (liveChanged)="model = $event"
          [live]="live"
          [permission]="permission"
        />
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header"> Conteúdo </ng-template>

        <div class="live-detail__contents">
          <strong
            *ngIf="live.active && hasChange"
            class="live-detail__contents__active"
          >
            <span>
              <span class="live-detail__info__active">
                <img src="assets/icons/live.png" />AO VIVO
              </span>
              * ATENÇÃO! As alterações precisam ser salvas para serem aplicadas
            </span>
            <span>
              <p-button
                label="Desfazer alterações"
                severity="danger"
                (onClick)="model = live"
                *ngIf="hasChange"
              />
              <p-button
                label="Salvar blocos"
                (onClick)="saveContent()"
                *ngIf="hasChange"
              />
            </span>
          </strong>
          <div
            class="live-detail__contents__content"
            *ngFor="let content of contents; let i = index"
          >
            <span class="live-detail__contents__content__header">
              <strong>
                {{ content.order }} -
                {{ content.html ? 'Bloco de texto' : 'Coleção' }}
              </strong>
              <span class="live-detail__contents__content__header__buttons">
                <p-button
                  icon="pi pi-arrow-up"
                  pTooltip="Mover para cima"
                  tooltipPosition="bottom"
                  severity="warning"
                  [rounded]="true"
                  *ngIf="i > 0"
                  (onClick)="moveBlock(i, -1)"
                />
                <p-button
                  icon="pi pi-arrow-down"
                  pTooltip="Mover para baixo"
                  tooltipPosition="bottom"
                  severity="warning"
                  [rounded]="true"
                  *ngIf="i > 0 && i < contents?.length - 1"
                  (onClick)="moveBlock(i, 1)"
                />
                <p-button
                  icon="pi pi-pencil"
                  pTooltip="Editar bloco"
                  tooltipPosition="bottom"
                  (onClick)="
                    addBlock(content.html ? 'text' : 'collection', content)
                  "
                  [rounded]="true"
                />
                <p-button
                  icon="pi pi-trash"
                  pTooltip="Remover bloco"
                  tooltipPosition="bottom"
                  severity="danger"
                  (onClick)="deleteBlock(i, $event)"
                  [rounded]="true"
                />
              </span>
            </span>
            <span [innerHTML]="content.html" *ngIf="content.html"></span>
            <span class="collection" *ngIf="content.collectionId">
              <ng-container *ngIf="products?.length">
                <strong>{{ collection.name }}</strong>
                <div class="shop-products">
                  <span
                    *ngFor="let product of products"
                    class="shop-products__product"
                  >
                    <img
                      [src]="
                        product.images?.at(0) ||
                        'assets/images/no_img_available.png'
                      "
                    />
                    {{ product.name }}
                  </span>
                </div>
                <small
                  >{{ totalProducts | number : '1.0-0' }} produtos no
                  total</small
                >
              </ng-container>
            </span>
          </div>
        </div>
        <span class="live-detail__buttons">
          <span class="live-detail__buttons__message">
            <p-button
              label="Adicionar texto"
              (onClick)="addBlock('text')"
              [disabled]="descriptions?.length > 1"
            />
            <small *ngIf="descriptions?.length > 1">
              * Máximo de 2 blocos de texto
            </small>
          </span>
          <span class="live-detail__buttons__message">
            <p-button
              label="Adicionar coleção"
              (onClick)="addBlock('collection')"
              severity="warning"
              [disabled]="collectionId"
            />
            <small *ngIf="collectionId"> * Somente 1 coleção permitida </small>
          </span>
        </span>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <i class="pi pi-thumbs-up"></i>&nbsp;Likes&nbsp;({{
            likes?.length || 0 | number : '1.0-0'
          }})
        </ng-template>
        <app-table
          [cols]="likeCols"
          [elements]="likes"
          *ngIf="likes"
          [lazy]="false"
          modelName="likes"
        />
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <i class="pi pi-eye"></i>&nbsp; Visualizações ({{
            views?.length || 0 | number : '1.0-0'
          }})
        </ng-template>
        <app-table
          [cols]="viewCols"
          [elements]="views"
          *ngIf="views"
          [lazy]="false"
          modelName="visualizações"
        />
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
<app-live-form *ngIf="!live" />
<p-toast />
<p-confirmPopup *ngIf="tabIndex === 1" />
<p-confirmDialog styleClass="danger" *ngIf="tabIndex === 0" />
