/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { CouponCreateRequest } from '../model/couponCreateRequest';
import { CouponEmailRestrictionUpdateRequest } from '../model/couponEmailRestrictionUpdateRequest';
import { CouponEmailsRestrictionUpdateRequest } from '../model/couponEmailsRestrictionUpdateRequest';
import { CouponEnabledUpdateRequest } from '../model/couponEnabledUpdateRequest';
import { CouponProductVariant } from '../model/couponProductVariant';
import { CouponProductVariantCreateRequest } from '../model/couponProductVariantCreateRequest';
import { CouponProductVariantGroupRestriction } from '../model/couponProductVariantGroupRestriction';
import { CouponUpdateRequest } from '../model/couponUpdateRequest';
import { PageableRequest } from '../model/pageableRequest';
import { ResponseB4ACoupon } from '../model/responseB4ACoupon';
import { ResponseB4ACouponEmailRestriction } from '../model/responseB4ACouponEmailRestriction';
import { ResponseB4ACouponProductVariant } from '../model/responseB4ACouponProductVariant';
import { ResponseB4AListCouponEmailRestriction } from '../model/responseB4AListCouponEmailRestriction';
import { ResponseB4AListCouponEmailRestrictionDetail } from '../model/responseB4AListCouponEmailRestrictionDetail';
import { ResponseB4AListCouponProductVariantGroupRestriction } from '../model/responseB4AListCouponProductVariantGroupRestriction';
import { ResponseB4AListCouponProductVariantSubscriptionTypeRestriction } from '../model/responseB4AListCouponProductVariantSubscriptionTypeRestriction';
import { ResponseB4AListCouponProductVariantSummary } from '../model/responseB4AListCouponProductVariantSummary';
import { ResponseB4AListCouponSubscriptionTypeRestriction } from '../model/responseB4AListCouponSubscriptionTypeRestriction';
import { ResponseB4AListCouponUsageSummary } from '../model/responseB4AListCouponUsageSummary';
import { ResponseB4AListCouponUsageType } from '../model/responseB4AListCouponUsageType';
import { ResponseB4AListCouponUsageTypeByCouponName } from '../model/responseB4AListCouponUsageTypeByCouponName';
import { ResponseB4APageCouponSummary } from '../model/responseB4APageCouponSummary';
import { ResponseB4APageSubscriberCouponSummary } from '../model/responseB4APageSubscriberCouponSummary';
import { ResponseB4AString } from '../model/responseB4AString';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable()
export class CouponControllerService {
  protected basePath = 'https://admin-api.b4a.com.br';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addCouponEmailRestrictions(
    body: CouponEmailsRestrictionUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponEmailRestriction>;
  public addCouponEmailRestrictions(
    body: CouponEmailsRestrictionUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCouponEmailRestriction>>;
  public addCouponEmailRestrictions(
    body: CouponEmailsRestrictionUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCouponEmailRestriction>>;
  public addCouponEmailRestrictions(
    body: CouponEmailsRestrictionUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addCouponEmailRestrictions.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListCouponEmailRestriction>(
      'post',
      `${this.basePath}/coupon/email-restrictions`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param couponId
   * @param file
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addEmailRestrictionsFromXlsxFileForm(
    couponId: number,
    file?: Blob,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponEmailRestriction>;
  public addEmailRestrictionsFromXlsxFileForm(
    couponId: number,
    file?: Blob,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCouponEmailRestriction>>;
  public addEmailRestrictionsFromXlsxFileForm(
    couponId: number,
    file?: Blob,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCouponEmailRestriction>>;
  public addEmailRestrictionsFromXlsxFileForm(
    couponId: number,
    file?: Blob,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (couponId === null || couponId === undefined) {
      throw new Error(
        'Required parameter couponId was null or undefined when calling addEmailRestrictionsFromXlsxFile.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec()
      });
    }

    if (file !== undefined) {
      formParams = (formParams.append('file', <any>file) as any) || formParams;
    }

    return this.httpClient.request<ResponseB4AListCouponEmailRestriction>(
      'post',
      `${this.basePath}/coupon/email-restrictions/${encodeURIComponent(
        String(couponId)
      )}`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addProductVariantToCoupon(
    body: CouponProductVariantCreateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ACouponProductVariant>;
  public addProductVariantToCoupon(
    body: CouponProductVariantCreateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ACouponProductVariant>>;
  public addProductVariantToCoupon(
    body: CouponProductVariantCreateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ACouponProductVariant>>;
  public addProductVariantToCoupon(
    body: CouponProductVariantCreateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling addProductVariantToCoupon.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ACouponProductVariant>(
      'post',
      `${this.basePath}/coupon/product-variant`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCoupon(
    body: CouponCreateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ACoupon>;
  public createCoupon(
    body: CouponCreateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ACoupon>>;
  public createCoupon(
    body: CouponCreateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ACoupon>>;
  public createCoupon(
    body: CouponCreateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling createCoupon.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ACoupon>(
      'post',
      `${this.basePath}/coupon/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param couponId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCouponById(
    couponId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ACoupon>;
  public findCouponById(
    couponId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ACoupon>>;
  public findCouponById(
    couponId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ACoupon>>;
  public findCouponById(
    couponId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (couponId === null || couponId === undefined) {
      throw new Error(
        'Required parameter couponId was null or undefined when calling findCouponById.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4ACoupon>(
      'get',
      `${this.basePath}/coupon/id/${encodeURIComponent(String(couponId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param couponId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCouponEmailRestrictions(
    couponId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponEmailRestrictionDetail>;
  public findCouponEmailRestrictions(
    couponId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCouponEmailRestrictionDetail>>;
  public findCouponEmailRestrictions(
    couponId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCouponEmailRestrictionDetail>>;
  public findCouponEmailRestrictions(
    couponId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (couponId === null || couponId === undefined) {
      throw new Error(
        'Required parameter couponId was null or undefined when calling findCouponEmailRestrictions.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCouponEmailRestrictionDetail>(
      'get',
      `${this.basePath}/coupon/email-restrictions/${encodeURIComponent(
        String(couponId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param couponId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCouponProductVariantSubscriptionTypeRestrictionByCouponId(
    couponId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponProductVariantSubscriptionTypeRestriction>;
  public findCouponProductVariantSubscriptionTypeRestrictionByCouponId(
    couponId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<ResponseB4AListCouponProductVariantSubscriptionTypeRestriction>
  >;
  public findCouponProductVariantSubscriptionTypeRestrictionByCouponId(
    couponId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<
    HttpEvent<ResponseB4AListCouponProductVariantSubscriptionTypeRestriction>
  >;
  public findCouponProductVariantSubscriptionTypeRestrictionByCouponId(
    couponId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (couponId === null || couponId === undefined) {
      throw new Error(
        'Required parameter couponId was null or undefined when calling findCouponProductVariantSubscriptionTypeRestrictionByCouponId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCouponProductVariantSubscriptionTypeRestriction>(
      'get',
      `${
        this.basePath
      }/coupon/product-subscription-type-restriction/${encodeURIComponent(
        String(couponId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param couponId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCouponProductVariants(
    couponId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponProductVariantSummary>;
  public findCouponProductVariants(
    couponId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCouponProductVariantSummary>>;
  public findCouponProductVariants(
    couponId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCouponProductVariantSummary>>;
  public findCouponProductVariants(
    couponId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (couponId === null || couponId === undefined) {
      throw new Error(
        'Required parameter couponId was null or undefined when calling findCouponProductVariants.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCouponProductVariantSummary>(
      'get',
      `${this.basePath}/coupon/product-variant/${encodeURIComponent(
        String(couponId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param couponUsageTypeId
   * @param editionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCouponUsageDetailsByEdition(
    couponUsageTypeId: number,
    editionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponUsageTypeByCouponName>;
  public findCouponUsageDetailsByEdition(
    couponUsageTypeId: number,
    editionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCouponUsageTypeByCouponName>>;
  public findCouponUsageDetailsByEdition(
    couponUsageTypeId: number,
    editionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCouponUsageTypeByCouponName>>;
  public findCouponUsageDetailsByEdition(
    couponUsageTypeId: number,
    editionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (couponUsageTypeId === null || couponUsageTypeId === undefined) {
      throw new Error(
        'Required parameter couponUsageTypeId was null or undefined when calling findCouponUsageDetailsByEdition.'
      );
    }

    if (editionId === null || editionId === undefined) {
      throw new Error(
        'Required parameter editionId was null or undefined when calling findCouponUsageDetailsByEdition.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCouponUsageTypeByCouponName>(
      'get',
      `${this.basePath}/coupon/usage/detail/${encodeURIComponent(
        String(couponUsageTypeId)
      )}/${encodeURIComponent(String(editionId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param editionId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCouponUsageReport(
    editionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponUsageSummary>;
  public findCouponUsageReport(
    editionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCouponUsageSummary>>;
  public findCouponUsageReport(
    editionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCouponUsageSummary>>;
  public findCouponUsageReport(
    editionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (editionId === null || editionId === undefined) {
      throw new Error(
        'Required parameter editionId was null or undefined when calling findCouponUsageReport.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCouponUsageSummary>(
      'get',
      `${this.basePath}/coupon/usage/report/${encodeURIComponent(
        String(editionId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findCouponsTable(
    body: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageCouponSummary>;
  public findCouponsTable(
    body: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageCouponSummary>>;
  public findCouponsTable(
    body: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageCouponSummary>>;
  public findCouponsTable(
    body: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling findCouponsTable.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageCouponSummary>(
      'put',
      `${this.basePath}/coupon/table`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param couponId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findProductVariantGroupRestrictions(
    couponId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponProductVariantGroupRestriction>;
  public findProductVariantGroupRestrictions(
    couponId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<ResponseB4AListCouponProductVariantGroupRestriction>
  >;
  public findProductVariantGroupRestrictions(
    couponId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCouponProductVariantGroupRestriction>>;
  public findProductVariantGroupRestrictions(
    couponId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (couponId === null || couponId === undefined) {
      throw new Error(
        'Required parameter couponId was null or undefined when calling findProductVariantGroupRestrictions.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCouponProductVariantGroupRestriction>(
      'get',
      `${
        this.basePath
      }/coupon/product-group-subscription-type-restriction/${encodeURIComponent(
        String(couponId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriberCouponsTable(
    body: PageableRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4APageSubscriberCouponSummary>;
  public findSubscriberCouponsTable(
    body: PageableRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4APageSubscriberCouponSummary>>;
  public findSubscriberCouponsTable(
    body: PageableRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4APageSubscriberCouponSummary>>;
  public findSubscriberCouponsTable(
    body: PageableRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling findSubscriberCouponsTable.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4APageSubscriberCouponSummary>(
      'put',
      `${this.basePath}/coupon/subscribers`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param couponId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findSubscriptionTypeRestrictionByCouponId(
    couponId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponSubscriptionTypeRestriction>;
  public findSubscriptionTypeRestrictionByCouponId(
    couponId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCouponSubscriptionTypeRestriction>>;
  public findSubscriptionTypeRestrictionByCouponId(
    couponId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCouponSubscriptionTypeRestriction>>;
  public findSubscriptionTypeRestrictionByCouponId(
    couponId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (couponId === null || couponId === undefined) {
      throw new Error(
        'Required parameter couponId was null or undefined when calling findSubscriptionTypeRestrictionByCouponId.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCouponSubscriptionTypeRestriction>(
      'get',
      `${
        this.basePath
      }/coupon/subscription-type-restriction/${encodeURIComponent(
        String(couponId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findUsageTypesList(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponUsageType>;
  public findUsageTypesList(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCouponUsageType>>;
  public findUsageTypesList(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCouponUsageType>>;
  public findUsageTypesList(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AListCouponUsageType>(
      'get',
      `${this.basePath}/coupon/usage-types`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeCouponEmailRestrictions(
    body: CouponEmailsRestrictionUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponEmailRestrictionDetail>;
  public removeCouponEmailRestrictions(
    body: CouponEmailsRestrictionUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListCouponEmailRestrictionDetail>>;
  public removeCouponEmailRestrictions(
    body: CouponEmailsRestrictionUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCouponEmailRestrictionDetail>>;
  public removeCouponEmailRestrictions(
    body: CouponEmailsRestrictionUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling removeCouponEmailRestrictions.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListCouponEmailRestrictionDetail>(
      'put',
      `${this.basePath}/coupon/email-restrictions/remove`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeProductVariantFromCoupon(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public removeProductVariantFromCoupon(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public removeProductVariantFromCoupon(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public removeProductVariantFromCoupon(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling removeProductVariantFromCoupon.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'delete',
      `${this.basePath}/coupon/product-variant/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCoupon(
    body: CouponUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ACoupon>;
  public updateCoupon(
    body: CouponUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ACoupon>>;
  public updateCoupon(
    body: CouponUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ACoupon>>;
  public updateCoupon(
    body: CouponUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateCoupon.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ACoupon>(
      'put',
      `${this.basePath}/coupon/`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCouponProductVariant(
    body: CouponProductVariant,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ACouponProductVariant>;
  public updateCouponProductVariant(
    body: CouponProductVariant,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ACouponProductVariant>>;
  public updateCouponProductVariant(
    body: CouponProductVariant,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ACouponProductVariant>>;
  public updateCouponProductVariant(
    body: CouponProductVariant,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateCouponProductVariant.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ACouponProductVariant>(
      'put',
      `${this.basePath}/coupon/product-variant`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateEnabledState(
    body: CouponEnabledUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ACoupon>;
  public updateEnabledState(
    body: CouponEnabledUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ACoupon>>;
  public updateEnabledState(
    body: CouponEnabledUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ACoupon>>;
  public updateEnabledState(
    body: CouponEnabledUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateEnabledState.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ACoupon>(
      'put',
      `${this.basePath}/coupon/enabled`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param couponId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProductVariantGroupRestrictions(
    couponId: number,
    body: Array<CouponProductVariantGroupRestriction>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponProductVariantGroupRestriction>;
  public updateProductVariantGroupRestrictions(
    couponId: number,
    body: Array<CouponProductVariantGroupRestriction>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<ResponseB4AListCouponProductVariantGroupRestriction>
  >;
  public updateProductVariantGroupRestrictions(
    couponId: number,
    body: Array<CouponProductVariantGroupRestriction>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListCouponProductVariantGroupRestriction>>;
  public updateProductVariantGroupRestrictions(
    couponId: number,
    body: Array<CouponProductVariantGroupRestriction>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateProductVariantGroupRestrictions.'
      );
    }

    if (couponId === null || couponId === undefined) {
      throw new Error(
        'Required parameter couponId was null or undefined when calling updateProductVariantGroupRestrictions.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListCouponProductVariantGroupRestriction>(
      'put',
      `${
        this.basePath
      }/coupon/product-group-subscription-type-restriction/${encodeURIComponent(
        String(couponId)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param couponId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProductVariantRestrictions(
    couponId: number,
    body: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListCouponProductVariantSubscriptionTypeRestriction>;
  public updateProductVariantRestrictions(
    couponId: number,
    body: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<ResponseB4AListCouponProductVariantSubscriptionTypeRestriction>
  >;
  public updateProductVariantRestrictions(
    couponId: number,
    body: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<
    HttpEvent<ResponseB4AListCouponProductVariantSubscriptionTypeRestriction>
  >;
  public updateProductVariantRestrictions(
    couponId: number,
    body: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateProductVariantRestrictions.'
      );
    }

    if (couponId === null || couponId === undefined) {
      throw new Error(
        'Required parameter couponId was null or undefined when calling updateProductVariantRestrictions.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListCouponProductVariantSubscriptionTypeRestriction>(
      'put',
      `${
        this.basePath
      }/coupon/product-subscription-type-restriction/${encodeURIComponent(
        String(couponId)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRestrictionMaxRedeems(
    body: CouponEmailRestrictionUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4ACouponEmailRestriction>;
  public updateRestrictionMaxRedeems(
    body: CouponEmailRestrictionUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4ACouponEmailRestriction>>;
  public updateRestrictionMaxRedeems(
    body: CouponEmailRestrictionUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4ACouponEmailRestriction>>;
  public updateRestrictionMaxRedeems(
    body: CouponEmailRestrictionUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateRestrictionMaxRedeems.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4ACouponEmailRestriction>(
      'put',
      `${this.basePath}/coupon/email-restrictions/update`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
