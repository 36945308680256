/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ArticleDetail {
  articleId?: number;
  websiteId?: number;
  unpublishDate?: Date;
  publishDate?: Date;
  dateUpdated?: Date;
  dateCreated?: Date;
  articleType?: number;
  articleTitle?: string;
  articleTags?: string;
  articleSummary?: string;
  articleKeywords?: string;
  articleImage?: string;
  articleDescription?: string;
  articleBody?: string;
  articleAuthor?: string;
  minutesTotal?: number;
  views?: number;
  likes?: number;
  active?: boolean;
}
