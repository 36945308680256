import {
  Component,
  OnDestroy,
  OnInit,
  WritableSignal,
  computed,
  inject,
  signal
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { lastValueFrom, map } from 'rxjs';
import {
  IntegrationControllerService,
  ShippingCompanyRangeDTO,
  WeightRangeServiceCompanyRange
} from 'src/app/admin-api';
import { ShippingService } from 'src/app/admin-api/model/shippingService';
import { TableColumn } from 'src/app/components/table';
import { ZipRange } from 'src/app/models/enums/ZipRange';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-update-shipping-company-activation',
  templateUrl: './update-shipping-company-activation.component.html',
  styleUrl: './update-shipping-company-activation.component.scss'
})
export class UpdateShippingCompanyActivationComponent
  implements OnInit, OnDestroy
{
  private integrationService: IntegrationControllerService = inject(
    IntegrationControllerService
  );
  private title = inject(Title);

  weightRangeServiceCompanyRange: WritableSignal<
    WeightRangeServiceCompanyRange[]
  > = signal(undefined);
  logger: WeightRangeServiceCompanyRange[];
  range: ShippingCompanyRangeDTO[];
  shippingService: ShippingService[];
  zip: number = null;

  selectedShippingService: number | null = null;
  selectedZipStartRange: number = null;
  selectedZipEndRange: number = null;
  selectedOperationType: string = null;
  selectedOperationTypeFilterType: string = null;

  cancelButtonDisabled: boolean = true;
  syncButtonDisabled: boolean = true;
  updateButtonDisabled: boolean = true;
  rollbackButtonDisabled: boolean = true;
  continueButtonDisabled: boolean = true;
  stopButtonDisabled: boolean = true;

  private weightRangeIntervalId: any;
  private loggerIntervalId: any;

  zipRangeOptions = [
    {
      label: '1000000 até 9999999',
      value: { start: ZipRange.ZERO_START, end: ZipRange.ZERO_END }
    },
    {
      label: '10000000 até 19999999',
      value: { start: ZipRange.ONE_START, end: ZipRange.ONE_END }
    },
    {
      label: '20000000 até 29999999',
      value: { start: ZipRange.TWO_START, end: ZipRange.TWO_END }
    },
    {
      label: '30000000 até 39999999',
      value: { start: ZipRange.THREE_START, end: ZipRange.THREE_END }
    },
    {
      label: '40000000 até 49999999',
      value: { start: ZipRange.FOUR_START, end: ZipRange.FOUR_END }
    },
    {
      label: '50000000 até 59999999',
      value: { start: ZipRange.FIVE_START, end: ZipRange.FIVE_END }
    },
    {
      label: '60000000 até 69999999',
      value: { start: ZipRange.SIX_START, end: ZipRange.SIX_END }
    },
    {
      label: '70000000 até 79999999',
      value: { start: ZipRange.SEVEN_START, end: ZipRange.SEVEN_END }
    },
    {
      label: '80000000 até 89999999',
      value: { start: ZipRange.EIGHT_START, end: ZipRange.EIGHT_END }
    },
    {
      label: '90000000 até 99999999',
      value: { start: ZipRange.NINE_START, end: ZipRange.NINE_END }
    }
  ];

  operationTypes = [
    { label: 'PRODUÇÃO', value: 'PRODUÇÃO' },
    { label: 'BACKUP', value: 'BACKUP' },
    { label: 'SIMULATION', value: 'SIMULATION' }
  ];

  synchronism = computed(() =>
    this.weightRangeServiceCompanyRange()?.some(
      (item) => item.status === 'PENDING'
    )
  );

  async ngOnInit(): Promise<void> {
    this.title.setTitle('Integrações - Atualização da tabela de frete de Loja');

    await this.fetch();

    this.weightRangeIntervalId = setInterval(() => {
      this.fetchUpdateWeightRange();
    }, 12000);

    this.loggerIntervalId = setInterval(() => {
      this.fetchUpdateLogger();
    }, 15000);
  }

  ngOnDestroy(): void {
    if (this.weightRangeIntervalId) {
      clearInterval(this.weightRangeIntervalId);
    }
    if (this.loggerIntervalId) {
      clearInterval(this.loggerIntervalId);
    }
  }

  cols: Array<TableColumn> = [
    new TableColumn('Menor preço', 'lowerShippingPrice', true, 'text'),
    new TableColumn('Preço médio', 'averageShippingPrice', true, 'text'),
    new TableColumn('Maior preço', 'greaterShippingPrice', true, 'text'),
    new TableColumn('Menor peso', 'lowerWeight', true, 'text'),
    new TableColumn('Peso médio', 'averageWeight', true, 'text'),
    new TableColumn('Maior peso', 'greaterWeight', true, 'text'),
    new TableColumn(
      'Total de linhas',
      'totalServiceCompanyRange',
      true,
      'number'
    ),
    new TableColumn('Responsável', 'responsible', true, 'text'),
    new TableColumn('Ambiente', 'environment', true, 'text'),
    new TableColumn('Data da atualização', 'dateCreated', true, 'date')
  ];

  colsRange: Array<TableColumn> = [
    new TableColumn('Transportadora', 'shippingCompanyName', true, 'text'),
    new TableColumn('Estado', 'state', true, 'text'),
    new TableColumn('CEP inicial', 'zipStart', true, 'number'),
    new TableColumn('CEP final', 'zipEnd', true, 'number'),
    new TableColumn('Menor peso (gramas)', 'minGrams', true, 'number'),
    new TableColumn('Maior peso (gramas)', 'maxGrams', true, 'number'),
    new TableColumn('Valor do frete', 'shippingPrice', true, 'text'),
    new TableColumn('Dias para entrega', 'daysToDelivery', true, 'number')
  ];

  async fetch(): Promise<void> {
    try {
      LoaderService.showLoader();
      this.shippingService = await lastValueFrom(
        this.integrationService
          .getShippingServices()
          .pipe(map((data) => data.result))
      );
      this.weightRangeServiceCompanyRange.set(
        await lastValueFrom(
          this.integrationService
            .findAllWeightRangeServiceCompanyRange()
            .pipe(map((data) => data.result))
        )
      );
      await this.buttonDisabled();
      this.logger = await lastValueFrom(
        this.integrationService
          .findLoggerUpdateServiceCompanyRange()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async fetchUpdateWeightRange(): Promise<void> {
    try {
      this.weightRangeServiceCompanyRange.set(
        await lastValueFrom(
          this.integrationService
            .findAllWeightRangeServiceCompanyRange()
            .pipe(map((data) => data.result))
        )
      );
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async fetchUpdateLogger(): Promise<void> {
    try {
      this.logger = await lastValueFrom(
        this.integrationService
          .findLoggerUpdateServiceCompanyRange()
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async cancelUpdate(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(
        this.integrationService.abortUpdateServiceCompanyRange()
      );
      await this.fetchUpdateWeightRange();
      await this.fetchUpdateLogger();
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async stopUpdate(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(
        this.integrationService.stopUpdateServiceCompanyRange()
      );
      await this.fetchUpdateWeightRange();
      await this.fetchUpdateLogger();
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async continueUpdate(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(
        this.integrationService.continueSyncServiceCompanyRange()
      );
      await this.fetchUpdateWeightRange();
      await this.fetchUpdateLogger();
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async syncUpdateServiceCompanyRange(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(this.integrationService.syncServiceCompanyRange());
      await this.fetchUpdateWeightRange();
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async updateServiceCompanyRange(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(this.integrationService.updateServiceCompanyRange());
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async rollbackServiceCompanyRange(): Promise<void> {
    try {
      LoaderService.showLoader();
      await lastValueFrom(this.integrationService.rollbackServiceCompany());
      await this.buttonDisabled();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async rollbackIsValid(): Promise<void> {
    try {
      this.rollbackButtonDisabled = !(await lastValueFrom(
        this.integrationService
          .rollbackIsValidServiceCompany()
          .pipe(map((data) => data.result))
      ));
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    }
  }

  async buttonDisabled() {
    await this.rollbackIsValid();

    this.cancelButtonDisabled = this.weightRangeServiceCompanyRange()?.every(
      (obj) =>
        obj.status === 'ABORT' ||
        obj.status === 'FINISH' ||
        obj.status === 'NOT_START' ||
        obj.status === 'FAIL'
    );

    this.continueButtonDisabled = !this.weightRangeServiceCompanyRange()?.some(
      (obj) => obj.status === 'STOP'
    );

    this.stopButtonDisabled = !this.weightRangeServiceCompanyRange()?.some(
      (obj) => obj.status === 'PENDING'
    );

    this.updateButtonDisabled = !this.weightRangeServiceCompanyRange()?.every(
      (obj) => obj.status === 'FINISH' && obj.inProduction === 0
    );

    this.syncButtonDisabled = this.weightRangeServiceCompanyRange()?.some(
      (obj) => obj.status === 'PENDING'
    );
    if (this.syncButtonDisabled) {
      return;
    }
    this.syncButtonDisabled = !this.weightRangeServiceCompanyRange()?.every(
      (obj) =>
        obj.status === 'ABORT' ||
        obj.status === 'FINISH' ||
        obj.status === 'FAIL'
    );
  }

  getGridClass(status: string, inProduction: number): string {
    switch (status) {
      case 'PENDING':
        return 'pending';
      case 'FAIL':
        return 'fail';
      case 'STOP':
        return 'stop';
      case 'ABORT':
        return 'abort';
      case 'FINISH':
        return inProduction === 0 ? 'finish' : 'finish_in_production';
      case 'NOT_START':
        return 'not_start';
      default:
        return '';
    }
  }

  getDescription(status: string, inProduction: number): string {
    switch (status) {
      case 'PENDING':
        return 'Pendente';
      case 'FAIL':
        return 'Falha';
      case 'STOP':
        return 'Sincronismo pausado';
      case 'ABORT':
        return 'Cancelado';
      case 'FINISH':
        return inProduction === 0
          ? 'Finalizado e Pendente de envio para Produção'
          : 'Finalizado e em Produção';
      case 'NOT_START':
        return 'Não iniciado';
      default:
        return '';
    }
  }

  async onShippingServiceChange(event: any) {
    this.selectedShippingService = event.value?.shippingServiceId;
    await this.checkAndFilterRange();
  }

  async onZipRangeChange(event: any) {
    this.selectedZipStartRange = event.value?.value?.start;
    this.selectedZipEndRange = event.value?.value?.end;
    await this.checkAndFilterRange();
  }

  async onOperationTypeChange(event: any) {
    this.selectedOperationType = event.value?.value;
    await this.checkAndFilterRange();
  }

  async checkAndFilterRange() {
    if (
      this.selectedShippingService &&
      this.selectedZipStartRange &&
      this.selectedZipEndRange &&
      this.selectedOperationType
    ) {
      try {
        LoaderService.showLoader();
        this.range = await lastValueFrom(
          this.integrationService
            .getServiceCompanyRanges(
              this.selectedShippingService,
              this.selectedZipStartRange,
              this.selectedZipEndRange,
              this.selectedOperationType
            )
            .pipe(map((data) => data.result))
        );
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    }
  }

  async onZipChange(value: number) {
    this.zip = value;
    if (
      value &&
      (value.toString().length === 7 || value.toString().length === 8) &&
      this.selectedOperationTypeFilterType
    ) {
      await this.lookupZip();
    }
  }

  async onOperationTypeFilterTypeChange(event: any) {
    this.selectedOperationTypeFilterType = event.value?.value;
    if (this.zip && this.selectedOperationTypeFilterType) {
      await this.lookupZip();
    }
  }

  async lookupZip() {
    try {
      LoaderService.showLoader();
      this.range = await lastValueFrom(
        this.integrationService
          .getServiceCompanyRangesByZip(
            this.zip,
            this.selectedOperationTypeFilterType
          )
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }
}
