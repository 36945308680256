import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TableModalModule } from 'src/app/components/table-modal/table-modal.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { EditionImageModule } from 'src/app/edition-image/edition-image.module';
import { StockMovementsComponent } from 'src/app/pages/operations/stock-movements/stock-movements.component';
import { AllocationComponent } from './allocation/allocation.component';
import { CompositionProductionModalComponent } from './composition-productions/composition-production-modal/composition-production-modal.component';
import { CompositionProductionComponent } from './composition-productions/composition-production/composition-production.component';
import { CompositionProductionsComponent } from './composition-productions/composition-productions.component';
import { CompositionsBeautyProfileComponent } from './compositions-beauty-profile/compositions-beauty-profile.component';
import { CompositionDetailComponent } from './compositions/composition-detail/composition-detail.component';
import { CompositionsComponent } from './compositions/compositions.component';
import { EditionsDashboardComponent } from './editions-dashboard/editions-dashboard.component';
import { PendingPreparationComponent } from './editions-dashboard/pending-preparation/pending-preparation.component';
import { OperationsRoutingModule } from './operations-routing.module';

@NgModule({
  declarations: [
    AllocationComponent,
    CompositionsComponent,
    CompositionsBeautyProfileComponent,
    CompositionDetailComponent,
    EditionsDashboardComponent,
    CompositionProductionComponent,
    CompositionProductionsComponent,
    CompositionProductionModalComponent,
    StockMovementsComponent,
    PendingPreparationComponent,
    StockMovementsComponent
  ],
  imports: [
    CommonModule,
    OperationsRoutingModule,
    AppTableModule,
    DynamicDialogModule,
    MultiSelectModule,
    FormsModule,
    ButtonModule,
    ToastModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    DropdownModule,
    EditionImageModule,
    ConfirmPopupModule,
    InputTextModule,
    InputTextareaModule,
    TabViewModule,
    TableModule,
    TableModalModule,
    InputNumberModule,
    ChipModule,
    CalendarModule,
    NgxPermissionsModule.forChild()
  ]
})
export class OperationsModule {}
