import { Component, inject, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ShopCollection, ShopSection, ShopSettings } from '@infrab4a/connect';
import { ShopSettingsService } from 'src/app/connect-api/api/shop-settings/shop-settings.service';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { GlamHomeComponent } from '../glam-home.component';

export enum colorsEnum {
  'ROSA' = '#FE357B',
  'PRETO' = '#343434'
}

@Component({
  selector: 'app-glam-home-anuncy-form',
  templateUrl: './glam-home-anuncy-form.component.html',
  styleUrl: './glam-home-anuncy-form.component.scss'
})
export class GlamHomeAnuncyFormComponent implements OnInit {
  @Input({ required: true }) id: string;

  settings: Partial<ShopSettings> & { sections?: ShopSection[] };
  private shopSettingsService = inject(ShopSettingsService);
  collection: any;

  form = new FormGroup({
    published: new FormControl<boolean>(false),
    id: new FormControl<string>(
      this.shopSettingsService.createId(),
      Validators.required
    ),
    title: new FormControl<string>('Faixa de anuncio'),
    type: new FormControl<string>('anuncy', Validators.required),
    fixed: new FormControl(true),
    editable: new FormControl(true),
    infobars: new FormArray([])
  });

  defaultColors = [
    { label: 'Rosa', value: '#FE357B' },
    { label: 'Preto', value: '#343434' },
    { label: 'Personalizado', value: 'custom' }
  ];

  constructor(private router: Router) {}

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    try {
      this.settings = await this.shopSettingsService.getById(
        GlamHomeComponent.documentId
      );
      if (this.id && this.id !== 'new') {
        this.collection = this.settings.sections.find((s) => s.id === this.id);
        if (!this.collection) throw { message: 'Anuncio não encontrado' };
        this.form.patchValue(this.collection);
        this.collection.infobars.map((infobar) => this.addItem(infobar));
        this.form.controls.id.enable();
      }
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  async submit(): Promise<void> {
    if (this.form.valid) {
      try {
        LoaderService.showLoader();
        const collection = this.form.value as ShopCollection;
        const sections = this.settings.sections as ShopSection[];
        if (this.collection) {
          collection.updatedAt = new Date();
          const ref: ShopCollection = sections.find(
            (s) => s.id === this.collection.id
          ) as ShopCollection;
          if (!ref) throw { message: 'Coleção não encontrada' };
          Object.keys(this.form.value).forEach(
            (key) => (ref[key] = collection[key])
          );
        } else {
          collection.createdAt = new Date();
          this.settings.sections = [
            { ...collection },
            ...sections.filter((s) => s.type !== 'newsletter'),
            ...sections.filter((s) => s.type === 'newsletter')
          ];
        }
        await this.shopSettingsService.update(this.settings);
        this.router.navigate(['/configuracoes/glam/home']);
      } catch (error) {
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
    }
  }

  addItem(infobar: any): void {
    const newItem = new FormGroup({
      text: new FormControl(infobar?.text, [
        Validators.maxLength(40),
        Validators.required
      ]),
      color: new FormControl(infobar?.color, Validators.required),
      customColor: new FormControl(infobar?.customColor),
      link: new FormControl(infobar?.link)
    });
    this.infobars.push(newItem);
  }

  removeItem(index: number): void {
    this.infobars.removeAt(index);
  }

  showColorPicker(i: number): boolean {
    return (
      (this.form.get('infobars') as FormArray).at(i).get('color')?.value ===
      'custom'
    );
  }

  get infobars(): FormArray {
    return this.form.get('infobars') as FormArray;
  }
}
