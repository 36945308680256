/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CompositionProductVariantDetail } from './compositionProductVariantDetail';

export interface CompositionDetails {
  compositionId?: number;
  compositionName?: string;
  costTotal?: number;
  saleTotal?: number;
  invoiceTotal?: number;
  subscriberCount?: number;
  productCount?: number;
  weight?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  dateSyncERP?: Date;
  editionId?: number;
  glamclubTradeCount?: number;
  pendingPreparation?: number;
  products?: Array<CompositionProductVariantDetail>;
}
