import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ContentFormComponent } from './content-form/content-form.component';
import { CourseFormComponent } from './course-form.component';
import { ModuleFormComponent } from './module-form/module-form.component';

@NgModule({
  declarations: [
    CourseFormComponent,
    ModuleFormComponent,
    ContentFormComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    ButtonModule,
    TooltipModule,
    EditorModule,
    ReactiveFormsModule,
    InputTextModule,
    FileUploadModule,
    CheckboxModule,
    InputSwitchModule,
    InputNumberModule
  ],
  exports: [CourseFormComponent, ModuleFormComponent, ContentFormComponent]
})
export class CourseFormModule {}
