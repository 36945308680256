import { Inject, Injectable } from '@angular/core';
import { Coupon, CouponRepository } from '@infrab4a/connect';
import { ConnectRequest } from 'src/app/models';
import { BaseConnectService } from './base-connect.service';

@Injectable({
  providedIn: 'root'
})
export class ShopCouponService extends BaseConnectService<Coupon> {
  constructor(
    @Inject('CouponRepository')
    private couponRepository: CouponRepository
  ) {
    super(couponRepository);
  }

  async getCouponById(id: string): Promise<Coupon> {
    return this.couponRepository.get({ id });
  }

  async getCouponsList(request: Partial<ConnectRequest>) {
    const result = await this.paginatedSearch(request);
    const content = result.data.map((category) => ({ ...category }));
    const totalPages = request.pageSize
      ? Math.floor(result.count / request.pageSize)
      : undefined;
    const totalElements = result.count;

    return {
      totalPages,
      totalElements,
      content,
      empty: result.count === 0
    };
  }

  async saveCoupon(coupon: Partial<Coupon>) {
    return this.couponRepository.create(Coupon.toInstance(coupon));
  }

  async updateCoupon(coupon: Partial<Coupon>) {
    return this.couponRepository.update(Coupon.toInstance(coupon));
  }

  async deleteCoupon(couponId: string): Promise<void> {
    return await this.couponRepository.delete({ id: couponId });
  }

  override get scheme(): 'firebase' | 'hasura' {
    return 'hasura';
  }
}
