/* eslint-disable @typescript-eslint/no-explicit-any */
import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe, TitleCasePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  computed,
  inject
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom, map } from 'rxjs';
import {
  CreditCardRecurrenceWithCard,
  FinancialControllerService,
  InfluencerControllerService,
  InfluencerDetail,
  OpenFinancePayerInstitution,
  PaymentControllerService,
  PersonControllerService,
  PersonDetail,
  SubscriberAddressChangeTable,
  SubscriberBillingHistoryEdition,
  SubscriberCancellationDetail,
  SubscriberCancellationReason,
  SubscriberControllerService,
  SubscriberCouponDetail,
  SubscriberEditionCompositionSummary,
  SubscriberInfo,
  SubscriberPaymentHistory,
  SubscriberRefund,
  SubscriberRenewalInfo,
  SubscriberStatus,
  VoucherSubscriberBilling
} from 'src/app/admin-api';
import { DisableRecurrenceModalComponent } from 'src/app/components/disable-recurrence-modal/disable-recurrence-modal.component';
import { EditionChargebackModalComponent } from 'src/app/components/edition-chargeback-modal/edition-chargeback-modal.component';
import { PaymentDetailsModalComponent } from 'src/app/components/payment-details-modal/payment-details-modal.component';
import { SubscriberCancellationModalComponent } from 'src/app/components/subscriber-cancellation-modal/subscriber-cancellation-modal.component';
import { TableActionButton, TableColumn } from 'src/app/components/table';
import {
  AvailableBilling,
  EditionId,
  PaymentHistory,
  Role,
  SubscriberBillingStatusEnum,
  SubscriptionEnum,
  TransactionStatusEnum,
  getSubscriptionName,
  roleAsObject
} from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { FormUtil } from 'src/app/utils/form.util';

@Component({
  selector: 'app-subscriber-details',
  templateUrl: './subscriber-details.component.html',
  styleUrls: ['./subscriber-details.component.scss'],
  providers: [
    DatePipe,
    DialogService,
    MessageService,
    ConfirmationService,
    TitleCasePipe
  ],
  encapsulation: ViewEncapsulation.None
})
export class SubscriberDetailsComponent implements OnInit {
  @ViewChild(Dropdown)
  dropdown: Dropdown | undefined;

  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private subscriberService: SubscriberControllerService = inject(
    SubscriberControllerService
  );
  private router: Router = inject(Router);
  private personService: PersonControllerService = inject(
    PersonControllerService
  );
  private datePipe: DatePipe = inject(DatePipe);
  private title: Title = inject(Title);
  private messageService: MessageService = inject(MessageService);
  private dialog: DialogService = inject(DialogService);
  private paymentService: PaymentControllerService = inject(
    PaymentControllerService
  );
  private influencerService: InfluencerControllerService = inject(
    InfluencerControllerService
  );
  private clipboard: Clipboard = inject(Clipboard);
  private confirmationService: ConfirmationService =
    inject(ConfirmationService);
  private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private financialService: FinancialControllerService = inject(
    FinancialControllerService
  );
  private ngxPermissionsService: NgxPermissionsService = inject(
    NgxPermissionsService
  );

  person: PersonDetail | undefined;
  subscriber: SubscriberInfo | undefined;
  creditCard: CreditCardRecurrenceWithCard | undefined;
  paymentHistory: PaymentHistory[] | undefined;
  selectedTab = 0;
  isMobile = false;
  isDesktop = false;
  compositions: Array<SubscriberEditionCompositionSummary> | undefined;
  paymentCols = computed(() =>
    [
      new TableColumn.Builder()
        .setHeader('Parc.')
        .setField('installments')
        .setFilter(false)
        .setType('number')
        .build(),
      new TableColumn.Builder()
        .setHeader('TID')
        .setFilter(false)
        .setField('transactionId')
        .build(),
      new TableColumn.Builder()
        .setHeader('Gateway')
        .setField('gatewayName')
        .setFilter(false)
        .build(),

      new TableColumn.Builder()
        .setHeader('Instituição de pagamento')
        .setField('payerInstitution')
        .setFilter(false)
        .setStyleClass('institution')
        .setInnerHTML((payment: SubscriberPaymentHistory) =>
          this.payerInstitutionImg(payment)
        )
        .setRouterLink(
          (payment: SubscriberPaymentHistory) =>
            this.payerInstitution(payment)?.website
        )
        .setSortable(false)
        .build(),
      new TableColumn.Builder()
        .setHeader('Data')
        .setField('dateCreated')
        .setType('date')
        .setFilter(false)
        .build(),
      new TableColumn.Builder()
        .setHeader('Valor')
        .setField('amount')
        .setType('currency')
        .setFilter(false)
        .build(),
      new TableColumn.Builder()
        .setHeader('Status')
        .setField('transactionStatusName')
        .setType('status')
        .setFilter(false)
        .setStatusFunction((payment: SubscriberPaymentHistory) =>
          this.paymentAuthorized(payment)
        )
        .build(),
      new TableColumn.Builder()
        .setHeader('Tipo')
        .setField('paymentTypeName')
        .setFilter(false)
        .build(),
      new TableColumn.Builder()
        .setHeader('Detalhe')
        .setField('authorizationMessage')
        .setFilter(false)
        .build(),
      new TableColumn.Builder()
        .setHeader('Ação')
        .setField(' ')
        .setFilter(false)
        .setType('button')
        .build()
    ].filter(
      (c) =>
        !['payerInstitution', ' '].includes(c.field) ||
        (this.paymentHistory?.some((p) => p.payerInstitution) &&
          c.field === 'payerInstitution') ||
        this.paymentHistory?.some(
          (p) =>
            p.nextStep &&
            [
              TransactionStatusEnum.CRIADA,
              TransactionStatusEnum.EM_ANDAMENTO
            ].includes(p.transactionStatusId)
        )
    )
  );

  billingCols: Array<TableColumn> = [
    new TableColumn.Builder()
      .setHeader('Id cobrança')
      .setField('subscriberBillingId')
      .setFilter(false)
      .setType('number')
      .build(),
    new TableColumn.Builder()
      .setHeader('Data criação')
      .setField('dateCreated')
      .setType('date')
      .setFilter(false)
      .build(),
    new TableColumn.Builder()
      .setHeader('Data atualização')
      .setField('dateUpdated')
      .setFilter(false)
      .setType('date')
      .build(),
    new TableColumn.Builder()
      .setHeader('Valor')
      .setField('amount')
      .setFilter(false)
      .setType('currency')
      .build(),
    new TableColumn.Builder()
      .setHeader('Edição')
      .setField('editionName')
      .setFilter(false)
      .setType('text')
      .build(),
    new TableColumn.Builder()
      .setHeader('Status')
      .setField('subscriberBillingStatusName')
      .setFilter(false)
      .setType('status')
      .setStatusFunction(this.billingStatusPaid)
      .build()
  ];
  cancellationReasons: Array<SubscriberCancellationReason> | undefined;
  changeStatus = false;
  statusList: Array<SubscriberStatus> | undefined;
  subscriberStatus = new FormControl(0, Validators.required);
  renewals: Array<SubscriberRenewalInfo> | undefined;
  renewalsCols: Array<TableColumn> = [
    new TableColumn('Ação', 'action', false, 'text'),
    new TableColumn('Status', 'status', false, 'text'),
    new TableColumn(
      'Cupom',
      'coupon',
      false,
      'text',
      '/marketing/coupons/',
      'couponId'
    ),
    new TableColumn('Valor', 'totalAmount', false, 'currency'),
    new TableColumn('Desconto', 'discountValue', false, 'currency'),
    new TableColumn('Transição', 'transition', false, 'text'),
    new TableColumn('Data agendamendo', 'dateCreated', false, 'date'),
    new TableColumn('Data reativação', 'dateRenewed', false, 'date')
  ];
  addressChanges: Array<SubscriberAddressChangeTable> | undefined;
  addressChangeCols: Array<TableColumn> = [
    new TableColumn('Data mudança', 'dateChanged', false, 'date'),
    new TableColumn('Pagamento', 'paymentId', false, 'link'),
    new TableColumn('Endereço antigo', 'oldAddress', false, 'text'),
    new TableColumn('Novo endereço', 'newAddress', false, 'text')
  ];
  influencer: InfluencerDetail | undefined;
  refunds: Array<SubscriberRefund & { edition?: string }> | undefined;
  refundCols: Array<TableColumn> = [
    new TableColumn('Edição', 'edition', false, 'text'),
    new TableColumn('Valor', 'amount', false, 'currency'),
    new TableColumn('Data criação', 'dateCreated', false, 'date'),
    new TableColumn('Aprovado', 'hasBeenApproved', false, 'boolean'),
    new TableColumn('Estornado', 'hasRefunded', false, 'boolean'),
    new TableColumn('Data estorno', 'dateRefunded', false, 'date')
  ];
  refundButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'removeRefund',
      PrimeIcons.TRASH,
      this.canRemoveRefund,
      '',
      undefined,
      undefined,
      false,
      true,
      'danger',
      'small'
    )
  ];
  coupons: Array<SubscriberCouponDetail> | undefined;
  couponCols: Array<TableColumn> = [
    new TableColumn('Identificador', 'couponId', false, 'text'),
    new TableColumn(
      'Convite',
      'inviter',
      false,
      'text',
      '/users/person/',
      'inviterPersonid'
    ),
    new TableColumn(
      'Nome do cupom',
      'couponName',
      false,
      'text',
      '/marketing/coupons/',
      'couponId'
    ),
    new TableColumn('Valor', 'value', false, 'text'),
    new TableColumn('Frete grátis', 'freeShipping', false, 'boolean'),
    new TableColumn('Data de utilização', 'dateCreated', false, 'date')
  ];
  cancellations: Array<SubscriberCancellationDetail> | undefined;
  cancellationCols: Array<TableColumn> = [
    new TableColumn('Cancelado por', 'cancelledBy', false, 'text'),
    new TableColumn(
      'Motivo',
      'subscriberCancellationReasonName',
      false,
      'text'
    ),
    new TableColumn('Observação', 'cancellationReason', false, 'text'),
    new TableColumn('Edição cancelada', 'editionCancelled', false, 'text'),
    new TableColumn('Multa', 'fee', false, 'currency'),
    new TableColumn('Estorno', 'refundAmount', false, 'currency'),
    new TableColumn('A cobrar', 'chargeAmount', false, 'currency'),
    new TableColumn('Data criação', 'dateCancelled', false, 'date'),
    new TableColumn.Builder()
      .setHeader('Data cancelamento')
      .setField('dateExecuted')
      .setFilter(false)
      .setDisplayFunction((c: SubscriberCancellationDetail) =>
        this.datePipe.transform(
          new Date(
            c.dateExecuted ||
              (c.cancellationReference
                ? this.referenceAsDate(c.cancellationReference)
                : c.dateCancelled)
          ),
          'dd/MM/yyyy HH:mm:ss'
        )
      )
      .setType('date')
      .build()
  ];
  cardCols: Array<TableColumn> = [
    new TableColumn('Id', 'creditCardId', false, 'number'),
    new TableColumn('Bandeira', 'flag', false, 'image'),
    new TableColumn('Final', 'lastDigits', false, 'text'),
    new TableColumn('Nome', 'nameOnCard', false, 'text'),
    new TableColumn('Ação', '', false, 'button')
  ];
  cardButtons: Array<TableActionButton> = [
    new TableActionButton(
      '',
      'confirmDeleteCard',
      PrimeIcons.TRASH,
      this.canRemoveCard,
      '',
      undefined,
      undefined,
      true,
      true,
      'danger'
    )
  ];
  pageReady = false;
  voucherBillings: Array<VoucherSubscriberBilling> | undefined;
  voucherCols: Array<TableColumn> = [
    new TableColumn('Id', 'subscriberBillingId', false, 'number'),
    new TableColumn('VoucherId', 'voucherId', false, 'number'),
    new TableColumn('Voucher', 'number', false, 'text'),
    new TableColumn('Provider', 'voucherProviderName', false, 'text'),
    new TableColumn('Plano', 'subscriptionTypeName', false, 'text'),
    new TableColumn('Edição', 'editionId', false, 'number'),
    new TableColumn('Valor', 'amount', false, 'currency'),
    new TableColumn('Data criação', 'dateCreated', false, 'date'),
    new TableColumn('Data atualização', 'dateUpdated', false, 'date'),
    new TableColumn(
      'Status',
      'subscriberBillingStatus',
      false,
      'status',
      undefined,
      undefined,
      true,
      'equals',
      undefined,
      this.billingStatusPaid
    )
  ];
  acceptedPermissions = [
    roleAsObject(Role.Full_Administrator).enumValue,
    roleAsObject(Role.Financial).enumValue
  ];
  permission = false;
  billingsToChargeback: AvailableBilling[];
  cancellationOpen = false;
  actionButtons = [
    new TableActionButton.Builder()
      .setIcon(PrimeIcons.COPY)
      .setAction((payment: SubscriberPaymentHistory) =>
        this.copyToClipboard(payment.nextStep)
      )
      .setSeverity('primary')
      .setSize('small')
      .setStyleClass('p-button-xs')
      .setText(true)
      .setTooltip('Copiar link de pagamento')
      .setTooltipPosition('bottom')
      .setDisplay(
        (payment: SubscriberPaymentHistory) =>
          !!payment.nextStep?.trim() &&
          [
            TransactionStatusEnum.EM_ANDAMENTO,
            TransactionStatusEnum.CRIADA
          ].includes(payment.transactionStatusId)
      )
      .build()
  ];
  institutions: OpenFinancePayerInstitution[];

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkScreenSize();
  }

  ngOnInit(): void {
    this.checkScreenSize();
    this.activatedRoute.params.subscribe(
      async (params: { [x: string]: string }) => {
        this.permission = await this.ngxPermissionsService.hasPermission(
          this.acceptedPermissions
        );
        if (this.permission)
          this.refundCols.push(new TableColumn('Ação', '', false, 'button'));
        if (params['subscriberId']) {
          LoaderService.showLoader();
          try {
            this.subscriber = await lastValueFrom(
              this.subscriberService
                .findSubscriberInfoById(Number(params['subscriberId']))
                .pipe(map((data) => data.result))
            );
            this.subscriberStatus.setValue(
              this.subscriber?.subscriberStatus as number
            );
            if (this.subscriber) {
              await Promise.all([
                this.findPerson(),
                this.findCompositions(),
                this.findCreditCard(),
                this.findPayments(),
                this.findSubscriberStatusList(),
                this.findSubscriberRenewals(),
                this.findAddressChanges(),
                this.findInfluencerInfo(),
                this.findSubscriberRefunds(),
                this.findSubscriberCoupons(),
                this.findSubscriberCancellations(),
                this.findVoucherSubscriberBillings(),
                this.findOpenFinanceInstitutions()
              ]);
            }
            this.pageReady = true;
            this.title.setTitle(
              `${this.subscriber?.boxId} ${this.person?.name} ${this.person?.lastName}`
            );
            this.findBillingsToChargeback();
          } catch (error: any) {
            AppDialogService.showErrorDialog(
              error,
              true,
              'Assinatura não encontrada.'
            );
          }
          LoaderService.showLoader(false);
        } else {
          AppDialogService.showErrorDialog(
            {
              message: 'Assinatura não encontrada'
            },
            true
          );
        }
      }
    );
    this.activatedRoute.queryParams.subscribe(
      (queryParams: { [x: string]: string }) => {
        if (queryParams['tab']) {
          this.selectedTab = Number(queryParams['tab']) || 0;
        } else {
          this.selectedTab = 0;
        }
      }
    );
  }

  checkScreenSize(): void {
    this.isMobile = window.innerWidth < 768;
    this.isDesktop = window.innerWidth > 1080;
  }

  tabChanged($event: number): void {
    this.router.navigate(
      [`users/subscribers/${this.subscriber?.subscriberId}`],
      {
        queryParams: { tab: $event },
        queryParamsHandling: 'merge'
      }
    );
  }

  async findPerson(): Promise<void> {
    try {
      this.person = await lastValueFrom(
        this.personService
          .findPersonInfoById(this.subscriber?.personId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      console.error(error);
    }
  }

  async findCompositions(): Promise<void> {
    try {
      this.compositions = await lastValueFrom(
        this.subscriberService
          .findSubscriberCompositions(this.subscriber?.subscriberId as number)
          .pipe(
            map((data) => {
              data.result?.sort((s1, s2) => {
                if (s1.editionId % 1000000 < s2.editionId % 1000000) {
                  return 1;
                } else if (s1.editionId % 1000000 > s2.editionId % 1000000) {
                  return -1;
                }
                return (
                  new Date(s2.dateCreated as any).getTime() -
                  new Date(s1.dateCreated as any).getTime()
                );
              });
              return data.result;
            })
          )
      );
    } catch (error: any) {
      console.error(error);
      this.compositions = [];
    }
  }

  async findCreditCard(): Promise<void> {
    try {
      this.creditCard = await lastValueFrom(
        this.financialService
          .findCreditCardBySubscriberId(this.subscriber?.subscriberId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      AppDialogService.showErrorDialog(error);
      delete this.creditCard;
    }
  }

  async findPayments(): Promise<void> {
    try {
      this.paymentHistory = await lastValueFrom(
        this.subscriberService
          .findSubscriberPayments(this.subscriber?.subscriberId as number)
          .pipe(
            map((data) => {
              data.result?.forEach((ph) =>
                ph.billings?.forEach(
                  (
                    b: SubscriberBillingHistoryEdition & {
                      subscriberBillingStatusName?: string;
                      editionName?: string;
                    }
                  ) => {
                    b.subscriberBillingStatusName =
                      SubscriberBillingStatusEnum[
                        b.subscriberBillingStatus as number
                      ];
                    b.editionName = this.editionDateName(b.editionId);
                  }
                )
              );
              return data.result;
            })
          )
      );
    } catch (error: any) {
      console.error(error);
      this.paymentHistory = [];
    }
  }

  async findSubscriberStatusList(): Promise<void> {
    try {
      this.statusList = await lastValueFrom(
        this.subscriberService
          .findSubscriberStatusList()
          .pipe(
            map((data) =>
              data.result.filter((s) => s.subscriberStatusId !== 10)
            )
          )
      );
    } catch (error: any) {
      console.error(error);
      this.statusList = [];
    }
  }

  async findSubscriberRenewals(): Promise<void> {
    try {
      this.renewals = await lastValueFrom(
        this.subscriberService
          .findSubscriberRenewals(this.subscriber?.subscriberId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      console.error(error);
      this.renewals = [];
    }
  }

  async findAddressChanges(): Promise<void> {
    try {
      this.addressChanges = await lastValueFrom(
        this.subscriberService
          .findSubscriberAddressChangeHistory(
            this.subscriber?.subscriberId as number
          )
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      console.error(error);
      this.addressChanges = [];
    }
  }

  async findInfluencerInfo(): Promise<void> {
    try {
      this.influencer = await lastValueFrom(
        this.influencerService
          .findInfluencerDetails(this.subscriber?.personId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      delete this.influencer;
    }
  }

  async findSubscriberRefunds(): Promise<void> {
    try {
      const refunds = await lastValueFrom(
        this.subscriberService
          .findSubscriberRefunds(this.subscriber?.subscriberId as number)
          .pipe(map((data) => data.result))
      );
      delete this.refunds;
      setTimeout(() => {
        this.refunds =
          refunds?.map((r) => ({
            ...r,
            edition: this.editionString(r.editionId as number)
          })) || [];
      });
    } catch (error: any) {
      this.refunds = [];
    }
  }

  async findSubscriberCoupons(): Promise<void> {
    try {
      this.coupons = await lastValueFrom(
        this.subscriberService
          .findSubscriberCoupons(this.subscriber?.subscriberId as number)
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      this.coupons = [];
    }
  }

  async findSubscriberCancellations(): Promise<void> {
    try {
      const cancellations = await lastValueFrom(
        this.subscriberService
          .findSubscriberCancellations(this.subscriber?.subscriberId as number)
          .pipe(map((data) => data.result))
      );
      delete this.cancellations;
      setTimeout(() => {
        this.cancellations = cancellations;
      });
    } catch (error: any) {
      this.cancellations = [];
    }
  }

  async findVoucherSubscriberBillings(): Promise<void> {
    try {
      this.voucherBillings = await lastValueFrom(
        this.subscriberService
          .findVoucherSubscriberBillings(
            this.subscriber?.subscriberId as number
          )
          .pipe(
            map((data) => {
              data.result?.forEach(
                (b) =>
                  (b.subscriberBillingStatus =
                    SubscriberBillingStatusEnum[
                      b.subscriberBillingStatusId as number
                    ])
              );
              return data.result;
            })
          )
      );
    } catch (error: any) {
      this.voucherBillings = [];
    }
  }

  async findOpenFinanceInstitutions(): Promise<void> {
    try {
      this.institutions = await lastValueFrom(
        this.financialService
          .findOpenFinanceInstitutions()
          .pipe(map((data) => data.result))
      );
    } catch (error: any) {
      this.institutions = [];
    }
  }

  editionString(edition: number): string {
    const date = edition
      ? new Date(
          (Number(edition.toString().substring(1)) / 100).toFixed(0) +
            '-' +
            edition.toString().substring(5) +
            '-01 12:00:00'
        )
      : new Date();
    return (getSubscriptionName(Number((edition / 1000000).toFixed(0))) +
      ' ' +
      this.datePipe.transform(date, 'MMMM/yyyy', undefined, 'pt-BR')) as string;
  }

  timeDiffLabel(dateStart: string): string {
    return FormUtil.timeDiffLabel(dateStart, this.datePipe);
  }

  async chargeBack(): Promise<void> {
    this.dialog
      .open(EditionChargebackModalComponent, {
        closable: true,
        header: 'Estornar edições',
        data: {
          billings: this.billingsToChargeback,
          subscriberId: this.subscriber?.subscriberId
        },
        width: this.modalWidth
      })
      .onClose.subscribe(async (data) => {
        if (data) {
          LoaderService.showLoader();
          await Promise.all([
            this.findSubscriberRefunds(),
            this.findPayments()
          ]);
          this.messageService.add({
            detail: 'Estorno solicitado com sucesso',
            summary: 'Sucesso',
            severity: 'success'
          });
          this.selectedTab = this.voucherBillings.length ? 5 : 4;
          this.cdRef.detectChanges();
          LoaderService.showLoader(false);
        }
      });
  }

  async inactivate(): Promise<void> {
    if (!this.cancellationReasons) {
      LoaderService.showLoader();
      this.cancellationReasons = await lastValueFrom(
        this.subscriberService
          .getSubscriberCancellationReasons()
          .pipe(map((data) => data.result))
      );
      LoaderService.showLoader(false);
    }
    this.cancellationOpen = true;
    this.dialog
      .open(SubscriberCancellationModalComponent, {
        closable: true,
        header: 'Cancelar assinatura',
        data: {
          subscriber: this.subscriber,
          cancellationReasons: this.cancellationReasons,
          billings: this.billingsToChargeback,
          paymentHistory: this.paymentHistory,
          compositions: this.compositions
        },
        width: this.modalWidth,
        maximizable: true
      })
      .onClose.subscribe(async (data) => {
        if (data) {
          this.subscriber = data;
          this.messageService.add({
            detail: 'Assinatura cancelada',
            summary: 'Sucesso',
            severity: 'success'
          });
          this.subscriberStatus.setValue(
            this.subscriber?.subscriberStatus as number
          );
          await Promise.all([
            this.findSubscriberCancellations(),
            this.findSubscriberCoupons(),
            this.findSubscriberRefunds(),
            this.findPayments(),
            this.findCompositions(),
            this.findCreditCard()
          ]);
          this.findBillingsToChargeback();
          this.selectedTab = this.voucherBillings?.length ? 7 : 6;
          if (
            this.refunds?.some((r) => !r.hasBeenApproved || !r.hasRefunded) ||
            this.scheduledCancellation
          )
            AppDialogService.showInfoDialog({
              message: this.refunds?.some(
                (r) => !r.hasBeenApproved || !r.hasRefunded
              )
                ? 'Lembre-se de aprovar os estornos para remover as edições.'
                : 'A assinante tem até o final do mês para utilizar seus benefícios.',
              header: this.scheduledCancellation
                ? 'Cancelamento agendado para ' +
                  this.datePipe.transform(
                    this.referenceAsDate(
                      this.scheduledCancellation.cancellationReference
                    ),
                    'dd/MM/yyyy'
                  )
                : 'Assinatura cancelada'
            });
          LoaderService.showLoader(false);
        }
        this.cancellationOpen = false;
      });
  }

  disableRecurrence(): void {
    let width = '100%';
    if (!this.isMobile) {
      width = '500px';
    }
    this.dialog
      .open(DisableRecurrenceModalComponent, {
        closable: true,
        header: 'Desabilitar recorrência',
        data: {
          subscriberId: this.subscriber?.subscriberId
        },
        width
      })
      .onClose.subscribe(async (detail) => {
        if (detail) {
          await this.findCreditCard();
          lastValueFrom(
            this.subscriberService
              .findSubscriberInfoById(this.subscriber.subscriberId)
              .pipe(map((data) => data.result))
          )
            .then((subscriber) => (this.subscriber = subscriber))
            .catch((err) => AppDialogService.showErrorDialog(err));
          this.messageService.add({
            detail,
            summary: 'Sucesso',
            severity: 'success'
          });
          LoaderService.showLoader(false);
        }
      });
  }

  showDropdown(): void {
    this.changeStatus = true;
    setTimeout(() => {
      this.dropdown?.show();
    });
  }

  async changeSubscriberStatus(): Promise<void> {
    if (this.subscriberStatus.valid) {
      LoaderService.showLoader();
      try {
        this.subscriber = await lastValueFrom(
          this.subscriberService
            .updateSubscriberStatus({
              subscriberId: this.subscriber?.subscriberId as number,
              subscriberStatusId: this.subscriberStatus.value as number
            })
            .pipe(map((data) => data.result))
        );
        this.changeStatus = false;
      } catch (error: any) {
        AppDialogService.showErrorDialog(
          error,
          false,
          'Não foi possível alterar o status.'
        );
      }
      LoaderService.showLoader(false);
    }
  }

  async showPayment(paymentId: number): Promise<void> {
    LoaderService.showLoader();
    try {
      const payment = await lastValueFrom(
        this.paymentService
          .findPaymentById(paymentId)
          .pipe(map((data) => data.result))
      );
      this.dialog.open(PaymentDetailsModalComponent, {
        closable: true,
        header: 'Pagamento ' + paymentId,
        data: payment
      });
    } catch (error: any) {
      AppDialogService.showErrorDialog(
        error,
        false,
        'Pagamento não encontrado.'
      );
    }
    LoaderService.showLoader(false);
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Copiado para área de trasnferência'
    });
  }

  callAction($event: {
    item: SubscriberRefund & { edition: string };
    $event: Event;
    action: 'removeRefund' | string;
  }): void {
    if ($event.action === 'removeRefund') {
      this[$event.action]($event.item, $event.$event);
    }
  }

  removeRefund(
    refund: SubscriberRefund & { edition: string },
    $event: Event
  ): void {
    this.confirmationService.confirm({
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-primary',
      message: `Deseja remover a solicitação de estorno para a ${refund.edition}`,
      target: $event.target || undefined,
      accept: async () => {
        LoaderService.showLoader();
        try {
          await lastValueFrom(
            this.subscriberService.removeSubscriberRefund(
              refund.subscriberBillingId as number
            )
          );
          await Promise.all([
            this.findSubscriberRefunds(),
            this.findPayments()
          ]);
          this.findBillingsToChargeback();
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Estorno removido com sucesso.'
          });
        } catch (error: any) {
          AppDialogService.showErrorDialog(
            error,
            false,
            'Pagamento não encontrado.'
          );
        }
        LoaderService.showLoader(false);
      }
    });
  }

  billingStatusPaid(
    billing: SubscriberBillingHistoryEdition | VoucherSubscriberBilling
  ): number {
    if (billing.subscriberBillingStatus === SubscriberBillingStatusEnum.Pago)
      return 1;
    if (
      billing.subscriberBillingStatus === SubscriberBillingStatusEnum.Pendente
    )
      return 0;
    return -1;
  }

  paymentAuthorized(payment: SubscriberPaymentHistory): number {
    if (payment.transactionStatusId === 6) return 1;
    if (payment.transactionStatusId === 1) return 0;
    return -1;
  }

  async editionChanged(): Promise<void> {
    LoaderService.showLoader();
    await Promise.all([
      this.findCompositions(),
      this.findPayments(),
      this.findSubscriberRefunds()
    ]);
    LoaderService.showLoader(false);
  }

  canRemoveRefund(refund: SubscriberRefund): boolean {
    return !refund.hasBeenApproved && !refund.hasRefunded;
  }

  canRemoveCard(card: CreditCardRecurrenceWithCard): boolean {
    return !!card.recurrenceStatusId;
  }

  editionDateName(editionId: number): string {
    return (
      SubscriptionEnum[editionId.toString().substring(0, 1)] +
      ' ' +
      (this.datePipe.transform(
        new Date(
          `${((editionId % 1000000) / 100).toFixed(0)}-${editionId % 100}-02`
        ),
        'MMMM/yyyy'
      ) || FormUtil.editionDate(editionId))
    );
  }

  payerInstitution(payment: SubscriberPaymentHistory) {
    return this.institutions?.find(
      (i) => i.payerInstitutionId === payment.payerInstitution
    );
  }

  payerInstitutionImg(payment: SubscriberPaymentHistory) {
    return this.payerInstitution(payment)?.logoUrl ||
      this.payerInstitution(payment)?.iconUrl
      ? `<img src="${
          this.payerInstitution(payment)?.logoUrl ||
          this.payerInstitution(payment)?.iconUrl
        }" alt="${this.payerInstitution(payment).legalEntityName}">`
      : this.payerInstitution(payment)?.institutionName ||
          FormUtil.gatewayImage(payment.gatewayId) ||
          payment.gatewayName;
  }

  referenceAsDate(reference: number) {
    return `${(reference / 100).toFixed()}-${reference % 100}-01 12:00:00`;
  }

  get idLabel(): string {
    switch (this.subscriber?.subscriptionId) {
      case 5:
        return 'BagId';
      case 6:
        return 'PassId';
      default:
        return 'BoxId';
    }
  }

  get oldEditions(): Array<SubscriberEditionCompositionSummary> {
    return this.compositions?.filter(
      (c) =>
        c.compositionId ||
        EditionId.isBeforeOrEqual(
          c.editionId,
          EditionId.currentEdition(this.subscriber.subscriptionId)
        )
    );
  }

  get recurrenceActive(): boolean {
    return (
      this.creditCard?.recurrenceStatusId == 0 &&
      this.creditCard?.amount !== null &&
      this.creditCard?.amount !== undefined &&
      this.creditCard?.amount > 0 &&
      this.creditCard?.attempt !== null &&
      this.creditCard?.attempt !== undefined &&
      this.creditCard?.attempt >= 0 &&
      this.creditCard?.creditCardId !== null &&
      this.creditCard?.creditCardId !== undefined &&
      this.creditCard?.creditCardId > 0 &&
      this.creditCard?.nextPaymentDate !== undefined &&
      this.creditCard?.nextPaymentDate !== null &&
      this.creditCard?.maxAttempts !== null &&
      this.creditCard?.maxAttempts !== undefined &&
      this.creditCard.preferredGatewayId !== null &&
      this.creditCard.preferredGatewayId !== undefined &&
      this.creditCard.preferredGatewayId > 0 &&
      this.creditCard.installments !== null &&
      this.creditCard.installments !== undefined &&
      this.creditCard.installments > 0 &&
      this.creditCard.recurrence !== null &&
      this.creditCard.recurrence !== undefined
    );
  }

  get lastPayment() {
    return this.paymentHistory?.reduce((p, last) => {
      if (
        FormUtil.isDateBefore(
          new Date(last.dateCreated),
          new Date(p.dateCreated)
        )
      )
        last = p;
      return last;
    }, this.paymentHistory[0]);
  }

  findBillingsToChargeback() {
    const editions = this.compositions
      ?.filter(
        (c) =>
          // Não entregues
          c.subscriberEditionStatusId !== 6
      )
      .map((c) => c.editionId);
    this.billingsToChargeback = this.paymentHistory?.reduce(
      (billings: AvailableBilling[], payment) => {
        if (
          payment.billings?.some((b) => {
            return (
              b.subscriberBillingStatus === SubscriberBillingStatusEnum.Pago
            );
          })
        )
          billings = billings.concat(
            payment.billings
              ?.filter((b) => {
                const created = new Date(payment.dateCreated as any);
                created.setMonth(created.getMonth() + 1);
                const today = new Date();
                today.setDate(today.getDate() - 1);
                today.setHours(0, 0, 0, 0);
                return (
                  b.subscriberBillingStatus ===
                    SubscriberBillingStatusEnum.Pago &&
                  (editions?.includes(b.editionId) ||
                    created.getTime() > today.getTime() ||
                    (b.editionId / 1000000).toFixed(0) === '6')
                );
              })
              .map(
                (b) =>
                  ({
                    editionId: b.editionId as number,
                    subscriberBillingId: b.subscriberBillingId as number,
                    imageUrl: this.compositions?.find((c) => c.compositionId)
                      ?.imageURL as string,
                    delivered:
                      !editions?.includes(b.editionId) &&
                      (b.editionId % 1000000).toFixed(0) === '6'
                  } as AvailableBilling)
              )
          );
        return billings;
      },
      []
    );
  }

  get modalWidth() {
    return this.isMobile ? '100%' : '700px';
  }

  get scheduledCancellation() {
    return this.cancellations?.find(
      (c) => c.cancellationReference && !c.dateExecuted
    );
  }
}
