/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PersonActionDetailed {
  personActionId?: number;
  personId?: number;
  referenceId?: number;
  actionType?: number;
  dateCreated?: Date;
  dateConfirmed?: Date;
  userId?: number;
  description?: string;
  username?: string;
  manual?: boolean;
}
