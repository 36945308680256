export interface Badge {
  badgeId: number;
  consecutiveEditionsToAchieve: number;
  glampointsMultiplier: number;
  glampointsRewardAnnual: number;
  glampointsRewardMonthly: number;
  glampointsRewardMontly: number;
  glampointsRewardSemestral: number;
  iconUrl: string;
  level: number;
  title: string;
  monthsToExpiration: number;
}
