/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CouponCreateRequest {
  name?: string;
  value?: number;
  couponType?: number;
  maxRedeems?: number;
  enabled?: boolean;
  isRenewal?: number;
  glampoints?: number;
  dateExpired?: Date;
  freebieCount?: number;
  freebieMessage?: string;
  influencerStatusTypeIdRestriction?: number;
  freeShipping?: number;
  couponUsageTypeId?: number;
  emailRestrictions?: Array<string>;
  subscriptionTypeIds?: Array<number>;
}
