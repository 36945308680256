// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: 'http://localhost:8080',
  apiUrl: 'https://admin-api-stage.b4a.com.br',
  subscriptionApiUrl: 'https://apiglamapp.glambox.com.br/',
  instagramUrl: 'https://instagram.com/',
  firebase: {
    projectId: 'b4a-prj-admin-stg',
    appId: '1:232671007897:web:a82572e331261185ac9408',
    storageBucket: 'b4a-prj-admin-stg.appspot.com',
    locationId: 'southamerica-east1',
    apiKey: 'AIzaSyDaqd--Tbq0ayPOk-W1Ks8BYKCnvk_DQn0',
    authDomain: 'b4a-prj-admin-stg.firebaseapp.com',
    messagingSenderId: '232671007897',
    vapidKey:
      'BDrhalwt9EblmvWguXhQNLY9Ha9FKdu8qy5_Bdf08fA2drshNZcSwkqZA1ZBewMoHxOxp_Yp5cDK4Kwdkhr-nAc',
    serverKey: '3OwCROt24lZ_Ii7eCU47jiFl_gvAAatYo4aENKHoOW0'
  },
  connectFirebase: {
    projectId: 'b4astage-cb80a',
    appId: '1:925318835135:web:5fdea0da32656c1b2dcf0a',
    storageBucket: 'b4astage-cb80a.appspot.com',
    locationId: 'southamerica-east1',
    apiKey: 'AIzaSyADyiL4mMWKp0zqVbX_lZy-oYChSpwoNzM',
    authDomain: 'b4astage-cb80a.firebaseapp.com'
  },
  elasticSearch: {
    url: 'https://a2bb4426e59548a78607bb5a78c4e8cc.us-central1.gcp.cloud.es.io:443',
    credential: 'TzNYS2RZY0J0M3dXQS1YS3VsY006Vkx6aG9mMDJTSzZGOEt4Vk9ZcjVKZw=='
  },
  vertexConfig: {
    url: 'https://southamerica-east1-b4astage-cb80a.cloudfunctions.net/vertex',
    token: ''
  },
  hasura: {
    endpoint: 'https://hasura-products-36m4jjtkna-rj.a.run.app/v1/graphql',
    adminSecret: 'b171ab4d722455b03648ed462b41f8c2'
  },
  apiBadge: {
    gateway: 'https://apiglamapp.glambox.com.br',
    credentials: {
      username: 'tecnologia@b4agroup.com',
      password: 'z#jruzVBq5dZd9'
    }
  },
  firebaseRegion: 'southamerica-east1',
  auditApiUrl: 'https://audit-microservice-dot-b4astage-cb80a.rj.r.appspot.com',
  glamUrl: 'https://glam-develop.web.app',
  shopGatewayId: 15
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
