/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CompositionProductVariantDetail {
  compositionId?: number;
  compositionName?: string;
  quantity?: number;
  productVariantId?: number;
  productId?: number;
  productVariantName?: string;
  costPrice?: number;
  invoicePrice?: number;
  weight?: number;
  salePrice?: number;
  tradePoints?: number;
  tradeActive?: boolean;
  defaultImageURL?: string;
  orderCount?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  tradeCount?: number;
  isSellableUrl?: boolean;
  brandId?: number;
  brandName?: string;
  brandEquity?: number;
  subcategoryId?: number;
  subcategory?: string;
  categoryId?: number;
  category?: string;
  externalId?: number;
  internalEAN?: string;
  subscriptionId?: number;
  subscriptionName?: string;
  editionId?: number;
  expiration?: string;
  inStockAcquisition?: number;
  inStockFidelization?: number;
  inStockReserveMarketing?: number;
  inStockInfluencerB4A?: number;
  sellable?: boolean;
  tradeable?: boolean;
  cst?: string;
  extipi?: string;
  genero?: string;
  ncm?: string;
  cean?: string;
  cfop?: string;
}
