/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CompositionProduction } from './compositionProduction';

export interface CompositionProductionSummary {
  compositionId?: number;
  editionId?: number;
  compositionName?: string;
  subscriberCount?: number;
  quantity?: number;
  pending?: number;
  productions?: Array<CompositionProduction>;
}
