/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PageableObject } from './pageableObject';
import { SortObject } from './sortObject';
import { SubscriberRefundSummary } from './subscriberRefundSummary';

export interface PageSubscriberRefundSummary {
  totalPages?: number;
  totalElements?: number;
  pageable?: PageableObject;
  size?: number;
  content?: Array<SubscriberRefundSummary>;
  number?: number;
  sort?: SortObject;
  numberOfElements?: number;
  first?: boolean;
  last?: boolean;
  empty?: boolean;
}
