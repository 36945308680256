import {
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Editor } from 'primeng/editor';
import Quill from 'quill';
import { lastValueFrom, map } from 'rxjs';
import * as beautify from 'simply-beautiful';
import {
  CourseControllerService,
  CourseModule,
  FileControllerService
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-module-form',
  templateUrl: './module-form.component.html',
  styleUrl: './module-form.component.scss',
  providers: [MessageService]
})
export class ModuleFormComponent implements OnInit {
  @ViewChild(Editor) editor: Editor;

  private courseService = inject(CourseControllerService);
  private fileService = inject(FileControllerService);
  private messageService = inject(MessageService);
  private ref = inject(DynamicDialogRef);
  cdRef = inject(ChangeDetectorRef);

  module: CourseModule = inject(DynamicDialogConfig).data.module;
  courseId: number = inject(DynamicDialogConfig).data.courseId;
  form = new FormGroup({
    name: new FormControl<string>(null, [
      Validators.required,
      Validators.maxLength(200)
    ]),
    description: new FormControl<string>(null),
    link: new FormControl<string>(null, Validators.maxLength(2000)),
    active: new FormControl(false),
    html: new FormControl(false),
    order: new FormControl<number>(null)
  });
  toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [
      'bold',
      'italic',
      'underline',
      'strike',
      { script: 'sub' },
      { script: 'super' },
      'code-block'
    ], // toggled buttons
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['link', 'image'],

    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }]
  ];
  handlers = {
    image: this.handleImage
  };

  ngOnInit(): void {
    if (this.module) this.form.patchValue(this.module);
  }

  format() {
    this.form
      .get('description')
      .setValue(beautify.html(this.form.value.description, { indent_size: 2 }));
  }

  handleImage() {
    document.getElementById('inputFile').click();
  }

  async handleFile($event: Event) {
    try {
      LoaderService.showLoader();
      const link = await lastValueFrom(
        this.fileService
          .uploadFileForm(
            'course/module/assets/',
            ($event.target as any).files[0],
            ($event.target as any).files[0].name
          )
          .pipe(map((data) => data.result))
      );
      this.form.controls.description.setValue(
        this.form.value.description + `<p><img src="${link}" /></p>`
      );
      this.editor.writeValue(this.form.value.description);
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Imagem adicionada ao final do texto.'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  addClassToImages() {
    (this.editor?.getQuill() as Quill)?.container
      .querySelectorAll('img')
      .forEach((i) => {
        if (!i.className?.includes('dimensioned'))
          i.classList.add('dimensioned');
      });
    this.form
      .get('description')
      .setValue(
        (this.editor?.getQuill() as Quill)?.getSemanticHTML() ||
          this.form.value.description
      );
  }

  async submit() {
    if (this.form.valid) {
      try {
        LoaderService.showLoader();
        if (this.module) {
          this.module = await lastValueFrom(
            this.courseService
              .updateCourseModule(this.form.value, this.module.moduleId)
              .pipe(map((data) => data.result))
          );
        } else {
          this.module = await lastValueFrom(
            this.courseService
              .createCourseModule(this.form.value, this.courseId)
              .pipe(map((data) => data.result))
          );
        }
        this.ref.close(this.module);
      } catch (error) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(error);
      }
    }
  }
}
