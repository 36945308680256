/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EditionCustomizationIcons } from './editionCustomizationIcons';

export interface EditionCustomization {
  editionTheme?: string;
  editionDate?: number;
  editionCardDesktop?: string;
  editionCardMobile?: string;
  editionCardBackgroundColor?: string;
  lastEditionsCarouselCard?: string;
  lastEditionsCarouselCardAlt?: string;
  dateCreated?: Date;
  icons?: Array<EditionCustomizationIcons>;
  editionDateTime?: Date;
}
