import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomizationComponentBannerFormComponent } from './customization-component/customization-component-banner/customization-component-banner-form/customization-component-banner-form.component';
import { CustomizationComponentBannerComponent } from './customization-component/customization-component-banner/customization-component-banner.component';
import { CustomizationComponentComponent } from './customization-component/customization-component.component';
import { DmsSectionFormComponent } from './dms-section-form/dms-section-form.component';
import { CallAndWinFormComponent } from './glam/call-friends/call-and-win/call-and-win-form/call-and-win-form.component';
import { CallAndWinComponent } from './glam/call-friends/call-and-win/call-and-win.component';
import { EarnGlampointsFormComponent } from './glam/call-friends/earn-glampoints/earn-glampoints-form/earn-glampoints-form.component';
import { EarnGlampointsComponent } from './glam/call-friends/earn-glampoints/earn-glampoints.component';
import { HomeScreenFormComponent } from './glam/call-friends/home-screen/home-screen-form/home-screen-form.component';
import { HomeScreenComponent } from './glam/call-friends/home-screen/home-screen.component';
import { PostsFormComponent } from './glam/call-friends/posts/posts-form/posts-form.component';
import { PostsComponent } from './glam/call-friends/posts/posts.component';
import { CollectionsLojinhasEditComponent } from './glam/collections-lojinhas/collections-lojinhas-edit/collections-lojinhas-edit.component';
import { CollectionsLojinhasComponent } from './glam/collections-lojinhas/collections-lojinhas.component';
import { EditionComponent } from './glam/editions/edition/edition.component';
import { EditionsComponent } from './glam/editions/editions.component';
import { GlamHomeAnuncyFormComponent } from './glam/glam-home/glam-home-anuncy-form/glam-home-anuncy-form.component';
import { GlamHomeBannerFormComponent } from './glam/glam-home/glam-home-banner-form/glam-home-banner-form.component';
import { GlamHomeCollectionFormComponent } from './glam/glam-home/glam-home-collection-form/glam-home-collection-form.component';
import { GlamHomeLiveEmbeddedFormComponent } from './glam/glam-home/glam-home-live-embedded-form/glam-home-live-embedded-form.component';
import { GlamHomeSectionComponent } from './glam/glam-home/glam-home-section/glam-home-section.component';
import { GlamHomeComponent } from './glam/glam-home/glam-home.component';
import { LpClubBannersFormComponent } from './glam/lp-club/lp-club-banners/lp-club-banners-form/lp-club-banners-form.component';
import { LpClubBannersComponent } from './glam/lp-club/lp-club-banners/lp-club-banners.component';
import { LpClubComponent } from './glam/lp-club/lp-club.component';
import { FirstPanelCarouselFormComponent } from './glam/panel/panel-first-carousel/panel-first-carousel-form/panel-first-carousel-form.component';
import { FirstPanelCarouselComponent } from './glam/panel/panel-first-carousel/panel-first-carousel.component';
import { SecondPanelCarouselFormComponent } from './glam/panel/panel-second-carousel/panel-second-carousel-form/panel-second-carousel-form.component';
import { SecondPanelCarouselComponent } from './glam/panel/panel-second-carousel/panel-second-carousel.component';
import { PanelComponent } from './glam/panel/panel.component';
import { VitrineCustomizationComponent } from './glam/vitrine-customization/vitrine-customization.component';
import { WishlistBannersFormComponent } from './glam/wishlist/wishlist-banners/wishlist-banners-form/wishlist-banners-form.component';
import { WishlistBannersComponent } from './glam/wishlist/wishlist-banners/wishlist-banners.component';
import { WishlistThemesFormComponent } from './glam/wishlist/wishlist-themes/wishlist-themes-form/wishlist-themes-form.component';
import { WishlistThemesComponent } from './glam/wishlist/wishlist-themes/wishlist-themes.component';
import { MensHomeComponent } from './mens/mens-home/mens-home.component';
import { MenuComponent } from './menu/menu.component';
import { GlamBeautyBannerFormComponent } from './own-brands/glam-beauty/glam-beauty-carousel/glam-beauty-banner-form/glam-beauty-banner-form.component';
import { GlamBeautyCarouselComponent } from './own-brands/glam-beauty/glam-beauty-carousel/glam-beauty-carousel.component';
import { GlamBeautyComponent } from './own-brands/glam-beauty/glam-beauty.component';
import { MalmoBannerFormComponent } from './own-brands/malmo/malmo-carousel/malmo-banner-form/malmo-banner-form.component';
import { MalmoCarouselComponent } from './own-brands/malmo/malmo-carousel/malmo-carousel.component';
import { MalmoComponent } from './own-brands/malmo/malmo.component';
import { MensBannerFormComponent } from './own-brands/mens/mens-carousel/mens-banner-form/mens-banner-form.component';
import { MensCarouselComponent } from './own-brands/mens/mens-carousel/mens-carousel.component';
import { MensComponent } from './own-brands/mens/mens.component';

const routes: Routes = [
  {
    path: '',
    component: MenuComponent
  },
  {
    path: 'lp-club',
    component: LpClubComponent
  },
  {
    path: 'lp-club/banners',
    component: LpClubBannersComponent
  },
  {
    path: 'lp-club/banners/new',
    component: LpClubBannersFormComponent
  },
  {
    path: 'lp-club/banners/edit/:index',
    component: LpClubBannersFormComponent
  },
  {
    path: 'call-friends/home-screen/new',
    component: HomeScreenFormComponent
  },
  {
    path: 'call-friends/home-screen/edit/:index',
    component: HomeScreenFormComponent
  },
  {
    path: 'call-friends/home-screen',
    component: HomeScreenComponent
  },
  {
    path: 'call-friends/call-and-win',
    component: CallAndWinComponent
  },
  {
    path: 'call-friends/call-and-win/new',
    component: CallAndWinFormComponent
  },
  {
    path: 'call-friends/call-and-win/edit/:index',
    component: CallAndWinFormComponent
  },
  {
    path: 'call-friends/earn-glampoints',
    component: EarnGlampointsComponent
  },
  {
    path: 'call-friends/earn-glampoints/new',
    component: EarnGlampointsFormComponent
  },
  {
    path: 'call-friends/earn-glampoints/edit/:index',
    component: EarnGlampointsFormComponent
  },
  {
    path: 'call-friends/posts',
    component: PostsComponent
  },
  {
    path: 'call-friends/posts/edit/:index',
    component: PostsFormComponent
  },
  {
    path: 'call-friends/posts/new',
    component: PostsFormComponent
  },
  {
    path: 'call-friends/call-and-win',
    component: CallAndWinFormComponent
  },
  {
    path: 'call-friends/earn-glampoints',
    component: EarnGlampointsFormComponent
  },
  {
    path: 'call-friends/posts',
    component: PostsComponent
  },
  {
    path: 'call-friends/posts/edit/:index',
    component: PostsFormComponent
  },
  {
    path: 'call-friends/posts/new',
    component: PostsFormComponent
  },
  {
    path: 'painel',
    component: PanelComponent
  },
  {
    path: 'painel/first-carousel',
    component: FirstPanelCarouselComponent
  },
  {
    path: 'painel/second-carousel',
    component: SecondPanelCarouselComponent
  },
  {
    path: 'painel/first-carousel/new',
    component: FirstPanelCarouselFormComponent
  },
  {
    path: 'painel/second-carousel/new',
    component: SecondPanelCarouselFormComponent
  },
  {
    path: 'painel/first-carousel/edit/:index',
    component: FirstPanelCarouselFormComponent
  },
  {
    path: 'painel/second-carousel/edit/:index',
    component: SecondPanelCarouselFormComponent
  },
  {
    path: 'painel/first-carousel',
    component: FirstPanelCarouselComponent
  },
  {
    path: 'collections-lojinhas',
    component: CollectionsLojinhasComponent
  },
  {
    path: 'collections-lojinhas/edit/:index',
    component: CollectionsLojinhasEditComponent
  },
  /**************************************************************
   * ************************************************************
   * LPS PROMOCIONAIS********************************************
   * ************************************************************
   ***************************************************************/
  {
    path: 'lp-ambassadors/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-ambassadors-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-ambassadors-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-ambassadors-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-edition/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-edition-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-edition-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-edition-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-edition-glambox/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-edition-glambox-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-edition-glambox-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-edition-glambox-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-glambag/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-glambag-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-glambag-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-glambag-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-glambox/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-glambox-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-glambox-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-glambox-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-glamcombo/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-glamcombo-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-glamcombo-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-glamcombo-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-glamgirl/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-glamgirl-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-glamgirl-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-glamgirl-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-glampass/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-glampass-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-glampass-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-glampass-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-partners/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-partners-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-partners-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-partners-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-royals/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-royals-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-royals-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-royals-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-signatures/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-signatures-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-signatures-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-signatures-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-growth-1/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-growth-1-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-growth-1-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-growth-1-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-growth-2/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-growth-2-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-growth-2-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-growth-2-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-growth-3/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-growth-3-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-growth-3-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-growth-3-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-growth-4/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-growth-4-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-growth-4-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-growth-4-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-growth-5/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'lp-growth-5-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'lp-growth-5-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'lp-growth-5-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  /**************************************************************
   * ************************************************************
   * MENSMARKET**************************************************
   * ************************************************************
   ***************************************************************/
  {
    path: 'mensmarket-clube/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'mensmarket-clube-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'mensmarket-clube-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'mensmarket-clube-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'mensmarket-prime/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'mensmarket-prime-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'mensmarket-prime-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'mensmarket-prime-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'mensmarket-select/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'mensmarket-select-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'mensmarket-select-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'mensmarket-select-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'mensmarket-promocoes/:id',
    component: CustomizationComponentComponent
  },
  {
    path: 'mensmarket-promocoes-banner/:id',
    component: CustomizationComponentBannerComponent
  },
  {
    path: 'mensmarket-promocoes-banner-new/:id',
    component: CustomizationComponentBannerFormComponent
  },
  {
    path: 'mensmarket-promocoes-banner-edit/:id/:index',
    component: CustomizationComponentBannerFormComponent
  },
  /**************************************************************
   * ************************************************************
   * MARCAS PROPRIAS*********************************************
   * ************************************************************
   ***************************************************************/
  {
    path: 'glam-beauty',
    component: GlamBeautyComponent
  },
  {
    path: 'glam-beauty/carousels',
    component: GlamBeautyCarouselComponent
  },
  {
    path: 'glam-beauty/carousels/new',
    component: GlamBeautyBannerFormComponent
  },
  {
    path: 'glam-beauty/carousels/edit/:index',
    component: GlamBeautyBannerFormComponent
  },
  {
    path: 'malmo',
    component: MalmoComponent
  },
  {
    path: 'malmo/carousels',
    component: MalmoCarouselComponent
  },
  {
    path: 'malmo/carousels/new',
    component: MalmoBannerFormComponent
  },
  {
    path: 'malmo/carousels/edit/:index',
    component: MalmoBannerFormComponent
  },

  {
    path: 'mens',
    component: MensComponent
  },
  {
    path: 'mens/carousels',
    component: MensCarouselComponent
  },
  {
    path: 'mens/carousels/new',
    component: MensBannerFormComponent
  },
  {
    path: 'mens/carousels/edit/:index',
    component: MensBannerFormComponent
  },
  {
    path: 'vitrine',
    component: VitrineCustomizationComponent
  },
  {
    path: 'editions',
    component: EditionsComponent
  },
  {
    path: 'editions/:editionId',
    component: EditionComponent
  },
  {
    path: 'wishlist/themes',
    component: WishlistThemesComponent
  },
  {
    path: 'wishlist/themes/new',
    component: WishlistThemesFormComponent
  },
  {
    path: 'wishlist/themes/edit/:index',
    component: WishlistThemesFormComponent
  },
  {
    path: 'wishlist/banners',
    component: WishlistBannersComponent
  },
  {
    path: 'wishlist/banners/new',
    component: WishlistBannersFormComponent
  },
  {
    path: 'wishlist/banners/edit/:index',
    component: WishlistBannersFormComponent
  },
  {
    path: 'glam/home',
    component: GlamHomeComponent
  },
  {
    path: 'glam/home/:type',
    component: GlamHomeSectionComponent
  },
  {
    path: 'glam/home/:type/banner/:id',
    component: GlamHomeBannerFormComponent
  },
  {
    path: 'glam/home/banner/:id',
    component: GlamHomeBannerFormComponent
  },
  {
    path: 'glam/home/collection/:id',
    component: GlamHomeCollectionFormComponent
  },
  {
    path: 'glam/home/anuncy/:id',
    component: GlamHomeAnuncyFormComponent
  },
  {
    path: 'glam/home/live-embedded/:id',
    component: GlamHomeLiveEmbeddedFormComponent
  },
  {
    path: 'dms/mens_market',
    component: MensHomeComponent
  },
  {
    path: 'dms/glamshop',
    component: GlamHomeComponent
  },
  {
    path: 'dms/:shop/:id',
    component: DmsSectionFormComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomizationRoutingModule {}
