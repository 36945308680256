import { Inject, Injectable } from '@angular/core';
import { Category, CategoryRepository, Shops, Where } from '@infrab4a/connect';
import { Papa, ParseResult } from 'ngx-papaparse';
import { MessageService } from 'primeng/api';
import { ShopProductService } from 'src/app/connect-api/api/shop/shop-product.service';
import { BatchService } from './batch.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryMostRelevantBatchService implements BatchService {
  itemsForUpload: Partial<Category>[] = [];
  chunkSize = 100;
  completeCount = 0;
  errors = [];

  constructor(
    private papa: Papa,
    @Inject('CategoryRepository') private repository: CategoryRepository,
    private productService: ShopProductService,
    private messageService: MessageService
  ) {}

  async parse(input: File): Promise<Partial<Category>[]> {
    return new Promise<Partial<Category>[]>((resolve, reject) => {
      if (!input) return resolve((this.itemsForUpload = []));

      this.papa.parse(input, {
        header: false,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async (results: ParseResult<Array<Array<string>>>) => {
          try {
            if (results.errors.length > 0) throw results.errors.shift();
            this.itemsForUpload = [];

            const headers = results.data.shift();
            headers.shift();
            const shops = results.data.shift();
            shops.shift();
            headers.map((slug, idx) =>
              this.itemsForUpload.push({
                slug,
                mostRelevants: {
                  [Shops.GLAMSHOP]: [],
                  [Shops.MENSMARKET]: []
                },
                shops: shops[idx]?.split(',') || []
              })
            );

            results.data
              .filter((c) => Object.keys(c).some((k) => c[k]))
              .forEach((category: any) => this.convertToCategory(category));

            if (this.itemsForUpload?.some((i) => !i.shops?.length)) {
              reject(
                new Error(
                  'Informe o campo "Shops" da categoria na segunda coluna.'
                )
              );
            }
            resolve(this.itemsForUpload);
          } catch (error: any) {
            reject(new Error(`Arquivo inválido: ${error.message}`));
          }
        }
      });
    });
  }

  async update(): Promise<Array<Partial<Category>>> {
    if (this.itemsForUpload?.length <= 0) {
      return [];
    }

    this.errors = [];

    const chunks = this.getChunkedCategoryList();
    return await this.startImportation(chunks);
  }

  get importProgress(): number {
    return +((this.completeCount * 100) / this.itemsForUpload.length).toFixed(
      0
    );
  }

  private async convertToCategory(category: Array<string>): Promise<void> {
    for (let index = 1; index < category.length; index++) {
      const ean = category[index];
      const item = this.itemsForUpload[index - 1];
      const shop = item.shops[0];

      if (item) item.mostRelevants[shop] = ean.split(',');
    }
  }

  private async startImportation(
    chunks: Partial<Category>[][]
  ): Promise<Array<Partial<Category>>> {
    let successImportCount = 0;
    this.completeCount = 0;
    const updated: Array<Partial<Category>> = [];

    for (const categories of chunks) {
      for (const category of categories) {
        const categoryToUpdate = await this.repository
          .find({
            filters: {
              slug: { operator: Where.EQUALS, value: category.slug },
              shops: { operator: Where.IN, value: category.shops }
            },
            limits: { limit: 1 }
          })
          .then((res) => res.data.shift());

        if (categoryToUpdate) {
          const mostRelevants = [];

          for (const ean of category.mostRelevants[category.shops[0]]) {
            if (!ean) continue;

            const product = await this.productService.getProductByEan(
              ean.toString()
            );
            const isChild = await this.repository.isChild(
              +product.category.id,
              +categoryToUpdate.id
            );

            if (
              product?.category?.id?.toString() ===
                categoryToUpdate.id.toString() ||
              product?.categories?.includes(categoryToUpdate.id) ||
              isChild
            )
              mostRelevants.push(product.id);
          }

          const categoryMostRelevat = {
            ...categoryToUpdate.mostRelevants,
            ...category.shops.reduce(
              (acc, shop) => ({ ...acc, [shop]: mostRelevants }),
              {}
            )
          };

          updated.push(
            await this.repository.update(
              Category.toInstance({
                id: categoryToUpdate.id,
                mostRelevants: categoryMostRelevat
              })
            )
          );

          successImportCount++;
        } else {
          this.errors.push({
            message: `Categoria com slug ${category.slug} não encontrada`
          });
        }

        this.completeCount++;
      }
    }

    if (this.errors.length === this.completeCount) {
      this.messageService.add({
        severity: 'warn',
        detail: `Não foi possível realizar a atualização`,
        summary: 'Erro'
      });
    } else {
      this.messageService.add({
        severity: 'success',
        detail: ` Foram atualizadas ${successImportCount} categorias`,
        summary: 'Atualização concluída!'
      });
    }
    return updated?.length
      ? (
          await this.repository.find({
            filters: {
              id: { operator: Where.IN, value: updated.map((c) => c.id) }
            }
          })
        ).data.map((c) => ({ ...c, shop: c.shops[0] } as Partial<Category>))
      : [];
  }

  private getChunkedCategoryList(): Partial<Category>[][] {
    const itemsForUpload = [...this.itemsForUpload];

    return [...Array(Math.ceil(itemsForUpload.length / this.chunkSize))].map(
      () => itemsForUpload.splice(0, this.chunkSize)
    );
  }
}
