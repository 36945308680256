import { PageableFilter } from '../admin-api';

export interface ConnectRequest {
  fields?: string[];
  page: number;
  pageSize: number;
  sortBy?: string;
  sortDirection?: string;
  filters?: Array<PageableFilter>;
}
