import { PageableObject, SortObject } from 'src/app/admin-api';

export interface PageContent<T> {
  totalPages?: number;
  totalElements?: number;
  size?: number;
  content?: Array<T>;
  number?: number;
  sort?: SortObject;
  first?: boolean;
  last?: boolean;
  numberOfElements?: number;
  pageable?: PageableObject;
  empty?: boolean;
}
