import { Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { LiveControllerService, LiveDetail } from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-lives',
  templateUrl: './lives.component.html',
  styleUrl: './lives.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class LivesComponent implements OnInit {
  liveService = inject(LiveControllerService);
  permissionService = inject(NgxPermissionsService);
  title = inject(Title);
  messageService = inject(MessageService);
  confirmationService = inject(ConfirmationService);

  cols: TableColumn[];
  permission: boolean;

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    this.title.setTitle('Conteúdo - Lives');
    try {
      this.permission = await this.permissionService.hasPermission([
        roleAsObject(Role.Full_Administrator).enumValue,
        roleAsObject(Role.Content).enumValue
      ]);
    } catch (error) {
      this.permission = false;
      AppDialogService.showErrorDialog(error);
    } finally {
      this.cols = this.columns;
    }
  }

  async updateLiveStatus(live: LiveDetail, stop = false) {
    if (!live.active && !stop) {
      this.confirmationService.confirm({
        header: 'Interromper a live atual?',
        acceptLabel: 'Interromper!',
        rejectLabel: 'Voltar',
        rejectButtonStyleClass: 'p-button-primary',
        acceptButtonStyleClass: 'p-button-danger',
        accept: async () => {
          await this.updateLiveStatus(live, true);
        },
        reject: () => (live.active = !live.active)
      });
      return;
    }

    try {
      LoaderService.showLoader();
      await lastValueFrom(this.liveService.updateLive(live, live.liveId));
    } catch (error) {
      live.active = !live.active;
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  get columns() {
    return [
      new TableColumn.Builder()
        .setHeader('Id')
        .setField('liveId')
        .setType('number')
        .setRouterLink('')
        .setRouterLinkFieldName('liveId')
        .build(),
      new TableColumn.Builder()
        .setHeader('Título')
        .setField('title')
        .setRouterLink('')
        .setRouterLinkFieldName('liveId')
        .setCondition('contains')
        .build(),
      new TableColumn.Builder()
        .setHeader('Cupom')
        .setField('couponName')
        .setCondition('contains')
        .setRouterLink('/marketing/coupons/')
        .setRouterLinkFieldName('couponId')
        .setLinkActive((live: LiveDetail) => live.couponId > 0)
        .build(),
      new TableColumn.Builder()
        .setHeader('Coleção')
        .setField('collectionId')
        .setRouterLink('/shop-products/categories/category/')
        .setRouterLinkFieldName('collectionId')
        .setLinkActive(
          (live: LiveDetail) => live.collectionId?.trim()?.length > 0
        )
        .build(),
      new TableColumn.Builder()
        .setHeader('Ativa')
        .setField('active')
        .setType(this.permission ? 'inputSwitch' : 'boolean')
        .build(),
      new TableColumn.Builder()
        .setHeader('Likes')
        .setField('likes')
        .setType('formattedNumber')
        .setCondition('gte')
        .build(),
      new TableColumn.Builder()
        .setHeader('Visualizações')
        .setField('views')
        .setType('formattedNumber')
        .setCondition('gte')
        .build(),
      new TableColumn.Builder()
        .setHeader('Criação')
        .setField('dateCreated')
        .setType('date')
        .setCondition('between')
        .build(),
      new TableColumn.Builder()
        .setHeader('Últ. atualização')
        .setField('dateUpdated')
        .setType('date')
        .setCondition('between')
        .build()
    ];
  }
}
