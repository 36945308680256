/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProductVariantTotals {
  productVariantId?: number;
  brandId?: number;
  brandName?: string;
  expiration?: string;
  productVariantName?: string;
  defaultImageURL?: string;
  inStockInfluencerB4A?: number;
  internalEAN?: string;
  externalId?: number;
  totalCompositions?: number;
  totalResend?: number;
  totalTrade?: number;
  totalGift?: number;
  totalIncentive?: number;
  inStockReserveMarketing?: number;
  inStockAcquisition?: number;
  inStockFidelization?: number;
}
