import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Category, Product } from '@infrab4a/connect';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import {
  Live,
  LiveAccessDetail,
  LiveContent,
  LiveControllerService,
  LiveLikeDetail
} from 'src/app/admin-api';
import { TableColumn } from 'src/app/components/table';
import { ShopCategoryService } from 'src/app/connect-api/api/shop/shop-category.service';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LiveContentFormComponent } from '../live-form/live-content-form/live-content-form.component';

@Component({
  selector: 'app-live-detail',
  templateUrl: './live-detail.component.html',
  styleUrl: './live-detail.component.scss',
  providers: [MessageService, DialogService, ConfirmationService]
})
export class LiveDetailComponent implements OnInit {
  liveService = inject(LiveControllerService);
  permissionService = inject(NgxPermissionsService);
  activatedRoute = inject(ActivatedRoute);
  shopCategoryService = inject(ShopCategoryService);
  messageService = inject(MessageService);
  clipboard = inject(Clipboard);
  title = inject(Title);
  router = inject(Router);
  dialog = inject(DialogService);
  confirmationService = inject(ConfirmationService);
  cdRef = inject(ChangeDetectorRef);

  likeCols = [
    new TableColumn.Builder()
      .setHeader('Últ. atualização')
      .setField('dateUpdated')
      .setType('date')
      .build(),
    new TableColumn.Builder()
      .setHeader('Assinante')
      .setField('personName')
      .setCondition('contains')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .build(),
    new TableColumn.Builder()
      .setHeader('Deu like?')
      .setField('like')
      .setType('boolean')
      .setFilter(true)
      .build()
  ];
  viewCols = [
    new TableColumn.Builder()
      .setHeader('Última visualização')
      .setField('dateUpdated')
      .setType('date')
      .build(),
    new TableColumn.Builder()
      .setHeader('Primeira visualização')
      .setField('dateCreated')
      .setType('date')
      .build(),
    new TableColumn.Builder()
      .setHeader('Assinante')
      .setField('personName')
      .setCondition('contains')
      .setRouterLink('/users/person/')
      .setRouterLinkFieldName('personId')
      .setLinkActive((like: LiveAccessDetail) => like.personId > 0)
      .build(),
    new TableColumn.Builder()
      .setHeader('Visualizações')
      .setField('views')
      .setCondition('gte')
      .build()
  ];

  liveId: number;
  live: Live;
  contents: LiveContent[];
  likes: LiveLikeDetail[];
  views: LiveAccessDetail[];
  categories: Partial<Category>[];
  tabIndex = 0;
  permission = false;
  collection: Partial<Category>;
  products: Partial<Product>[];
  totalProducts: number;
  hasChange = false;

  async ngOnInit(): Promise<void> {
    LoaderService.showLoader();
    try {
      this.permission = await this.permissionService.hasPermission([
        roleAsObject(Role.Full_Administrator).enumValue,
        roleAsObject(Role.Content).enumValue
      ]);
    } catch (error) {
      this.permission = false;
      AppDialogService.showErrorDialog(error);
    }
    const params = await firstValueFrom(this.activatedRoute.params);
    if (params['liveId'] && params['liveId'] !== 'new') {
      this.liveId = Number(params['liveId']);
      await Promise.all([
        this.findLive(),
        this.findLikes(),
        this.findViews(),
        this.getCategoriesList()
      ]);
      if (this.collectionId) await this.findCollection();
      this.title.setTitle('Live - ' + this.live.title);
    } else {
      this.title.setTitle('Nova Live');
    }
    this.activatedRoute.queryParams.subscribe((qParams) => {
      if (qParams['tab'] && Number(qParams['tab']) >= 0)
        this.tabIndex = Number(qParams['tab']);
    });
    LoaderService.showLoader(false);
  }

  async findLive() {
    try {
      this.model = await lastValueFrom(
        this.liveService
          .findLiveById(this.liveId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      AppDialogService.showErrorDialog(error, true);
    }
  }

  async findLikes() {
    try {
      this.likes = await lastValueFrom(
        this.liveService
          .findLiveLikes(this.liveId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.likes = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async findViews() {
    try {
      this.views = await lastValueFrom(
        this.liveService
          .findLiveViews(this.liveId)
          .pipe(map((data) => data.result))
      );
    } catch (error) {
      this.views = [];
      AppDialogService.showErrorDialog(error);
    }
  }

  async getCategoriesList() {
    try {
      this.categories = await this.shopCategoryService.getCollections();
    } catch (error) {
      this.categories = [];
    }
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
    this.messageService.add({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Copiado para área de trasnferência'
    });
  }

  indexChange(index: number) {
    this.tabIndex = index;
    this.router.navigate(['/content/lives/' + this.liveId], {
      queryParamsHandling: 'merge',
      queryParams: {
        tab: this.tabIndex
      }
    });
  }

  addBlock(type: 'collection' | 'text', block?: LiveContent) {
    let header: string;
    if (block) {
      header = 'Editar ' + type === 'collection' ? 'coleção' : 'bloco de texto';
    } else
      header = type === 'collection' ? 'Nova coleção' : 'Novo bloco de texto';

    this.dialog
      .open(LiveContentFormComponent, {
        width: '600px',
        header,
        modal: true,
        maximizable: true,
        data: {
          block,
          live: this.live,
          type,
          order: block?.order || this.contents?.length + 1,
          permission: this.permission,
          categories: this.categories
        }
      })
      .onClose.subscribe(async (content: LiveContent) => {
        if (!content) return;
        if (block && block.html) {
          this.hasChange = this.hasChange || block.html !== content.html;
          block.html = content.html;
        } else if (block) {
          this.hasChange =
            this.hasChange || block.collectionId !== content.collectionId;
          block.collectionId = content.collectionId;
        } else {
          this.hasChange = true;
          this.contents.push(content);
        }
        if (!this.live.active) await this.saveContent();
        if (content.collectionId) await this.findCollection();
        if (!this.hasChange) this.checkChanges();
      });
  }

  async findCollection() {
    if (this.collectionId)
      try {
        LoaderService.showLoader();
        this.collection = await this.shopCategoryService.getCategoryById(
          this.collectionId
        );
        const response = (
          await this.shopCategoryService.getCategoryProductsPage(
            this.collection,
            4
          )
        ).products;
        this.products = response.data;
        this.totalProducts = response.total;
      } catch (error) {
        delete this.collection;
        delete this.products;
        AppDialogService.showErrorDialog(error);
      } finally {
        LoaderService.showLoader(false);
      }
  }

  deleteBlock(index: number, event: MouseEvent) {
    const block = this.contents.at(index);
    this.confirmationService.confirm({
      target: event.target,
      acceptLabel: 'Remover',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Manter',
      rejectButtonStyleClass: 'p-button-primary',
      header:
        'Remover ' +
        (block.collectionId ? 'coleção - ' : 'bloco de texto - ') +
        block.order,
      message: 'Deseja remover este bloco?',
      accept: async () => {
        this.contents.splice(index, 1);
        this.contents.forEach((c, i) => (c.order = i + 1));
        if (!this.live.active) await this.saveContent();
        this.checkChanges();
      }
    });
  }

  async moveBlock(index: number, direction: -1 | 1) {
    const b1 = this.contents.at(index);
    const b2 = this.contents.at(index + direction);
    if (b1 && b2) {
      b1.order = b1.order + direction;
      b2.order = b2.order - direction;
    }
    this.contents.sort((c1, c2) => c1.order - c2.order);
    if (!this.live.active) await this.saveContent();
    this.checkChanges();
  }

  async saveContent() {
    try {
      LoaderService.showLoader();
      const content = await lastValueFrom(
        this.liveService
          .updateLiveContent(this.contents, this.live.liveId)
          .pipe(map((data) => data.result))
      );
      this.model = { ...this.live, content };
      this.hasChange = false;
      this.messageService.add({
        severity: 'success',
        detail: 'Conteúdo atualizado',
        summary: 'Sucesso'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  checkChanges() {
    if (this.live?.content?.length !== this.contents?.length) return true;
    let change = false;
    let i = 0;
    while ((!change && i < this.live?.content?.length) || 0) {
      const b1 = this.live.content.at(i);
      const b2 = this.contents?.at(i);
      change = !b1 || !b2 || Object.keys(b1).some((k) => b1[k] !== b2[k]);
      i++;
    }
    this.hasChange = change;
  }

  get descriptions() {
    return this.contents?.filter((c) => !c.collectionId && c.html);
  }

  get collectionId() {
    return this.contents?.find((c) => c.collectionId)?.collectionId;
  }

  get shopCategory() {
    return this.collectionId
      ? this.categories?.find((c) => c.id === this.collectionId)
      : null;
  }

  get totalLikes() {
    return this.likes?.filter((l) => l.like)?.length || 0;
  }

  get totalViews() {
    return this.views?.reduce((sum, v) => (sum += v.views || 1), 0);
  }

  set model(live: Live) {
    this.live = live;
    this.contents = [...live.content];
    this.hasChange = false;
  }
}
