import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { FileUpload, FileUploadHandlerEvent } from 'primeng/fileupload';
import { lastValueFrom, map } from 'rxjs';
import { Live, LiveControllerService } from 'src/app/admin-api';
import { ImageCropperModalComponent } from 'src/app/components/image-cropper-modal/image-cropper-modal.component';
import { ImageUploadComponent } from 'src/app/components/image-upload/image-upload.component';
import { Role, roleAsObject } from 'src/app/models';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-live-form',
  templateUrl: './live-form.component.html',
  styleUrl: './live-form.component.scss',
  providers: [MessageService, DialogService]
})
export class LiveFormComponent
  extends ImageUploadComponent<Live>
  implements OnInit
{
  @Input()
  live: Live;

  @Output()
  liveChanged = new EventEmitter<Live>();

  liveService = inject(LiveControllerService);
  messageService = inject(MessageService);
  permissionService = inject(NgxPermissionsService);
  dialog = inject(DialogService);
  confirmationService = inject(ConfirmationService);

  override form = new FormGroup({
    couponName: new FormControl<string>(null, [Validators.maxLength(200)]),
    title: new FormControl<string>(null, [
      Validators.required,
      Validators.maxLength(200)
    ]),
    subtitle: new FormControl<string>(null, [Validators.maxLength(200)]),
    link: new FormControl<string>(null, [Validators.maxLength(2000)]),
    image: new FormControl<string>(null, [Validators.maxLength(2000)]),
    active: new FormControl(false)
  });
  permission: boolean;

  async ngOnInit(): Promise<void> {
    try {
      this.permission = await this.permissionService.hasPermission([
        roleAsObject(Role.Full_Administrator).enumValue,
        roleAsObject(Role.Content).enumValue
      ]);
      if (!this.permission) this.form.disable();
      else this.form.enable();
    } catch (error) {
      console.error(error);
      this.permission = false;
    }
    if (this.live) this.form.patchValue(this.live);
  }

  async submit(stopLive = false) {
    if (this.form.valid) {
      if (this.live?.active && !this.form.value.active && !stopLive) {
        this.confirmationService.confirm({
          header: 'Interromper a live?',
          acceptLabel: 'Interromper!',
          rejectLabel: 'Voltar',
          rejectButtonStyleClass: 'p-button-primary',
          acceptButtonStyleClass: 'p-button-danger',
          accept: async () => {
            await this.submit(true);
          }
        });
        return;
      }
      try {
        LoaderService.showLoader();
        if (this.live?.liveId) {
          this.live = await lastValueFrom(
            this.liveService
              .updateLive(this.form.value, this.live?.liveId)
              .pipe(map((data) => data.result))
          );
          this.messageService.add({
            summary: 'Sucesso',
            detail: 'Live atualizada com sucesso',
            severity: 'success'
          });
          await this.afterSubmit();
          this.liveChanged.emit(this.live);
          LoaderService.showLoader(false);
        } else {
          this.live = await lastValueFrom(
            this.liveService
              .createLive(this.form.value)
              .pipe(map((data) => data.result))
          );
          await this.afterSubmit();
          this.messageService.add({
            summary: 'Sucesso',
            detail: 'Live criada com sucesso',
            severity: 'success'
          });
          LoaderService.showLoader(false);
          this.router.navigate(['/content/lives/' + this.live.liveId]);
        }
      } catch (error) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(error);
      }
    }
  }

  async cropImageBeforeUpload(
    $event: FileUploadHandlerEvent,
    fileUpload: FileUpload
  ): Promise<void> {
    this.dialog
      .open(ImageCropperModalComponent, {
        width: '600px',
        height: '80vh',
        data: {
          image: $event.files[0],
          fixedWidth: 245,
          fixedHeight: 245
        },
        showHeader: true,
        header: 'Ajustar imagem',
        maximizable: true,
        modal: true
      })
      .onClose.subscribe(async (cropped: File) => {
        if (cropped) {
          $event.files = [cropped];
          await this.onUpload($event, 'image', fileUpload);
        } else {
          fileUpload.clear();
        }
      });
  }

  public override get filePath(): string {
    return '/admin/lives/';
  }

  public override get model(): Live {
    return this.live;
  }

  public override get modelId(): string | number {
    return this.live?.liveId;
  }
}
