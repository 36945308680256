/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ShopOrderRefundDetail {
  shopOrderRefundId?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  orderId?: string;
  orderNumber?: string;
  productId?: string;
  productVariantId?: string;
  amount?: number;
  ean?: string;
  reason?: string;
  userId?: number;
  username?: string;
  quantity?: number;
}
