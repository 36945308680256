import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom, map } from 'rxjs';
import { InvoiceControl, KpiControllerService } from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-invoices-dashboard',
  templateUrl: './invoices-dashboard.component.html',
  styleUrls: ['./invoices-dashboard.component.scss']
})
export class InvoicesDashboardComponent implements OnInit {
  filterForm!: FormGroup;
  invoices: InvoiceControl[] = [];
  availableYears: number[] = [];
  availableMonths = [
    { name: 'Janeiro', value: 1 },
    { name: 'Fevereiro', value: 2 },
    { name: 'Março', value: 3 },
    { name: 'Abril', value: 4 },
    { name: 'Maio', value: 5 },
    { name: 'Junho', value: 6 },
    { name: 'Julho', value: 7 },
    { name: 'Agosto', value: 8 },
    { name: 'Setembro', value: 9 },
    { name: 'Outubro', value: 10 },
    { name: 'Novembro', value: 11 },
    { name: 'Dezembro', value: 12 }
  ];

  totalQuantidade = 0;
  totalValue = 0;
  averageValue = 0;

  constructor(
    private fb: FormBuilder,
    private invoiceService: KpiControllerService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.initializeYears();
  }

  private initializeForm(): void {
    this.filterForm = this.fb.group({
      ano: [new Date().getFullYear(), Validators.required],
      mes: [new Date().getMonth() + 1, Validators.required]
    });
  }

  private initializeYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear - 10; year <= currentYear; year++) {
      this.availableYears.push(year);
    }
  }

  async onSubmit(): Promise<void> {
    if (this.filterForm.valid) {
      const { ano, mes } = this.filterForm.value;
      await this.loadInvoices(ano, mes);
    }
  }

  private async loadInvoices(ano: number, mes: number): Promise<void> {
    try {
      LoaderService.showLoader();
      delete this.invoices;
      this.invoices = await lastValueFrom(
        this.invoiceService
          .findInvoices(ano, mes)
          .pipe(map((data) => data.result))
      );
      this.calculateTotals();
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  private calculateTotals(): void {
    this.totalQuantidade = this.invoices.reduce(
      (sum, invoice) => sum + (invoice.quantidade || 0),
      0
    );

    this.totalValue = this.invoices.reduce(
      (sum, invoice) => sum + (invoice.total || 0),
      0
    );

    const totalItems = this.invoices.length;
    const totalMedia = this.invoices.reduce(
      (sum, invoice) => sum + (invoice.media || 0),
      0
    );

    this.averageValue = totalItems > 0 ? totalMedia / totalItems : 0;
  }
}
