/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LiveContent } from './liveContent';

export interface Live {
  liveId?: number;
  couponName?: string;
  title?: string;
  subtitle?: string;
  link?: string;
  dateCreated?: Date;
  dateUpdated?: Date;
  content?: Array<LiveContent>;
  active?: boolean;
}
