import {
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Editor } from 'primeng/editor';
import Quill from 'quill';
import { lastValueFrom, map } from 'rxjs';
import * as beautify from 'simply-beautiful';
import {
  Course,
  CourseControllerService,
  CourseModuleContent
} from 'src/app/admin-api';
import { AppDialogService } from 'src/app/services/dialog.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';

@Component({
  selector: 'app-content-form',
  templateUrl: './content-form.component.html',
  styleUrl: './content-form.component.scss'
})
export class ContentFormComponent
  extends ImageUploadComponent<Course>
  implements OnInit
{
  @ViewChild(Editor) editor: Editor;

  private courseService = inject(CourseControllerService);
  private messageService = inject(MessageService);
  private ref = inject(DynamicDialogRef);
  private sanitizer = inject(DomSanitizer);
  cdRef = inject(ChangeDetectorRef);

  content: CourseModuleContent = inject(DynamicDialogConfig).data.content;
  moduleId: number = inject(DynamicDialogConfig).data.moduleId;
  override form = new FormGroup({
    title: new FormControl<string>(null, [
      Validators.required,
      Validators.maxLength(200)
    ]),
    subtitle: new FormControl<string>(null, [Validators.maxLength(200)]),
    description: new FormControl<string>(null),
    active: new FormControl(false),
    image: new FormControl<string>(null),
    code: new FormControl<string>(null, Validators.maxLength(100)),
    html: new FormControl(false),
    order: new FormControl<number>(null),
    minutesTotal: new FormControl<number>(null, [
      Validators.required,
      Validators.min(1)
    ])
  });
  toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [
      'bold',
      'italic',
      'underline',
      'strike',
      { script: 'sub' },
      { script: 'super' },
      'code-block'
    ], // toggled buttons
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['link', 'image'],

    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }]
  ];
  handlers = {
    image: this.handleImage
  };
  url: SafeResourceUrl;

  ngOnInit(): void {
    this.form.patchValue(this.content);
    this.setUrl();
  }

  setUrl() {
    if (!this.form.value.code) return;
    if (
      !this.url ||
      (this.form.controls.code.touched && this.form.controls.code.dirty)
    )
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/'.concat(this.form.value.code)
      );
  }

  format() {
    this.form
      .get('description')
      .setValue(beautify.html(this.form.value.description, { indent_size: 2 }));
  }

  handleImage() {
    document.getElementById('inputFile').click();
  }

  async handleFile($event: Event) {
    try {
      LoaderService.showLoader();
      const link = await lastValueFrom(
        this.fileService
          .uploadFileForm(
            this.filePath.concat('assets/'),
            ($event.target as any).files[0],
            ($event.target as any).files[0].name
          )
          .pipe(map((data) => data.result))
      );
      this.form.controls.description.setValue(
        this.form.value.description + `<p><img src="${link}" /></p>`
      );
      this.editor.writeValue(this.form.value.description);
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Imagem adicionada ao final do texto.'
      });
    } catch (error) {
      AppDialogService.showErrorDialog(error);
    } finally {
      LoaderService.showLoader(false);
    }
  }

  addClassToImages() {
    (this.editor?.getQuill() as Quill)?.container
      .querySelectorAll('img')
      .forEach((i) => {
        if (!i.className?.includes('dimensioned'))
          i.classList.add('dimensioned');
      });
    this.form
      .get('description')
      .setValue(
        (this.editor?.getQuill() as Quill)?.getSemanticHTML() ||
          this.form.value.description
      );
  }

  async submit() {
    if (this.form.valid) {
      try {
        LoaderService.showLoader();
        if (this.content) {
          this.content = await lastValueFrom(
            this.courseService
              .updateCourseModuleContent(this.form.value, this.modelId)
              .pipe(map((data) => data.result))
          );
        } else {
          this.content = await lastValueFrom(
            this.courseService
              .createCourseModuleContent(this.form.value, this.moduleId)
              .pipe(map((data) => data.result))
          );
        }
        await this.afterSubmit();
        this.ref.close(this.content);
      } catch (error) {
        LoaderService.showLoader(false);
        AppDialogService.showErrorDialog(error);
      }
    }
  }

  get modelId() {
    return this.content?.contentId;
  }

  get filePath(): string {
    return '/courses/modules/content';
  }

  get model(): CourseModuleContent {
    return this.content;
  }
}
