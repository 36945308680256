/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SubscriptionType {
  subscriptionTypeId?: number;
  subscriptionId?: number;
  name?: string;
  imageURL?: string;
  summary?: string;
  price?: number;
  installments?: number;
  editionCount?: number;
  recurrenceMonths?: number;
  rewardPoints?: number;
  dateCreated?: Date;
  dateUpdated?: Date;
  restrictedSubscriptionId?: number;
  badgeId?: number;
  isActive?: boolean;
  isVisible?: boolean;
  liveloPoints?: number;
  cycle?: number;
}
