<h3 *ngIf="collection">
  Editar coleção: {{ collection.title || collection.altText }}
</h3>
<h3 *ngIf="!collection">Nova coleção</h3>
<form class="form-b4a" [formGroup]="form" (submit)="submit()">
  <p-card>
    <div class="form-b4a__card">
      <div class="form-b4a__card__group">
        <span class="p-float-label">
          <input
            type="text"
            pInputText
            formControlName="title"
            id="title"
            name="title"
          />
          <label for="text">Titulo *</label>
        </span>
        <span class="p-float-label">
          <input
            type="text"
            pInputText
            formControlName="link"
            id="link"
            name="link"
          />
          <label for="link">Link *</label>
        </span>
      </div>
    </div>
  </p-card>
  <span class="input-switch">
    <label for="published">Inativo</label>
    <p-inputSwitch formControlName="published" inputId="published" />
    <label for="published">Ativo</label>
  </span>

  <span class="buttons">
    <p-button label="Voltar" severity="danger" routerLink="../../" />
    <p-button label="Salvar" type="submit" [disabled]="!form.valid" />
  </span>
</form>
