/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ResponseB4AString } from '../model/responseB4AString';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable()
export class FileControllerService {
  protected basePath = 'https://admin-api.b4a.com.br';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param file
   * @param filePath
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public compressAndUploadFileForm(
    file?: Blob,
    filePath?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public compressAndUploadFileForm(
    file?: Blob,
    filePath?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public compressAndUploadFileForm(
    file?: Blob,
    filePath?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public compressAndUploadFileForm(
    file?: Blob,
    filePath?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (filePath !== undefined && filePath !== null) {
      queryParameters = queryParameters.set('filePath', <any>filePath);
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec()
      });
    }

    if (file !== undefined) {
      formParams = (formParams.append('file', <any>file) as any) || formParams;
    }

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/file/compress`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param reference
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteFile(
    reference: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public deleteFile(
    reference: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public deleteFile(
    reference: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public deleteFile(
    reference: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (reference === null || reference === undefined) {
      throw new Error(
        'Required parameter reference was null or undefined when calling deleteFile.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (reference !== undefined && reference !== null) {
      queryParameters = queryParameters.set('reference', <any>reference);
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AString>(
      'delete',
      `${this.basePath}/file`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param filePath
   * @param file
   * @param fileName
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public uploadFileForm(
    filePath: string,
    file?: Blob,
    fileName?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AString>;
  public uploadFileForm(
    filePath: string,
    file?: Blob,
    fileName?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AString>>;
  public uploadFileForm(
    filePath: string,
    file?: Blob,
    fileName?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AString>>;
  public uploadFileForm(
    filePath: string,
    file?: Blob,
    fileName?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (filePath === null || filePath === undefined) {
      throw new Error(
        'Required parameter filePath was null or undefined when calling uploadFile.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (filePath !== undefined && filePath !== null) {
      queryParameters = queryParameters.set('filePath', <any>filePath);
    }
    if (fileName !== undefined && fileName !== null) {
      queryParameters = queryParameters.set('fileName', <any>fileName);
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec()
      });
    }

    if (file !== undefined) {
      formParams = (formParams.append('file', <any>file) as any) || formParams;
    }

    return this.httpClient.request<ResponseB4AString>(
      'post',
      `${this.basePath}/file/upload`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
