import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { CreditCardViewModule } from 'src/app/components/credit-card-view/credit-card-view.module';
import { PersonCardModule } from 'src/app/components/person-card/person-card.module';
import { ShopCouponFormModule } from 'src/app/components/shop-coupon-form/shop-coupon-form.module';
import { ShopOrderAddressFormModule } from 'src/app/components/shop-order-address-form/shop-order-address-form.module';
import { AppTableModule } from 'src/app/components/table/app-table.module';
import { OrdersBlockedComponent } from './orders-blocked/orders-blocked.component';
import { ShopOrderDetailComponent } from './shop-order-detail/shop-order-detail.component';
import { ShopOrderRefundModalComponent } from './shop-order-refund-modal/shop-order-refund-modal.component';
import { ShopOrdersRoutingModule } from './shop-orders-routing.module';
import { ShopOrdersComponent } from './shop-orders.component';

@NgModule({
  declarations: [
    ShopOrdersComponent,
    ShopOrderDetailComponent,
    OrdersBlockedComponent,
    ShopOrderRefundModalComponent
  ],
  imports: [
    CommonModule,
    ShopOrdersRoutingModule,
    AppTableModule,
    TabViewModule,
    FieldsetModule,
    ShopOrderAddressFormModule,
    AppTableModule,
    ButtonModule,
    ToastModule,
    PersonCardModule,
    ShopCouponFormModule,
    DropdownModule,
    ReactiveFormsModule,
    CreditCardViewModule,
    TooltipModule,
    CalendarModule,
    MultiSelectModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule
  ]
})
export class ShopOrdersModule {}
