<h2>Atualização Tabela de Frete (Assinatura)</h2>
<h3>Ranges de peso:</h3>
<div class="item-list">
  <ng-container *ngIf="weightRangeShippingCompanyRange()">
    <div class="item" *ngFor="let range of weightRangeShippingCompanyRange()">
      <h3 class="item-title">
        De
        {{ range.initialWeightRange / 1000 | number : '1.3-3' }} a
        {{ range.endWeightRange / 1000 | number : '1.3-3' }} KG
      </h3>
      <div
        class="item-status"
        [ngClass]="getGridClass(range.status, range.inProduction)"
        [title]="getDescription(range.status, range.inProduction)"
      ></div>
    </div>
  </ng-container>
</div>

<div *ngIf="synchronism()">
  <p class="loading-text">
    Sincronismo em progresso<span class="loading-dots">...</span>
  </p>
</div>

<br />
<div class="button-container">
  <p-button
    label="Continuar operação"
    severity="primary"
    class="continue-button"
    pTooltip="Continua o processo de atualização da tabela de frete em SIMULATION"
    tooltipPosition="bottom"
    (onClick)="continueUpdate()"
    [disabled]="continueButtonDisabled"
  ></p-button>
  <p-button
    label="Pausar atualização"
    severity="danger"
    class="stop-button"
    pTooltip="Pausa o processo de atualização da tabela de frete em SIMULATION"
    tooltipPosition="bottom"
    (onClick)="stopUpdate()"
    [disabled]="stopButtonDisabled"
  ></p-button>
  <p-button
    label="Enviar nova tabela para produção"
    severity="success"
    class="update-table-button"
    pTooltip="Enviar a tabela de SIMULATION para PRODUÇÃO"
    tooltipPosition="bottom"
    (onClick)="updateShippingCompanyRange()"
    [disabled]="updateButtonDisabled"
  ></p-button>
  <p-button
    label="Cancelar atualização"
    severity="danger"
    class="cancel-button"
    pTooltip="Interrompe por completo o processo de atualização da tabela de frete em SIMULATION"
    tooltipPosition="bottom"
    (onClick)="cancelUpdate()"
    [disabled]="cancelButtonDisabled"
  ></p-button>
  <p-button
    label="Realizar rollback"
    severity="secondary"
    class="rollback-button"
    pTooltip="Pega a última tabela de frete do backup e coloca em PRODUÇÃO"
    tooltipPosition="bottom"
    (onClick)="rollbackShippingCompanyRange()"
    [disabled]="rollbackButtonDisabled"
  ></p-button>
  <p-button
    label="Buscar nova tabela de frete"
    severity="primary"
    class="sync-button"
    pTooltip="Realiza o processo de atualização da tabela de frete em SIMULATION"
    tooltipPosition="bottom"
    (onClick)="syncUpdateShippingCompanyRange()"
    [disabled]="syncButtonDisabled"
  ></p-button>
</div>
<br />

<h2>Filtrar por ranges de CEP</h2>
<form class="form-b4a">
  <span class="p-float-label" *ngIf="shippingCompany">
    <p-dropdown
      id="selectedShippingCompany"
      [options]="shippingCompany"
      (onChange)="onShippingCompanyChange($event)"
      inputId="selectedShippingCompany"
      name="selectedShippingCompany"
      optionLabel="shippingCompanyName"
      placeholder="Selecione uma transportadora"
    >
    </p-dropdown>
    <label for="selectedShippingCompany">Selecione uma transportadora:</label>
  </span>

  <span class="p-float-label" *ngIf="zipRangeOptions">
    <p-dropdown
      id="selectedZipRange"
      [options]="zipRangeOptions"
      (onChange)="onZipRangeChange($event)"
      inputId="selectedZipRange"
      name="selectedZipRange"
      optionLabel="label"
      placeholder="Selecione um intervalo de CEP"
    >
    </p-dropdown>
    <label for="selectedZipRange">Selecione um intervalo de CEP:</label>
  </span>

  <span class="p-float-label" *ngIf="operationTypes">
    <p-dropdown
      id="selectedOperationType"
      [options]="operationTypes"
      (onChange)="onOperationTypeChange($event)"
      inputId="selectedOperationType"
      name="selectedOperationType"
      optionLabel="value"
      placeholder="Selecione onde verificar"
    >
    </p-dropdown>
    <label for="selectedOperationType">Selecione onde verificar:</label>
  </span>
</form>

<h2>Filtrar atendimento por CEP</h2>
<form class="form-b4a">
  <span class="p-float-label">
    <p-inputNumber
      id="zipInput"
      [(ngModel)]="zip"
      (ngModelChange)="onZipChange($event)"
      [min]="0"
      [max]="99999999"
      [showButtons]="false"
      inputId="zipInput"
      name="zip"
      placeholder="Digite o CEP"
    ></p-inputNumber>
    <label for="zipInput">Digite o CEP:</label>
  </span>

  <span class="p-float-label" *ngIf="operationTypes">
    <p-dropdown
      id="selectedOperationTypeFilterType"
      [options]="operationTypes"
      (onChange)="onOperationTypeFilterTypeChange($event)"
      inputId="selectedOperationTypeFilterType"
      name="selectedOperationTypeFilterType"
      optionLabel="value"
      placeholder="Selecione onde verificar"
    >
    </p-dropdown>
    <label for="selectedOperationTypeFilterType"
      >Selecione onde verificar:</label
    >
  </span>
</form>

<div>
  <ng-container *ngIf="range && colsRange">
    <app-table
      [elements]="range"
      [addButton]="false"
      [cols]="colsRange"
      emptyMessage="Nenhum range de CEP encontrado."
      [exportExcel]="true"
      [exportPdf]="false"
      [lazy]="false"
      sortField="zipStart"
      [sortOrder]="1"
      [changeColumns]="true"
      [showTitle]="false"
      modelName="registros"
    ></app-table>
  </ng-container>
</div>
<br />

<h2>Últimas atualizações realizadas</h2>
<div>
  <ng-container *ngIf="logger && cols">
    <app-table
      [elements]="logger"
      [addButton]="false"
      [cols]="cols"
      emptyMessage="Nenhuma atualização da tabela de frete encontrada."
      [exportExcel]="false"
      [exportPdf]="false"
      [lazy]="false"
      sortField="dateCreated"
      [sortOrder]="1"
      [changeColumns]="true"
      [showTitle]="false"
      modelName="registros"
    ></app-table>
  </ng-container>
</div>
