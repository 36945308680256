/**
 * Admin API
 * Admin REST API
 *
 * OpenAPI spec version: 1.0
 * Contact: ti@b4agroup.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { Observable } from 'rxjs';

import { AttributeCreateRequest } from '../model/attributeCreateRequest';
import { AttributeGroupCreateRequest } from '../model/attributeGroupCreateRequest';
import { AttributeGroupUpdateRequest } from '../model/attributeGroupUpdateRequest';
import { AttributeOptionCreateRequest } from '../model/attributeOptionCreateRequest';
import { AttributeOptionUpdateRequest } from '../model/attributeOptionUpdateRequest';
import { AttributeUpdateRequest } from '../model/attributeUpdateRequest';
import { ResponseB4AAttribute } from '../model/responseB4AAttribute';
import { ResponseB4AAttributeGroup } from '../model/responseB4AAttributeGroup';
import { ResponseB4AAttributeOption } from '../model/responseB4AAttributeOption';
import { ResponseB4AListAttribute } from '../model/responseB4AListAttribute';
import { ResponseB4AListAttributeGroup } from '../model/responseB4AListAttributeGroup';
import { ResponseB4AListAttributeOption } from '../model/responseB4AListAttributeOption';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable()
export class BeautyProfileAdminControllerService {
  protected basePath = 'https://admin-api.b4a.com.br';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param attributeId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public convertAttributeIntoGroup(
    attributeId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AAttributeGroup>;
  public convertAttributeIntoGroup(
    attributeId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AAttributeGroup>>;
  public convertAttributeIntoGroup(
    attributeId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AAttributeGroup>>;
  public convertAttributeIntoGroup(
    attributeId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (attributeId === null || attributeId === undefined) {
      throw new Error(
        'Required parameter attributeId was null or undefined when calling convertAttributeIntoGroup.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AAttributeGroup>(
      'put',
      `${
        this.basePath
      }/beautyprofile-admin/attribute/group/frequency/${encodeURIComponent(
        String(attributeId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createAttribute(
    body: AttributeCreateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AAttribute>;
  public createAttribute(
    body: AttributeCreateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AAttribute>>;
  public createAttribute(
    body: AttributeCreateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AAttribute>>;
  public createAttribute(
    body: AttributeCreateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling createAttribute.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AAttribute>(
      'post',
      `${this.basePath}/beautyprofile-admin/attribute`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createAttributeGroup(
    body: AttributeGroupCreateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AAttributeGroup>;
  public createAttributeGroup(
    body: AttributeGroupCreateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AAttributeGroup>>;
  public createAttributeGroup(
    body: AttributeGroupCreateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AAttributeGroup>>;
  public createAttributeGroup(
    body: AttributeGroupCreateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling createAttributeGroup.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AAttributeGroup>(
      'post',
      `${this.basePath}/beautyprofile-admin/attribute/group`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createAttributeOption(
    body: AttributeOptionCreateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AAttributeOption>;
  public createAttributeOption(
    body: AttributeOptionCreateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AAttributeOption>>;
  public createAttributeOption(
    body: AttributeOptionCreateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AAttributeOption>>;
  public createAttributeOption(
    body: AttributeOptionCreateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling createAttributeOption.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AAttributeOption>(
      'post',
      `${this.basePath}/beautyprofile-admin/attribute/option`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param attributeId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeAttributeFromGroup(
    attributeId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AAttribute>;
  public removeAttributeFromGroup(
    attributeId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AAttribute>>;
  public removeAttributeFromGroup(
    attributeId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AAttribute>>;
  public removeAttributeFromGroup(
    attributeId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (attributeId === null || attributeId === undefined) {
      throw new Error(
        'Required parameter attributeId was null or undefined when calling removeAttributeFromGroup.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ResponseB4AAttribute>(
      'delete',
      `${
        this.basePath
      }/beautyprofile-admin/attribute/group/frequency/${encodeURIComponent(
        String(attributeId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAttribute(
    body: AttributeUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AAttribute>;
  public updateAttribute(
    body: AttributeUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AAttribute>>;
  public updateAttribute(
    body: AttributeUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AAttribute>>;
  public updateAttribute(
    body: AttributeUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateAttribute.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AAttribute>(
      'put',
      `${this.basePath}/beautyprofile-admin/attribute`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAttributeGroup(
    body: AttributeGroupUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AAttributeGroup>;
  public updateAttributeGroup(
    body: AttributeGroupUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AAttributeGroup>>;
  public updateAttributeGroup(
    body: AttributeGroupUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AAttributeGroup>>;
  public updateAttributeGroup(
    body: AttributeGroupUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateAttributeGroup.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AAttributeGroup>(
      'put',
      `${this.basePath}/beautyprofile-admin/attribute/group`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAttributeGroupsOrder(
    body: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListAttributeGroup>;
  public updateAttributeGroupsOrder(
    body: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListAttributeGroup>>;
  public updateAttributeGroupsOrder(
    body: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListAttributeGroup>>;
  public updateAttributeGroupsOrder(
    body: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateAttributeGroupsOrder.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListAttributeGroup>(
      'put',
      `${this.basePath}/beautyprofile-admin/attribute/group/sort`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAttributeOption(
    body: AttributeOptionUpdateRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AAttributeOption>;
  public updateAttributeOption(
    body: AttributeOptionUpdateRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AAttributeOption>>;
  public updateAttributeOption(
    body: AttributeOptionUpdateRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AAttributeOption>>;
  public updateAttributeOption(
    body: AttributeOptionUpdateRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateAttributeOption.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AAttributeOption>(
      'put',
      `${this.basePath}/beautyprofile-admin/attribute/option`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param attributeId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAttributeOptionsOrder(
    attributeId: number,
    body: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListAttributeOption>;
  public updateAttributeOptionsOrder(
    attributeId: number,
    body: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListAttributeOption>>;
  public updateAttributeOptionsOrder(
    attributeId: number,
    body: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListAttributeOption>>;
  public updateAttributeOptionsOrder(
    attributeId: number,
    body: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateAttributeOptionsOrder.'
      );
    }

    if (attributeId === null || attributeId === undefined) {
      throw new Error(
        'Required parameter attributeId was null or undefined when calling updateAttributeOptionsOrder.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListAttributeOption>(
      'put',
      `${this.basePath}/beautyprofile-admin/attribute/${encodeURIComponent(
        String(attributeId)
      )}/option/sort`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param attributeGroupId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAttributesOrder(
    attributeGroupId: number,
    body: Array<string>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseB4AListAttribute>;
  public updateAttributesOrder(
    attributeGroupId: number,
    body: Array<string>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseB4AListAttribute>>;
  public updateAttributesOrder(
    attributeGroupId: number,
    body: Array<string>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseB4AListAttribute>>;
  public updateAttributesOrder(
    attributeGroupId: number,
    body: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling updateAttributesOrder.'
      );
    }

    if (attributeGroupId === null || attributeGroupId === undefined) {
      throw new Error(
        'Required parameter attributeGroupId was null or undefined when calling updateAttributesOrder.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer-key) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<ResponseB4AListAttribute>(
      'put',
      `${this.basePath}/beautyprofile-admin/attribute/sort/${encodeURIComponent(
        String(attributeGroupId)
      )}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
